<template>
    <div class="test">
        <BeforeLoading v-show="!isLoading"/>
        <div v-show="isLoading" class="container">
            <router-link
                class="test__back"
                to="/testing"
            >Вернуться к списку тестов
            </router-link
            >
            <div class="test__title title">{{ currentTest.name }}</div>
            <div class="test__subtitle">
                <div class="test__block">
                    <div class="test__block-text">
                        {{ currentTest.descrip }}
                    </div>
                </div>
            </div>
            <div class="test__wrapper">
                <TestingQuestion
                    v-for="(question, questionIndex) in preparedQuestions"
                    :key="question.id"
                    :question="question"
                    :questionIndex="questionIndex"
                    :settings="preparedSettings"
                    :testHistory="testHistory"
                    @isLoading="isLoadingFunc"
                    @updateAnswer="addAnswer"
                />
            </div>
            <button class="test__end" @click="completeTest">Завершить тест</button>
        </div>
        <button
            v-if="preparedQuestions.length > 15"
            v-b-tooltip.hover
            class="arrow_up-btn"
            title="Tap to scroll top"
            @click="scrollToTop">
            <img alt="" src="https://www.linkpicture.com/q/arrowup.png">
        </button>
    </div>
</template>

<script>
import TestingQuestion from '../components/testing/TestingQuestion.vue';
import BeforeLoading from '@/components/modals/common/BeforeLoading.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    /* eslint no-return-assign: "off"  */
    name: 'TestView',
    components: {
        TestingQuestion,
        BeforeLoading,
    },
    data() {
        return {
            answersArray: [],
            isLoading: false,
            isLoadingCount: 1,
        };
    },
    computed: {
        ...mapGetters({
            currentTest: 'testing/currentTest',
            testHistory: 'testing/testHistory',
            user: 'user/myInfo',
        }),
        // setup shitstorm (sorry, but backend is so smart (no))
        preparedQuestions() {
            this.testHistory.map(answer => {
                return this.arrayQuestions.map(question => {
                    if (question.type_question === 'multi') {
                        if (question.id === answer.id_question) {
                            if (answer.multi_arr !== '0') {
                                const array = answer.multi_arr.split(',');
                                return question.pre_answer = array;
                            }
                            return question.pre_answer = [];
                        }
                        return [];
                    }
                    if (question.id === answer.id_question) {
                        return question.pre_answer = answer.id_answer;
                    }
                    return null;
                });
            });

            return this.arrayQuestions;
        },

        arrayQuestions() {
            this.currentTest.question.map(question => {
                if (question.type_question === 'multi') {
                    return question.pre_answer = [];
                }
                return question.pre_answer = null;
            });

            return this.currentTest.question;
        },

        preparedSettings() {
            return {
                userId: this.user.id,
            };
        },
    },
    async created() {
        this.clearCurrentTest();
        await this.requestData();
    //  await this.requestHistoryData();
    },
    methods: {
        ...mapActions({
            getCurrentTest: 'testing/getCurrentTest',
            getTestHistory: 'testing/getTestHistory',
            setAnswer: 'testing/setAnswer',
            setTestResults: 'testing/setTestResults',
            clearCurrentTest: 'testing/clearCurrentTest',
        }),

        isLoadingFunc() {
            this.isLoadingCount = this.isLoadingCount + 1;

            if (this.isLoadingCount === this.preparedQuestions.length) {
                this.isLoading = true;
            }
        },

        requestData() {
            const params = this.$route.params.testId;
            this.getCurrentTest(params);
        },

        async requestHistoryData() {
            const params = {
                userId: this.user.id,
                testId: this.$route.params.testId,
            };
            await this.getTestHistory(params);
        },

        addAnswer(params) {
            this.setAnswer(params);
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },

        async completeTest() {
            const params = {
                id_test: this.$route.params.testId,
                id_user: this.user.id,
            };
            const response = await this.setTestResults(params);

            if (response.status === 200) {
                this.$router.push({ path: '/testing' });
            } else {
                for (let i = 0; i < this.arrayQuestions.length; i++) {
                    if (this.arrayQuestions[i].pre_answer === null) {
                        alert(`Ответьте на все вопросы!
                            Вы пропустли вопрос №${i + 1}!`);
                        break;
                    }
                }
            }
        },
    },
};
</script>

<style>
.class {
  position: relative;
}
</style>
