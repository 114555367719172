<template>
    <div class="test__question test__question-true">
        <div class="test__question-about">
            <div class="test__question-id">{{ questionIndex + 1 }}</div>
            <div class="test__question-title">
                {{ question.name }}
            </div>
        </div>
        <div class="test__question-answer">
            <div
                v-if="question.type_question === '' || question.type_question === null"
                class="test__question-selective"
            >
                <div class="test__question-flex column">
                    <div
                        v-for="answer in question.answer"
                        :key="answer.id"
                        class="test__question-answers"
                    >
                        <label
                            :class="{
                                active: preparedAnswer === answer.id,
                            }"
                            :for="'answer' + answer.id"
                            class="test__question-selectText"
                        >{{ answer.name }}</label
                        >
                        <input
                            :id="'answer' + answer.id"
                            v-model="preparedAnswer"
                            :value="answer.id"
                            type="radio"
                            @change="
                                updateAnswer(
                                    settings.userId,
                                    (testId = $route.params.testId),
                                    question.id,
                                    preparedAnswer,
                                    answer.points,
                                    answer.is_true
                                )
                            "
                        />
                    </div>
                </div>
            </div>

            <div
                v-if="question.type_question === 'image'"
                class="test__question-picture"
            >
                <div class="testing__item-picture_example">
                    <img :alt="question.descrip" :src="question.img"/>
                </div>

                <div class="testing__item-picture_select">
                    <div class="testing__item-picture_text">
                        Выберите один из вариантов ответа
                    </div>
                    <div class="testing__item-picture_wrapper">
                        <div
                            v-for="answer in question.answer"
                            :key="answer.id"
                            :class="{
                                active: preparedAnswer === answer.id,
                            }"
                            class="testing__item-picture_variant"
                        >
                            <label
                                :for="'answer' + answer.id"
                                class="test__question-selectImage"
                            >
                                <img :src="answer.img" alt=""/>
                            </label>
                            <input
                                :id="'answer' + answer.id"
                                v-model="preparedAnswer"
                                :value="answer.id"
                                type="radio"
                                @change="
                                    updateAnswer(
                                        settings.userId,
                                        (testId = $route.params.testId),
                                        question.id,
                                        preparedAnswer,
                                        answer.points,
                                        answer.is_true
                                    )
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="question.type_question === 'multi'"
                class="test__question-checkbox"
            >
                <div class="test__question-checkbox_title">
                    Вы можете выбрать несколько вариантов
                </div>
                <div class="test__question-checkbox_wrapper">
                    <div
                        v-for="answer in question.answer"
                        :key="answer.id"
                        class="test__question-checkbox_variant-multi"
                    >
                        <div class="flexbox">
                            <div>
                                <input
                                    :id="'answer' + answer.id"
                                    v-model="preparedAnswer"
                                    :value="answer.id"
                                    type="checkbox"
                                    @change="
                                        updateAnswer(
                                            settings.userId,
                                            (testId = $route.params.testId),
                                            question.id,
                                            preparedAnswer,
                                            answer.points,
                                            answer.is_true
                                        )
                                    "
                                />
                                <label :for="'answer' + answer.id"/>
                            </div>
                            <div>{{ answer.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
/* eslint vue/no-mutating-props: "off" */

export default {
    name: 'TestingQuestionCard',
    props: {
        question: {
            type: Object,
            default: () => ({}),
        },
        questionIndex: {
            type: Number,
            default: 0,
        },
        testHistory: {
            type: Array,
            default: () => [],
        },
        settings: {
            type: Object,
            default: () => {
            },
        },
    },
    data() {
        return {
            currentAnswer: null,
        };
    },
    computed: {
        preparedAnswer: {
            get() {
                return this.question.pre_answer;
            },
            set(value) {
                this.question.pre_answer = value;
            },
        },
    },
    mounted() {
        this.$emit('isLoading');
    },
    methods: {
        async updateAnswer(userId, testId, questionId, answerId, point, isTrue) {
            const ANSWER_ID = answerId.length ? answerId : 0;
            const params = {
                id_user: userId,
                id_test: Number(testId),
                id_question: questionId,
                id_answer: this.question.type_question === 'multi' ? 0 : answerId,
                points: point,
                is_true: isTrue,
                multi_arr: this.question.type_question === 'multi' ? ANSWER_ID : 0,
            };
            this.$emit('updateAnswer', params);
        },
    },
};
</script>
