import { send } from '@/global/functions';
import Vue from 'vue';

export const state = {
    profile: {
        profile_filed: {
            city: {},
            championships: [],
        },
        isMy: false,
        invitesCount: 0,
        messagesCount: 0,
        membershipRequestsCount: 0,
        Hard_skill_field: [],
        Group_Soft_skill_field: [],
        Soft_skill_field: [],
        id_game_field: [],
        Champ_Result: null, // то что я добавил
    },
    achievements: {},
    video: {
        id: null,
        name: '',
        description: '',
        url: '',
        visible: false,
        passed: {
            youtube: false,
            others: false,
        },
        code: '',
        src: '',
        list: [],
        isEdit: false,
    },
    settings: {
        fio: '',
        born: '',
        city: {
            options: [],
            current: '',
        },
    },
    userInfo: {},
    userAchievements: {},
    refreshToken: '',
    accessToken: '',
    userAuthorized: false,
};

export const getters = {
    convertedDate(state) {
        const resDate = new Date(state.profile.profile_filed.born).getTime();
        const d = new Date();
        d.setTime(resDate);

        return (
            ('0' + d.getDate()).slice(-2) +
            '.' +
            ('0' + (d.getMonth() + 1)).slice(-2) +
            '.' +
            d.getFullYear()
        );
    },

    timeToStampDate(state) {
        return new Date(state.profile.profile_filed.born);
    },

    myInfo: state => state.profile,
    userId: state => state.profile.id,
    invitesCount: state => state.profile.invitesCount,
    messagesCount: state => state.profile.messagesCount,
    membershipRequestsCount: state => state.profile.membershipRequestsCount,
    settings: state => state.settings,
    achievements: state => state.achievements,
    userAchievements: state => state.userAchievements,
    video: state => state.video,
    myVirtonomica: state => state.profile.id_game_field.find(item => item.game_title === 'virtonomica'),
    userInfo: state => state.userInfo,
    userVirtonomica: state => state.userInfo.id_game_field?.find(item => item.game_title === 'virtonomica'),
    championshipResults: state => state.profile.Champ_Result,
    hardSkills: state => state.profile.Hard_skill_field,
    softSkills: state => state.profile.Soft_skill_field,
    accessToken: state => state.accessToken,
    userAuthorized: state => state.userAuthorized,
};

export const mutations = {
    UPDATE_USER_DATA(state, res) {
        state.profile = { ...res };
        state.profile.isMy = true;
    },
    UPDATE_INVITES_COUNT(state, res) {
        state.profile.invitesCount = Vue.set(state.profile, 'invitesCount', res);
    },
    UPDATE_MESSAGES_COUNT(state, res) {
        state.profile.messagesCount = Vue.set(state.profile, 'messagesCount', res);
    },
    UPDATE_MEMBERSHIP_REQUESTS_COUNT(state, res) {
        state.profile.membershipRequestsCount = Vue.set(state.profile, 'membershipRequestsCount', res);
    },
    UPDATE_CITIES_LIST(state, res) {
        state.settings.city.options = res;
    },
    SET_USER_AUTHORIZED(state, res) {
        state.userAuthorized = res;
    },
    SET_ACHIEVEMENTS_LIST(state, res) {
        state.achievements = res;
    },
    SET_USER_ACHIEVEMENTS_LIST(state, res) {
        state.userAchievements = res;
    },
    SET_USER_VIDEOS(state, res) {
        state.video.list = res;
    },
    SET_VIDEO(state, res) {
        state.video = { ...state.video, ...res };
    },
    SET_USER_INFO(state, res) {
        state.userInfo = res;
    },
    UPDATE_ACCESS_TOKEN(state, res) {
        state.accessToken = res;
    },
};

export const actions = {
    async getUserData({
        commit,
        dispatch,
        getters,
    }, req) {
        try {
            const isTokenPassed = await dispatch('checkToken', { token: getters.accessToken });

            if (isTokenPassed) {
                const res = await send({
                    uri: `UserINF/detail/${req.id}/`,
                    method: 'GET',
                });

                commit('UPDATE_USER_DATA', res);

                dispatch('getInvitesCount', { id: req.id });
                dispatch('getMessagesCount', { id: req.id });
                dispatch('getMembershipRequestsCount', { id: req.id });
            }
        } catch (err) {
            console.error(err);
        }
    },

    async checkToken({
        getters,
        commit,
    }) {
        try {
            const res = await send({
                uri: 'auth/jwt/verify/',
                payload: { token: getters.accessToken || localStorage.getItem('token-access') },
                method: 'POST',
            });

            commit('SET_USER_AUTHORIZED', true);

            return !!(res && !Object.keys(res).length);
        } catch (e) {
            console.error(e);
        }
    },

    async refreshToken({ commit }, refreshtoken) {
        const result = await send({
            uri: 'auth/jwt/refresh/',
            payload: refreshtoken || { refresh: localStorage.getItem('token') },
            method: 'POST',
        });
        if (result.access) {
            localStorage.setItem('token-access', result.access);
            commit('UPDATE_ACCESS_TOKEN', result.access);
            return result.access;
        }

        localStorage.removeItem('token');
        localStorage.removeItem('token-access');
        location.reload();
    },

    async getInvitesCount({ commit }, req) {
        try {
            const res = await send({
                uri: `invitation_count/${req.id}/`,
                method: 'GET',
            });
            commit('UPDATE_INVITES_COUNT', res[0].invitesCount);
        } catch (e) {
            console.error(e);
        }
    },
    async getMessagesCount({ commit }, req) {
        try {
            const res = await send({
                uri: `message_count/${req.id}/`,
                method: 'GET',
            });
            commit('UPDATE_MESSAGES_COUNT', res.MessageCount);
        } catch (e) {
            console.error(e);
        }
    },

    async getMembershipRequestsCount({ commit }, req) {
        try {
            const res = await send({
                uri: `membership_requests_count/${req.id}/`,
                method: 'GET',
            });

            commit('UPDATE_MEMBERSHIP_REQUESTS_COUNT', res.membership_requests_count);
        } catch (e) {
            console.error(e);
        }
    },

    async getChangePassword({ commit }, req) {
        commit('settings/CLEAR_ERRORS', null, { root: true });

        try {
            const payload = {
                id_user: req.id,
                old_password: req.current,
                new_password: req.new,
            };
            return await send({
                uri: 'UpdatePassword/',
                payload,
                method: 'POST',
            });
        } catch (err) {
            console.log(err);
        }
    },

    async getChangeUserData({ commit }, req) {
        try {
            const payload = {
                id_user: req.id,
                born: req.born,
                city: req.city,
                second_name: req.name,
                gender: req.gender,
            };

            return await send({
                uri: 'UpdateProfile/',
                payload,
                method: 'POST',
            });
        } catch (err) {
            console.log(err);
        }
    },

    async loadCities({ commit }) {
        try {
            const res = await send({
                uri: 'town/all',
                method: 'GET',
            });
            commit('UPDATE_CITIES_LIST', res);
        } catch (err) {
            console.log(err);
        }
    },

    async updateUserGames({ commit }, req) {
        try {
            const payload = {
                id: req.id,
                game_id: req.gameId,
            };

            return await send({
                uri: 'update_game_id/',
                payload,
                method: 'POST',
            });
        } catch (e) {
            console.log(e);
        }
    },

    async loadAchievements({ commit }, req) {
        try {
            const res = await send(
                {
                    domain: 'https://virtonomica.ru',
                    uri: `api/vera/main/achievement/browse?user_id=${req.id}`,
                    method: 'GET',
                    wait: true,
                }
            );

            req.isMy
                ? commit('SET_ACHIEVEMENTS_LIST', res)
                : commit('SET_USER_ACHIEVEMENTS_LIST', res);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async userVideoUpload({
        commit,
        getters,
    }, req) {
        try {
            const res = await send({
                uri: 'profile/video/upload',
                payload: req,
                method: 'POST',
            });

            const result = getters.video.list;

            result.push(res[0]);

            commit('SET_USER_VIDEOS', result);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async userVideoUpdate({
        commit,
        getters,
    }, req) {
        try {
            const res = await send({
                uri: 'profile/video/edit',
                method: 'POST',
                payload: req,
            });

            if (res.status === 200) {
                const newVideosArray = getters.video.list.map(item => {
                    if (item.id === req.video_id) {
                        return {
                            ...req,
                        };
                    }

                    return item;
                });

                commit('SET_USER_VIDEOS', newVideosArray);
            }

            return res;
        } catch (e) {
            console.error(e);
        }
    },

    async getUserVideos({ commit }, req) {
        try {
            const res = await send({
                uri: `profile/video/detail/${req.id_user}`,
                method: 'GET',
                wait: true,
            });

            if (res && res.length) {
                res.sort((a, b) => new Date(a.date_create) - new Date(b.date_create));
            } else {
                return {};
            }

            commit('SET_USER_VIDEOS', res);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async userDeleteVideo({
        commit,
        getters,
    }, req) {
        try {
            const res = await send({
                uri: 'profile/video/delete',
                method: 'POST',
                payload: req,
            });

            if (res.status === 200) {
                const newVideosArray = getters.video.list.filter(item => item.id !== req.video_id);
                commit('SET_USER_VIDEOS', newVideosArray);
            }

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async getUserInfo({ commit }, req) {
        try {
            const res = await send({
                uri: `UserINF/detail/${req.id}/`,
                method: 'GET',
            });

            commit('SET_USER_INFO', res);
        } catch (e) {
            console.error(e);
        }
    },
    async requestMembership({ commit }, req) {
        const user_id = req.user;
        delete req.user;
        try {
            await send({
                uri: `championship/teams/request/${user_id}/`,
                payload: req,
                method: 'POST',
            });
            // commit('', res);
        } catch (e) {
            console.error(e);
        }
    },
    async getNeuralNetworkData() {
        try {
            return await send({
                uri: 'profile/neural-network/',
                method: 'GET',
            });
        } catch (e) {
            console.error(e);
        }
    },
    async activateAccount({ commit }, req) {
        try {
            // commit('', res);
            return await send({
                uri: `activate/${req.uuid}/`,
                method: 'GET',
            });
        } catch (e) {
            console.error(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
