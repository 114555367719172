<template>
    <div class="modal-date">
        <button class="modal-date__close" @click="toggleModal">
            <img alt="" src="@/assets/images/close-icon.svg"/>
        </button>
        <div class="container">
            <div class="modal-date__title">Редактирование данных</div>
            <form class="modal-date__wrapper">
                <div class="modal-date__wrapper-input">
                    <span>Фамилия, имя, отчествество (последнее необязательно)</span>
                    <input v-model="user.profile_filed.second_name" type="text"/>
                </div>
                <div
                    v-click-outside="hideCalendar"
                    class="modal-date__wrapper-input modal-date__wrapper-calendar"
                >
                    <span>Дата рождения</span>
                    <div
                        class="modal-date__wrapper-input_value"
                        @click="isOpenCalendar = true"
                    >
                        {{ timeToStampDate | dateFormat }}

                        <transition name="fade">
                            <v-date-picker
                                v-show="isOpenCalendar"
                                v-model="user.profile_filed.born"
                            />
                        </transition>
                    </div>
                </div>
                <div class="modal-date__wrapper-select">
                    <span>Город</span>
                    <v-select
                        v-model="user.profile_filed.city"
                        :options="userSettings.city.options"
                        height="62"
                        label="name"
                    ></v-select>
                </div>
                <div class="modal-date__wrapper-select">
                    <div class="choose-block">
                        <span>Пол</span>
                        <div class="input">
                            <input
                                id="male"
                                v-model="user.profile_filed.gender"
                                type="radio"
                                value="male"
                            />
                            <span>Мужской</span>
                        </div>
                        <div class="input">
                            <input
                                id="female"
                                v-model="user.profile_filed.gender"
                                type="radio"
                                value="female"
                            />
                            <span>Женский</span>
                        </div>
                    </div>
                </div>
                <div class="modal-date__wrapper-submit btn" @click="changeUserData()">
                    <button type="button">Сохранить изменения</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            isOpenCalendar: false,
            date: new Date(),
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/myInfo',
            timeToStampDate: 'user/timeToStampDate',
            userSettings: 'user/settings',
        }),
    },
    methods: {
        ...mapActions({
            getChangeUserData: 'user/getChangeUserData',
            toggleModals: 'settings/toggleModals',
        }),

        hideCalendar() {
            this.isOpenCalendar = false;
        },

        toggleModal() {
            this.toggleModals({
                parent: 'profile',
                children: 'data',
            });
        },

        async changeUserData() {
            if (
                this.user.profile_filed.second_name &&
          this.user.profile_filed.born &&
          this.user.profile_filed.city
            ) {
                const res = await this.getChangeUserData({
                    id: this.user.id,
                    born: this.user.profile_filed.born,
                    city: typeof this.user.profile_filed.city === 'object'
                        ? this.user.profile_filed.city.name
                        : this.user.profile_filed.city,
                    name: this.user.profile_filed.second_name,
                    gender: this.user.profile_filed.gender,
                });

                if (res.status === 200) {
                    this.toggleModal();
                } else {
                    alert('Ой, что-то пошло не так.');
                }
            } else {
                alert('Заполните все поля!');
            }
        },
    },
};
</script>

<style scoped>
</style>
