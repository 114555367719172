<template>
    <div class="modal-inner">
        <button class="modal-inner__close" @click="toggleModal">
            <img alt="" src="@/assets/images/close-icon.svg"/>
        </button>
        <div class="container">
            <div class="modal-inner__title">Настройки игр</div>
            <div class="modal-inner__wrapper">
                <div
                    :class="{
                        'auth__form-input_error': !isValidatePassed
                    }"
                    class="modal-upload-video__wrapper-input"
                >
                    <span>ID виртономики</span>
                    <input
                        v-model="myVirtonomica.game_id"
                        placeholder="Введите ID виртуномики"
                        type="number"
                    />
                </div>
                <div v-if="!isValidatePassed" class="modal-inner__wrapper-error">
                    ID должен состоять только из цифр.
                </div>
                <button :disabled="!isValidatePassed" class="modal-inner__wrapper-submit btn" @click="updateData">
                    Сохранить изменения
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ModalEditUserGames',
    computed: {
        ...mapGetters({
            userSettings: 'user/myInfo',
            myVirtonomica: 'user/myVirtonomica',
        }),

        isValidatePassed() {
            return /[0-9]/.test(this.myVirtonomica.game_id);
        },
    },
    methods: {
        ...mapActions({
            updateUserGames: 'user/updateUserGames',
            toggleModals: 'settings/toggleModals',
        }),

        toggleModal() {
            this.toggleModals({
                parent: 'profile',
                children: 'games',
            });
        },

        async updateData() {
            if (this.isValidatePassed) {
                const params = {
                    id: this.myVirtonomica.id,
                    gameId: Number(this.myVirtonomica.game_id),
                };

                const res = await this.updateUserGames(params);

                if (res.status === 200) {
                    this.toggleModal();
                }
            }
        },
    },
};
</script>

<style>

</style>
