var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth auth-reset"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('form',{staticClass:"auth__form",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"auth__form-input",class:{
                    'auth__form-input_error':
                        !_vm.$v.password.minLength ||
                        _vm.$v.password.$error ||
                        (!_vm.$v.password.PASSWORD_CHECK && this.password.length > 0) ||
                        this.resetpassErrors.password.length > 0,
                }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"placeholder":"Новый пароль","type":"password"},domProps:{"value":(_vm.password)},on:{"input":[function($event){if($event.target.composing)return;_vm.password=$event.target.value},function($event){_vm.resetpassErrors.password = []}]}})]),_c('div',{staticClass:"auth__form-input",class:{
                    'auth__form-input_error':
                        !_vm.$v.passwordRepeat.minLength ||
                        !_vm.$v.passwordRepeat.sameAsPassword ||
                        _vm.$v.passwordRepeat.$error,
                }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordRepeat),expression:"passwordRepeat"}],attrs:{"placeholder":"Подтвердите новый пароль","type":"password"},domProps:{"value":(_vm.passwordRepeat)},on:{"input":[function($event){if($event.target.composing)return;_vm.passwordRepeat=$event.target.value},function($event){_vm.resetpassErrors.password = []}]}}),_c('div',{staticClass:"auth__form-error"},[_vm._l((_vm.resetpassErrors.password),function(error){return _c('div',{key:error.index},[_vm._v(" "+_vm._s(error)+" ")])}),(!_vm.$v.password.PASSWORD_CHECK && this.password.length > 0)?_c('div',[_vm._v(" Некорректный новый пароль! ")]):_vm._e(),(!_vm.$v.passwordRepeat.sameAsPassword)?_c('div',[_vm._v(" Пароли не совпадают! ")]):_vm._e(),(!_vm.$v.password.minLength)?_c('div',[_vm._v(" Мин. длина пароля 8 символов! ")]):_vm._e()],2)]),(_vm.success)?_c('div',{staticClass:"auth__form-success"},[_c('span',[_vm._v("Пароль успешно изменен, авторизуйтесь!")]),_c('br')]):_vm._e(),_c('div',{staticClass:"auth__form-submit",class:{ success: _vm.success }},[_c('input',{staticClass:"btn",attrs:{"type":"submit","value":"Сохранить"}})]),_c('router-link',{staticClass:"auth__form-link",attrs:{"to":"/login"}},[_vm._v("Отменить и оставить старый пароль ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth__logo"},[_c('img',{attrs:{"alt":"","src":require("@/assets/images/logo-2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth__text"},[_vm._v(" Ваш "),_c('span',[_vm._v("старый пароль был сброшен.")]),_vm._v(" Теперь вам необходимо установить новый пароль. ")])
}]

export { render, staticRenderFns }