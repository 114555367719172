<script>
export default {
    props: {
        titleRating: {
            type: String,
            default: '',
        },
        levelRating: {
            type: String,
            default: '',
        },
        placeRating: {
            type: String,
            default: '',
        },
        totalPlacesRating: {
            type: String,
            default: '',
        },
        gameLink: {
            type: String,
            default: '',
        },
        pointsTotal: {
            type: [Number, String],
            default: 0,
        },
        pointsCurrent: {
            type: [Number, String],
            default: 0,
        },
    },
    computed: {
        barWidth() {
            return 100 / this.pointsTotal * this.pointsCurrent;
        },
    },
};
</script>

<template>
    <div class="general-block">
        <div class="progress-block">
            <div class="circle-bg">
                <div class="circle-progress-bg">
                    <div
                        :style="`background: radial-gradient(closest-side, white 80%, transparent 80% 100%), conic-gradient(#FF7F00 ${barWidth}%, transparent 0);`"
                        class="circle-progress">
                    </div>
                    <div class="circle-text">
                        <div class="percentage-txt">
                            <p>{{ parseInt(barWidth) }}%</p>
                        </div>
                        <div class="points-txt">
                            <p>{{ parseInt(pointsTotal) }} очков</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="progressbar">
      <div class="progress-line" :style="{width: barWidth + '%'}">
      </div>
    </div> -->
        </div>
        <div class="rating-text">
            <p class="rat-text">{{ titleRating }}</p>
            <div class="rating">
                <div class="level">
                    <img alt="" src="@/assets/images/profile/trophie.svg">
                    <p>Вы достигли {{ levelRating }} уровня!</p>
                </div>
                <div class="place">
                    <img alt="" src="@/assets/images/profile/places.svg">
                    <div>
                        <p>{{ placeRating }} место</p>
                        <p class="gray">из {{ totalPlacesRating }} игроков</p>
                    </div>
                </div>
            </div>
            <div class="shift">
                <a :href="gameLink">Перейти к игре →</a>
            </div>
        </div>
    </div>
</template>

<style scoped>
.general-block {
  display: flex;
  padding: 15px;
  width: 31%;
  border-radius: 5px;
  justify-content: space-between;
  background-color: #FFFFFF;
}

.gray {
  color: #B3B3B3;
}

.rating-text {
  margin: 20px;
}

.rating-text .rat-text {
  font-size: 17px;
  margin-bottom: 5px;
  width: 200px;
}

.rating-text img {
  width: 20px;
}

.level {
  display: flex;
  font-weight: 400;
}

.level p {
  font-size: 12px;
  margin-left: 7px;
}

.place {
  display: flex;
}

.place p {
  font-size: 12px;
  margin-left: 7px;
}

.shift a {
  color: #B3B3B3;
  font-size: 12px;
  margin-top: 11px;
  font-family: 'Inter';
}

.progress-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.circle-bg {
  background: #ffffff;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-progress-bg {
  border-radius: 50%;
  border: 7px solid #b3b3b3;
  width: 95px;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-progress {
  position: absolute;
  border-radius: 50%;
  width: 99px;
  height: 99px;
  z-index: 99;
}

.circle-text {
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.percentage-txt p {
  font-size: 17px;
  text-align: center;
}

.points-txt p {
  font-size: 11px;
  text-align: center;
  color: #b3b3b3;
}

@media (max-width: 1100px) {
  .progress-block {
    margin-left: 8px;
  }

  .circle-bg {
    background: #ffffff;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-progress-bg {
    border-radius: 50%;
    border: 6px solid #b3b3b3;
    width: 67px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-progress {
    position: absolute;
    border-radius: 50%;
    width: 68px;
    height: 68px;
    z-index: 99;
  }

  .percentage-txt p {
    font-size: 13px;
    text-align: center;
  }

  .points-txt p {
    font-size: 8px;
    text-align: center;
    color: #b3b3b3;
  }

  .rating-text {
    margin: 15px;
  }

  .rating-text .rat-text {
    font-size: 14px;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .level p {
    font-size: 10px;
    margin-left: 10px;
  }

  .place p {
    font-size: 10px;
    margin-left: 7px;
  }

  .shift a {
    font-size: 10px;
    margin-top: 11px;
  }

}

@media (max-width: 700px) {
  .general-block {
    padding: 10px;
    padding-left: 20px;
    width: 80%;
    border-radius: 5px;
    justify-content: space-between;
    background-color: #FFFFFF;
  }

  /* .circle-bg {
    background: #ffffff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-progress-bg {
    border-radius: 50%;
    border: 6px solid #b3b3b3;
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-progress {
    position: absolute;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    z-index: 99;
  }

  .percentage-txt p {
    font-size: 10px;
    text-align: center;
  }

  .points-txt p {
    font-size: 8px;
    text-align: center;
    color: #b3b3b3;
  }

  .rating-text {
    margin: 15px;
  }

  .rating-text .rat-text {
    font-size: 14px;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .level p {
    font-size: 10px;
    margin-left: 10px;
  }

  .place p {
    font-size: 10px;
    margin-left: 7px;
  }

  .shift a {
    font-size: 10px;
    margin-top: 11px;
  } */
}
</style>
