var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth auth-register"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('form',{staticClass:"auth__form",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"auth__form-input",class:{
                    'auth__form-input_error':
                        _vm.$v.username.$error ||
                        this.registerErrors.login.length > 0 ||
                        !_vm.$v.username.minLength,
                }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.username),expression:"username",modifiers:{"trim":true}}],attrs:{"placeholder":"Логин","type":"text"},domProps:{"value":(_vm.username)},on:{"input":[function($event){if($event.target.composing)return;_vm.username=$event.target.value.trim()},function($event){_vm.registerErrors.login = []}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"auth__form-error"},[_vm._l((_vm.registerErrors.login),function(error){return _c('div',{key:error.index},[_vm._v(" "+_vm._s(error)+" ")])}),(!_vm.$v.username.minLength)?_c('div',[_vm._v(" Мин. длина логина 4 символа! ")]):_vm._e()],2)]),_c('div',{staticClass:"auth__form-input",class:{
                    'auth__form-input_error':
                        _vm.$v.email.$error ||
                        (!_vm.$v.email.EMAIL_CHECK && this.email.length > 0) ||
                        this.registerErrors.email.length > 0,
                }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],attrs:{"placeholder":"Электронная почта","type":"email"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.email=$event.target.value.trim()},function($event){_vm.registerErrors.email = []}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"auth__form-error"},[_vm._l((_vm.registerErrors.email),function(error){return _c('div',{key:error.index},[_vm._v(" "+_vm._s(error)+" ")])}),(!_vm.$v.email.EMAIL_CHECK && this.email.length > 0)?_c('div',[_vm._v(" Некорректная почта! ")]):_vm._e()],2)]),_c('div',{staticClass:"auth__form-input",class:{
                    'auth__form-input_error':
                        !_vm.$v.password.minLength ||
                        _vm.$v.password.$error ||
                        (!_vm.$v.password.PASSWORD_CHECK && this.password.length > 0) ||
                        this.registerErrors.password.length > 0,
                }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}}],attrs:{"placeholder":"Пароль","type":"password"},domProps:{"value":(_vm.password)},on:{"input":[function($event){if($event.target.composing)return;_vm.password=$event.target.value.trim()},function($event){_vm.registerErrors.password = []}],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"auth__form-input",class:{
                    'auth__form-input_error':
                        !_vm.$v.passwordRepeat.minLength ||
                        !_vm.$v.passwordRepeat.sameAsPassword ||
                        _vm.$v.passwordRepeat.$error,
                }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.passwordRepeat),expression:"passwordRepeat",modifiers:{"trim":true}}],attrs:{"placeholder":"Пароль","type":"password"},domProps:{"value":(_vm.passwordRepeat)},on:{"input":[function($event){if($event.target.composing)return;_vm.passwordRepeat=$event.target.value.trim()},function($event){_vm.registerErrors.password = []}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"auth__form-error"},[_vm._l((_vm.registerErrors.password),function(error){return _c('div',{key:error.index},[_vm._v(" "+_vm._s(error)+" ")])}),(!_vm.$v.password.PASSWORD_CHECK && this.password.length > 0)?_c('div',[_vm._v(" Некорректный новый пароль! ")]):_vm._e(),(!_vm.$v.passwordRepeat.sameAsPassword)?_c('div',[_vm._v(" Пароли не совпадают! ")]):_vm._e(),(!_vm.$v.password.minLength)?_c('div',[_vm._v(" Мин. длина пароля 8 символов! ")]):_vm._e()],2)]),(_vm.success)?_c('div',{staticClass:"auth__form-success"},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"auth__form-submit",class:{ success: _vm.success }},[_c('input',{staticClass:"btn",attrs:{"type":"submit","value":"Зарегистрироваться"}})])]),_c('div',{staticClass:"auth__form-register"},[_c('span',[_vm._v("У вас уже есть аккаунт?")]),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Авторизоваться")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth__logo"},[_c('img',{attrs:{"alt":"","src":require("@/assets/images/logo-2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("Поздравляем - Вы успешно зарегистрировались!"),_c('br'),_vm._v(" На вашу почту отправлено пиьсмо для активации.")])
}]

export { render, staticRenderFns }