<template>
    <div
        class="sidebar"
    >
        <div class="sidebar-content">
            <span class="cross" @click="toggleSideBar">
                &#x2715;
            </span>
            <router-link class="sidebar__logo" to="/">
                <img alt="" src="@/assets/images/logo.svg"/>
            </router-link>

            <div class="sidebar-menu">
                <ul style="width: 100%;">
                    <li class="sidebar__list-item" @click="toggleSideBar">
                        <router-link to="/testing">Тестирование</router-link>
                    </li>
                    <li class="sidebar__list-item" @click="toggleSideBar">
                        <router-link to="/games">Игры</router-link>
                    </li>
                    <li class="sidebar__list-item" @click="toggleSideBar">
                        <router-link to="/championship">Чемпионаты</router-link>
                    </li>
                    <li class="sidebar__list-item" @click="toggleSideBar">
                        <router-link to="/videos">Видео</router-link>
                    </li>
                    <!-- <li class="sidebar__list-item" @click="toggleSideBar">
              <router-link to="/chat">Диалоги</router-link>
          </li> -->
                    <li class="sidebar__list-item" @click="toggleSideBar">
                        <router-link to="/partners">Партнерам</router-link>
                    </li>
                    <li class="sidebar__list-item" @click="toggleSideBar">
                        <router-link to="/contacts">Контакты</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    data() {
        return {};
    },
    directives: {
    // clickOutside,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        toggleSideBar() {
            if (this.show) {
                this.$emit('close-sidebar');
            }
        },
    },
};

</script>

<style lang="scss">
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #1d3e51;
  height: 100vh;
  width: 100wh;
  z-index: 50;
  padding: 0;
  right: 0;
  margin: 0;
}

.sidebar__logo {
  width: 40px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.sidebar-menu {
  width: 100%;
  padding-top: 20px;
}

.sidebar__list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin: 20px 0;

  & a {
    color: white;
    padding: 0 8px;
  }
}

.cross {
  position: absolute;
  margin-top: 20px;
  font-size: 24px;
  right: 6%;
  top: -10px;
  color: white;
  cursor: pointer;
}
</style>
