<template>
    <div class="error-wrapper">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'ErrorLayout',
};
</script>

<style lang="scss">
.error-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
}
</style>
