import { send } from '@/global/functions';
import router from '@/router';

export const state = {
    user: {},
};

export const getters = {
    auth: state => state,
};

export const mutations = {
    CLEAR_USER() {
        localStorage.removeItem('token');
        localStorage.removeItem('token-access');
        localStorage.removeItem('tabsContentProfileIndex');
        location.reload();
    },

    COMPLETE_REGISTER() {
        setTimeout(() => {
            router.push({ path: '/login' });
        }, 4000);
    },
};

export const actions = {
    async authorization({ commit }, {
        email,
        password,
        saveUser,
    }) {
        commit('settings/CLEAR_ERRORS', null, { root: true });

        try {
            const payload = {
                username: email,
                password,
            };
            const result = await send({
                uri: 'auth/jwt/create/',
                payload,
                method: 'POST',
                publicRequest: true,
            });

            if (result && result.detail) {
                commit(
                    'settings/UPDATE_AUTH_ERRORS',
                    {
                        res: ['Неверный логин/пароль'],
                        type: 'loginPassword',
                    },
                    { root: true }
                );
            } else if (result && result.access) {
                if (saveUser) {
                    localStorage.setItem('name', email);
                }
                localStorage.setItem('token', result.refresh);
                localStorage.setItem('token-access', result.access);
                location.replace('/profile');
            } else {
                commit(
                    'settings/UPDATE_AUTH_ERRORS',
                    {
                        res: ['Неверный логин/пароль'],
                        type: 'loginPassword',
                    },
                    { root: true }
                );
            }
        } catch (e) {
            console.log(e);
            commit(
                'settings/UPDATE_AUTH_ERRORS',
                {
                    res: [e],
                    type: 'loginPassword',
                },
                { root: true }
            );
        }
    },

    async authorizationGoogle(state, payload) {
        try {
            const res = await send({
                uri: 'auth/google/',
                method: 'POST',
                payload,
                publicRequest: true,
            });
            if (res.access) {
                localStorage.setItem('token', res.refresh);
                localStorage.setItem('token-access', res.access);
                location.replace('/profile');
            }
        } catch (e) {
            console.error(e);
        }
    },
    async authorizationVk(state, payload) {
        try {
            const res = await send({
                uri: 'auth/vk/',
                method: 'GET',
                publicRequest: true,
            });
            if (res.access) {
                localStorage.setItem('token', res.refresh);
                localStorage.setItem('token-access', res.access);
                location.replace('/profile');
            }
        } catch (e) {
            console.error(e);
        }
    },

    async register({ commit }, req) {
        commit('settings/CLEAR_ERRORS', null, { root: true });

        try {
            const resEmail = await send({
                uri: 'Check_email',
                payload: { email: req.email },
                method: 'POST',
                publicRequest: true,
            });
            if (resEmail.status === 200) {
                const res = await send({
                    uri: 'auth/users/',
                    payload: {
                        username: req.username,
                        email: req.email,
                        password: req.password,
                    },
                    publicRequest: true,
                    method: 'POST',
                });
                if (res.id) {
                    commit('COMPLETE_REGISTER');
                    return true;
                } else if (res.password) {
                    commit(
                        'settings/UPDATE_REGISTER_ERRORS',
                        {
                            res: res.password,
                            type: 'password',
                        },
                        { root: true }
                    );
                } else if (res.username) {
                    commit(
                        'settings/UPDATE_REGISTER_ERRORS',
                        {
                            res: res.username,
                            type: 'login',
                        },
                        { root: true }
                    );
                }
            } else {
                commit(
                    'settings/UPDATE_REGISTER_ERRORS',
                    {
                        res: resEmail.email,
                        type: 'email',
                    },
                    { root: true }
                );
            }
        } catch (err) {
            console.log(err);
        }
    },

    async logout({ commit }) {
        try {
            await commit('CLEAR_USER');
        } catch (e) {
            console.log(e);
        }
    },

    async forgotPassword({ commit }, req) {
        commit('settings/CLEAR_ERRORS', null, { root: true });

        try {
            const resEmail = await send({
                uri: 'Check_email',
                payload: { email: req.email },
                method: 'POST',
            });
            if (resEmail.email) {
                const payload = { email: req.email };
                const res = await send({
                    uri: 'auth/users/reset_password/',
                    payload,
                    method: 'POST',
                    resType: 'full',
                });

                if (res.status === 204) {
                    return true;
                }
                alert('Ой, что-то пошло не так.');
            } else {
                commit(
                    'settings/UPDATE_RESETPASS_ERRORS',
                    {
                        res: ['Данной почты не существует'],
                        type: 'email',
                    },
                    { root: true }
                );
            }
        } catch (e) {
            console.log(e);
        }
    },

    async confirmForgotPassword({ commit }, req) {
        commit('settings/CLEAR_ERRORS', null, { root: true });

        try {
            const payload = {
                uid: req.uuid,
                token: req.token,
                new_password: req.password,
            };
            const res = await send({
                uri: 'auth/users/reset_password_confirm/',
                payload,
                method: 'POST',
                resType: 'full',
            });
            if (res.status === 204) {
                return true;
            } else if (res.status === 400) {
                commit(
                    'settings/UPDATE_RESETPASS_ERRORS',
                    {
                        res: ['Данный пароль слишком распространен'],
                        type: 'password',
                    },
                    { root: true }
                );
            }
        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
