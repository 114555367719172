<template>
    <swiper-slide class="products__wrapper-item">
        <router-link
            :style="{
                'background-image': `url(${item.img})`,
            }"
            class="products__wrapper-item_img"
            to="/"
        ></router-link>
        <div class="products__wrapper-item_bottom">
            <div class="products__wrapper-item_title">
                {{ item.title }}
            </div>
            <div class="products__wrapper-item_price">{{ item.price }} ₽</div>
            <button class="products__wrapper-item_btn">Купить</button>
        </div>
    </swiper-slide>
</template>

<script>
export default {
    props: ['item'],
};
</script>
