<template>
    <div id="app">
        <component :is="layout" v-if="dataLoaded"></component>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import auth from '@/router/middleware/auth';
import landingLayout from './layouts/landingLayout.vue';
import defaultLayout from './layouts/DefaultLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';
import ErrorLayout from './layouts/ErrorLayout.vue';

export default {
    data() {
        return {
            dataLoaded: false,
        };
    },
    async created() {
        if (localStorage.getItem('token-access')) {
            await this.localGetUserData();

            // Check if user data is loaded, if not, fetch it
            if (!this.$store.getters['user/myInfo']) {
                await this.$store.dispatch('user/fetchUserData');
            }

            const requiresAuth = this.$route.meta.middleware && this.$route.meta.middleware.includes(auth);

            if (requiresAuth) {
                const userRole = this.$store.getters['user/myInfo'].profile_filed.role;
                const allowedForRole3 = ['/expert']; // Paths allowed for role 3

                if (userRole === 3 && !allowedForRole3.some(path => this.$route.path.startsWith(path))) {
                    await this.$router.push({ path: '/expert' });
                }
            }
        }
        this.dataLoaded = true;
    },
    computed: {
        ...mapGetters({
            user: 'user/myInfo',
            cropper: 'settings/cropper',
            convertJWT: 'globals/convertJWT',
        }),

        layout() {
            switch (this.$route.meta.layout) {
                case 'auth-layout':
                    return 'AuthLayout';
                case 'error-layout':
                    return 'ErrorLayout';
                case 'landing':
                    return 'landingLayout';
                default:
                    return 'defaultLayout';
            }
        },
    },
    // sockets: {
    //   connect: function () {
    //       console.log('socket connected')
    //   }
    // },
    components: {
        defaultLayout,
        AuthLayout,
        ErrorLayout,
        landingLayout,
    },
    methods: {
        ...mapActions({
            getUserData: 'user/getUserData',
        }),

        async localGetUserData() {
            await this.getUserData({
                token: localStorage.getItem('token-access'),
                id: this.convertJWT.user_id,
                url: this.$route,
            });
            this.dataLoaded = true;
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/style/main.scss";
</style>
