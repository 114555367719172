<template>
    <div class="modal-password">
        <button class="modal-password__close" @click="toggleModal">
            <img alt="" src="@/assets/images/close-icon.svg"/>
        </button>
        <div class="container">
            <div class="modal-password__title">Изменение пароля</div>
            <form class="modal-password__wrapper">
                <div
                    :class="{
                        'auth__form-input_error':
                            $v.password.current.$error ||
                            this.changepassErrors.password.length > 0,
                    }"
                    class="modal-password__wrapper-input"
                >
                    <span>Старый пароль</span>
                    <input
                        v-model.trim="password.current"
                        placeholder="Введите старый пароль"
                        type="password"
                        @input="changepassErrors.password = []"
                    />
                </div>
                <div
                    :class="{
                        'auth__form-input_error':
                            !$v.password.new.minLength ||
                            $v.password.new.$error ||
                            (!$v.password.new.PASSWORD_CHECK && this.password.new.length > 0),
                    }"
                    class="modal-password__wrapper-input"
                >
                    <span>Новый пароль</span>
                    <input
                        v-model.trim="password.new"
                        placeholder="Придумайте новый пароль"
                        type="password"
                        @input="changepassErrors.password = []"
                    />
                </div>
                <div
                    :class="{
                        'auth__form-input_error':
                            !$v.password.confirm.minLength ||
                            !$v.password.confirm.sameAsPassword ||
                            $v.password.confirm.$error,
                    }"
                    class="modal-password__wrapper-input"
                >
                    <span>Подтверждение нового пароля</span>
                    <input
                        v-model.trim="password.confirm"
                        placeholder="Подтвердите новый пароль"
                        type="password"
                        @input="changepassErrors.password = []"
                    />
                </div>
                <div class="modal-password__wrapper-error">
                    <div v-for="error in changepassErrors.password" :key="error.index">
                        {{ error }}
                    </div>
                    <div
                        v-if="
                            !$v.password.new.PASSWORD_CHECK && this.password.new.length > 0
                        "
                    >
                        Некорректный новый пароль!
                    </div>
                    <span
                        v-if="!$v.password.confirm.sameAsPassword"
                    >Пароли не совпадают!</span
                    ><br/>
                    <span
                        v-if="!$v.password.new.minLength"
                    >Мин. длина пароля 8 символов!</span
                    >
                </div>
                <div class="modal-password__wrapper-submit btn" @click="changePassword">
                    Сохранить изменения
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { REGEX_PASSWORD } from '@/global/constants';

const PASSWORD_CHECK = value => REGEX_PASSWORD.test(value);

export default {
    data() {
        return {
            password: {
                current: '',
                new: '',
                confirm: '',
            },
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/myInfo',
            changepassErrors: 'settings/changepassErrors',
        }),
    },
    methods: {
        ...mapActions({
            getChangePassword: 'user/getChangePassword',
            UPDATE_CHANGEPASS_ERRORS: 'settings/UPDATE_CHANGEPASS_ERRORS',
            toggleModals: 'settings/toggleModals',
        }),

        toggleModal() {
            this.toggleModals({
                parent: 'profile',
                children: 'password',
            });
        },

        async changePassword() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                if (
                    this.password.current &&
            this.password.new &&
            this.password.confirm
                ) {
                    if (this.password.new === this.password.confirm) {
                        const res = await this.getChangePassword({
                            id: this.user.id,
                            current: this.password.current,
                            new: this.password.new,
                        });

                        if (res.status === 200) {
                            this.toggleModal();
                        } else if (res.password) {
                            this.UPDATE_CHANGEPASS_ERRORS({
                                res: res.password,
                                type: 'password',
                            });
                        }
                    } else {
                        alert('Новый пароль не совпадает!');
                    }
                } else {
                    alert('Заполните все поля!');
                }
            }
        },
    },
    validations: {
        password: {
            current: {
                required,
            },
            new: {
                required,
                minLength: minLength(8),
                PASSWORD_CHECK,
            },
            confirm: {
                required,
                minLength: minLength(8),
                sameAsPassword: sameAs('new'),
            },
        },
    },
};
</script>
