<template>
    <section class="testing">
        <div v-if="!isLoading" class="container">
            <div class="testing__title title">Тестирование</div>
            <div class="testing__wrapper">
                <SkeletonCard
                    v-for="item in 8"
                    :key="item"
                    primary="#e3e3e3"
                    structure="700112"/>
            </div>
        </div>
        <div v-else class="container">
            <div class="testing__title title">Тестирование</div>
            <div v-if="testingList.length" class="testing__wrapper">
                <TestingCard v-for="test in testingList" :key="test.id" :data="test"/>
            </div>
            <div v-else class="testing__wrapper">пусто</div>
        </div>
    </section>
</template>

<script>
import TestingCard from '../components/testing/TestingCard.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'TestDetailsView',
    components: {
        TestingCard,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            testingList: 'testing/testingList',
            user: 'user/myInfo',
        }),
    },
    created() {
        this.requestData();
    },
    methods: {
        ...mapActions({
            getTestingList: 'testing/getTestingList',
        }),

        async requestData() {
            const params = {
                id_user: this.user.id,
            };
            const response = await this.getTestingList(params);
            if (response) {
                this.isLoading = true;
            }
        },
    },
};
</script>
