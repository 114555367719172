<script>
import {
    mapGetters
} from 'vuex';

export default ({
    computed: {
        champId() {
            return this.$route.params.id;
        },
        ...mapGetters({
            champResult: 'user/championshipResults',
        }),
        currentChamp() {
            return this.champResult.find(x => x.id === this.champId);
        },
    },
});
</script>

<template>
    <div>
        <div class="inner-result">
            <div class="champ-block-inner">
                <p>
                    <slot>{{ currentChamp.name_game }}</slot>
                </p>
            </div>
            <div class="champ-info">
                <div class="champ-name">
                    <p class="first-text">{{ currentChamp.name_game }}</p>
                    <p>{{ currentChamp.team_name }}</p>
                </div>
                <div class="champ-list-block">
                    <div class="champ-list">
                        <img alt="" src="@/assets/images/profile/trophie-place.svg">
                        <p>№{{ currentChamp.place }}</p>
                    </div>
                    <div class="champ-list">
                        <img alt="" src="@/assets/images/profile/money.svg">
                        <p>{{ currentChamp.revenue }} ₽</p>
                    </div>
                    <div class="champ-list">
                        <img alt="" src="@/assets/images/profile/money-bag.svg">
                        <p>{{ currentChamp.netProfit }} ₽</p>
                    </div>
                </div>
            </div>
        </div>
        <router-link :to="{name: 'ChampResult'}">
            <p class="back-btn">Назад</p>
        </router-link>
    </div>
</template>

<style scoped>
.inner-result {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 45px;
}

.first-text {
  margin-right: 15px;
}

.champ-info {
  height: 100%;
  width: 40%;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.champ-name {
  display: flex;
  margin: 20px;
  justify-content: center;
}

.champ-name p {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 0px;
  width: 100%;
  border-radius: 5px;
  background: #204B6F;
  color: #FFFFFF;
}

.champ-list {
  display: flex;
  margin: 20px;
  align-items: center;
}

.champ-list img {
  width: 35px;
  margin-right: 20px;
}

.champ-list p {
  font-size: 21px;
}

.champ-block-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 155px;
  border-radius: 5px;
  background-color: #204B6F;
}

.champ-block-inner p {
  text-align: center;
  color: #FFF;
  font-size: 23px;
}

.back-btn-main {
  display: flex;
}

.back-btn {
  color: #FFFFFF;
  background-color: #204B6F;
  border: 1.75px solid #204B6F;
  padding: 12px 25px;
  border-radius: 5px;
  transition: .5s;
  display: flex;
}

.back-btn:hover {
  border: 1.75px solid #204B6F;
  color: #204B6F;
  background-color: transparent;
}

@media (max-width: 700px) {
  .champ-block-inner {
    width: 90%;
  }

  .champ-info {
    width: 90%;
  }

  .inner-result {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .back-btn {
    margin-left: 20px;
    margin-top: 10px;
  }
}
</style>
