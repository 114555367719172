<template>
    <div class="modal-add-comand">
        <button class="modal-add-comand__close">
            <img alt="" src="@/assets/images/close-icon.svg" @click="toggleModal"/>
        </button>
        <div class="container">
            <div class="modal-add-comand__title">Добавление команды</div>
            <form class="modal-add-comand__wrapper">
                <div class="modal-add-comand__wrapper-input">
                    <span>Название команды</span>
                    <input v-model="command.name" type="text"/>
                </div>

                <!--                <div class="modal-add-comand__wrapper-select">-->
                <!--                    <span>Номинация</span>-->
                <!--                    <v-select-->
                <!--                        label="name"-->
                <!--                        height="62"-->
                <!--                        :options="inviting.nominations"-->
                <!--                        v-model="command.nominationId"-->
                <!--                        placeholder="Выберите из списка"-->
                <!--                    ></v-select>-->
                <!--                </div>-->

                <div class="modal-date__wrapper-select">
                    <span>Город</span>
                    <v-select
                        v-model="user.profile_filed.city"
                        :options="userSettings.city.options"
                        height="62"
                        label="name"
                    ></v-select>
                </div>

                <div class="modal-add-comand__wrapper-submit btn" @click="addCommand">
                    <button type="button">Создать команду</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    name: 'ModalChampionshipAddCommand',
    data() {
        return {
            command: {
                name: '',
                nominationId: null,
                city: '',
                description: '',
            },
        };
    },
    computed: {
        ...mapGetters({
            championship: 'championship/currentChampionship',
            inviting: 'championship/inviting',
            user: 'user/myInfo',
            userSettings: 'user/settings',
        }),
    },
    created() {
        this.getNominationsList();
    },
    methods: {
        ...mapActions({
            addCommandForChampionship: 'championship/addCommandForChampionship',
            getNominationsList: 'championship/getNominationsList',
            getTeamMembersDetails: 'championship/getTeamMembersDetails',
            toggleModals: 'settings/toggleModals',
        }),

        ...mapMutations({
            SET_TEAM_TAB_INDEX: 'championship/SET_TEAM_TAB_INDEX',
        }),

        toggleModal() {
            this.toggleModals({
                parent: 'championship',
                children: 'teams',
            });
        },

        async addCommand() {
            const data = {
                name: this.command.name,
                description: this.command.description,
                // nomination: this.command.nominationId.id,
                id_user: this.user.id,
                id_championship: this.championship.id,
                city_name: this.command.city,
            };

            const res = await this.addCommandForChampionship(data);

            if (res) {
                this.toggleModal();
                this.SET_TEAM_TAB_INDEX(0);

                await this.getTeamMembersDetails({
                    teamId: res.id,
                    wait: true,
                });
            }
        },
    },
};
</script>
