<script>
import ModalEditDate from '@/components/modals/profile/ModalEditDate.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        ModalEditDate,
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
            date: 'user/convertedDate',
            userData: 'user/myInfo',
            modals: 'settings/modals',
            cropper: 'settings/cropper',
        }),
        city() {
            return this.userData.profile_filed.city.name === undefined || !this.userData.profile_filed.city
                .name
                ? this.userData.profile_filed.city
                : this.userData.profile_filed.city.name;
        },
    },
    data: () => ({
        defaultImg: require('@/assets/images/profile/avatar.svg'),
    }),
    methods: {
        ...mapMutations({
            changeCropperImage: 'settings/CHANGE_CROPPER_IMAGE',
        }),

        ...mapActions({
            toggleModals: 'settings/toggleModals',
            getUserData: 'user/getUserData',
            logout: 'auth/logout',
        }),

        toggleModal(children) {
            this.toggleModals({
                parent: 'profile',
                children,
            });
        },
    },
};
</script>

<template>
    <div class="main-section">
        <div class="block-section">
            <div class="top-expert">
                <div class="avatar-expert">
                    <div v-if="userData.profile_filed" class="avatar-img">
                        <img
                            :src="cropper.imagesPreviews.userinfo ||
                                userData.profile_filed.img ||
                                defaultImg"
                            alt=""
                        >
                    </div>
                </div>
                <div class="info-expert">
                    <div class="username">
                        <p>{{ userData.profile_filed.second_name }}</p>
                    </div>
                    <div class="bg">
                        <p>
                            <span>{{ userData.username }}</span>
                        </p>
                    </div>
                    <div class="bg">
                        <p>г. <span>{{ userData.profile_filed.city }}</span></p>
                    </div>
                    <div class="bg">
                        <p class="flex-line" @click="logout">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-logout"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#ffffff"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M14 8v-2a4 4 0 0 1 4 -4h2a4 4 0 0 1 4 4v12a4 4 0 0 1 -4 4h-2a4 4 0 0 1 -4 -4v-2"/>
                                <path d="M7 12h14"/>
                                <path d="M11 16l-4 -4l4 -4"/>
                            </svg>
                            <span>
                                выйти
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="edit-expert">
                <div class="edit-link-expert">
                    <label for="imageUserInfo">Изменить фотографию</label>
                    <input
                        id="imageUserInfo"
                        accept="image/*"
                        hidden
                        type="file"
                        @change="changeCropperImage"/>
                </div>

                <div class="edit-link-expert">
                    <span @click="toggleModal('data')">
                        Редактировать данные
                    </span>
                </div>
            </div>
        </div>
        <transition name="fade">
            <ModalEditDate v-if="modals.profile.data"/>
        </transition>
    </div>
</template>

<style>
.main-section {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.top-expert {
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.block-section {
  padding: 40px;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  background: #0D2144B2;
  box-shadow: 0 4px 4px 0 #54464640;
  border: 3px solid #D9D9D980;
  border-radius: 20px;
}

.avatar-expert {
  width: 300px;
  height: 300px;
  border-radius: 15px;
  background-color: #D9D9D9;
}

.avatar-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.info-expert {
  color: #FFFFFF;
  margin-left: 40px;
  font-size: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.edit-expert {
  display: flex;
  text-align: center;
  font-size: 20px;
  justify-content: space-around;
}

.edit-link-expert {
  color: #FFFFFF;
  margin-top: 30px;
  cursor: pointer;
}

.flex-line {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .top-expert {
    flex-direction: column;
    align-items: center;
  }

  .edit-expert {
    font-size: 18px;
  }
}
</style>
