import { send } from '@/global/functions';

const state = {
    video: {
        id: null,
        name: '',
        description: '',
        url: '',
        visible: false,
        passed: {
            youtube: false,
            others: false,
        },
        code: '',
        src: '',
        list: [],
        isEdit: false,
    },
};

const getters = {
    video: state => state.video,
    videosList: state => state.video.list,
};

const mutations = {
    SET_VIDEOS_LIST(state, res) {
        state.video.list = res;
    },
    SET_VIDEO(state, res) {
        state.video = { ...state.video, ...res };
    },
};

const actions = {
    async getVideos({ commit }) {
        try {
            const res = await send({
                uri: 'video/main',
                method: 'GET',
                wait: true,
            });

            res.sort((a, b) => new Date(a.date_create) - new Date(b.date_create));

            commit('SET_VIDEOS_LIST', res);

            return res;
        } catch (e) {
            console.error(e);
        }
    },

    async videoUpload({
        commit,
        getters,
    }, req) {
        try {
            const res = await send({
                uri: 'video/create',
                payload: req,
                method: 'POST',
            });

            const result = getters.video.list;

            result.push(res);

            commit('SET_VIDEOS_LIST', result);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async videoUpdate({
        commit,
        getters,
    }, req) {
        try {
            const res = await send({
                uri: `video/detail/${req.params.video_id}/`,
                method: 'PUT',
                payload: req.data,
            });

            if (res) {
                const newVideosArray = getters.video.list.map(item => {
                    if (item.id === req.data.video_id) {
                        return {
                            ...item,
                            ...req.data,
                        };
                    }

                    return item;
                });

                commit('SET_VIDEOS_LIST', newVideosArray);
            }

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async videoDelete({
        commit,
        getters,
    }, req) {
        try {
            const res = await send({
                uri: `video/detail/${req.video_id}`,
                method: 'DELETE',
                resType: 'full',
            });

            if (res) {
                const newVideosArray = getters.video.list.filter(item => item.id !== req.video_id);
                commit('SET_VIDEOS_LIST', newVideosArray);
            }

            return res;
        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
