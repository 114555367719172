<template>
    <section class="champion-detail">
        <div class="container">
            <div>
                <div v-if="!isLoading" class="champion-detail__url">
                    <div class="champion-detail__url-item">
                        <router-link to="/championship">Чемпионат</router-link>
                    </div>
                    <div class="champion-detail__url-item">{{ currentChampionship.name }}</div>
                </div>

                <div
                    v-if="!isLoading && currentChampionship.status !== 'final' && currentChampionship.status !== 'not_started'"
                    class="champion-detail__title title">
                    <div class="champion-detail__title-text">{{ currentChampionship.name }}</div>
                    <div v-if="isChildLoaded">
                        <div v-if="!isUserParticipates" class="champion-detail__title-addbutton" @click="invitingToChampionship">
                            Участвовать
                        </div>
                        <div v-else class="champion-detail__title-addbutton" @click="declineChampionship">Отменить участие</div>
                    </div>
                </div>

                <div
                    v-if="!isLoading  && currentChampionship.status !== 'final'  && currentChampionship.status !== 'not_started'"
                    class="champion-detail__tabs">
                    <router-link
                        v-for="(item, itemIndex) in tabs"
                        :key="itemIndex"
                        :to="'/championship/' + championshipId + item.url"
                        style="margin-right: 30px"
                    >
                        <button
                            :class="{ active: itemIndex === activeTabItem }"
                            class="champion-detail__tabs-select"
                            @click="changeTab(itemIndex)"
                        >
                            {{ item.name }} <span>({{ itemIndex === 0 ? countMembers || 0 : countTeams || 0 }})</span>
                        </button>
                    </router-link>
                </div>
            </div>

            <router-view v-if="!isLoading" @invite="inviteUser" @loaded="isChildLoaded = true"/>

        </div>

        <transition name="fade">
            <ModalAddComand v-if="modals.championship.teams"/>
        </transition>

        <transition name="fade">
            <ModalInviteComand v-if="modals.championship.members" :invitedUser="invitedUser"/>
        </transition>

        <transition name="fade">
            <ModalUpdateCommand v-if="modals.championship.update_command" :team="currentTeam"/>
        </transition>

        <transition name="fade">
            <ModalUserInfo v-if="modals.championship.userinfo"/>
        </transition>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ModalAddComand from '@/components/modals/championship/ModalAddComand.vue';
import ModalInviteComand from '@/components/modals/championship/ModalInviteComand.vue';
import ModalUserInfo from '@/components/modals/championship/ModalUserInfo.vue';
import ModalUpdateCommand from '@/components/modals/championship/ModalUpdateCommand.vue';

export default {
    name: 'ChampionshipDetailsView',
    data() {
        return {
            tabs: [
                {
                    name: 'Участники',
                    url: '/members',
                    index: 0,
                },
                {
                    name: 'Команды',
                    url: '/teams',
                    index: 1,
                },
            ],
            activeTabItem: Number(localStorage.getItem('championshipTabIndex')) || 0,
            invitedUser: {},
            isLoading: true,
            isChildLoaded: false,
        };
    },
    components: {
        ModalInviteComand,
        ModalAddComand,
        ModalUserInfo,
        ModalUpdateCommand,
    },
    computed: {
        ...mapGetters({
            user: 'user/myInfo',
            currentChampionship: 'championship/currentChampionship',
            modals: 'settings/modals',
            currentTeam: 'championship/currentTeam',
            isUserParticipates: 'championship/isUserParticipates',
            teams: 'championship/championshipTeams',
            members: 'championship/championshipMembers',
        }),

        countMembers() {
            return this.currentChampionship?.count_members;
        },

        countTeams() {
            return this.currentChampionship?.count_commands;
        },

        championshipId() {
            return Number(this.$route.params.championshipId) ?? null;
        },
    },
    async created() {
        this.isLoading = true;

        await this.getChampionshipDetails({ id: this.championshipId });

        this.isLoading = false;
    },
    updated() {
        this.activeTabItem = Number(localStorage.getItem('championshipTabIndex')) ?? 0;
    },
    methods: {
        ...mapActions({
            getChampionshipTeamsDetails: 'championship/getChampionshipTeamsDetails',
            getChampionshipDetails: 'championship/getChampionshipDetails',
            invitingChampionship: 'championship/invitingChampionship',
            leaveChampionship: 'championship/leaveChampionship',
            getTeamMembersDetails: 'championship/getTeamMembersDetails',
        }),

        toggleModal(component) {
            this.modals[component] = !this.modals[component];
        },

        changeTab(index) {
            this.activeTabItem = index;

            localStorage.setItem('championshipTabIndex', index);
        },
        inviteUser(payload) {
            this.toggleModal(payload.modal);
            this.invitedUser = payload.user_id;
        },

        invitingToChampionship() {
            const params = {
                id_championship: this.championshipId,
                id_user: this.user.id,
            };

            const data = {
                id: this.user.id,
                login: this.user.username,
                second_name: this.user.last_name,
                city: this.user.profile_filed.city,
                img: this.user.profile_filed.img,
            };

            this.invitingChampionship({
                params,
                data,
            });
        },

        async declineChampionship() {
            const params = {
                id_championship: this.championshipId,
                id_user: this.user.id,
            };

            await this.leaveChampionship(params);

            await this.getChampionshipTeamsDetails({
                id_championship: this.championshipId,
                wait: !!this.currentChampionship.id,
            })
                .then(item => {
                    this.isLoadingTeams = false;
                    if (item.length) {
                        return this.getTeamMembersDetails({ teamId: this.teams[0].id });
                    }
                })
                .then(() => {
                    this.isLoadingMembers = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>
