<template>
    <div class="testing__item-hyperlink" @click="showAlertOrOpenTest(data)">
        <div
            :class="{ completed: data.pre_answer === 'true' }"
            class="testing__item"
        >

            <div class="testing__item-info">
                <div class="testing__item-title">
                    {{ data.name }}
                </div>
                <div class="testing__item-next">Пройти тестирование</div>
            </div>
        </div>
    </div>
</template>

<script>
// import { SpringSpinner } from 'epic-spinners';
import router from '@/router';

export default {
    name: 'TestingCard',
    // components: {
    //     SpringSpinner,
    // },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            img: {
                visible: false,
            },
        };
    },
    methods: {
        visibleImages() {
            this.img.visible = true;
        },
        showAlertOrOpenTest(data) {
            if (data.is_active !== true) {
                this.fnalert();
            } else {
                router.push('/testing/' + data.id);
            }
        },
        fnalert() {
            alert('Этот тест заблокирован! Вы не можете пройти этот тест.');
        },
    },
};
</script>
