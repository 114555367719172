<template>
    <div>
        <div
            :class="{ active: isActiveTab }"
            class="champion-detail__content-comand"
            @click="(event) => getTeamInfo(payload.id, index, event, payload.name, payload.main_user)">
            <div class="champion-detail__content-comand_title">{{ payload.name }}</div>
            <div class="champion-detail__content-comand_paticipant">
                Участников: <span>{{ payload.members_count || 1 }}</span>
                <button
                    v-if="canRequestMembership"
                    class="membership_request"
                    @click.stop="membershipRequest"
                >
                    Заявка на вступление
                </button>
                <button
                    v-if="payload.main_user === this.userId"
                    class="update-command"
                    @click="(event) => openUpdateCommandModal(event)">
                    &#9998;
                </button>
            </div>
            <div class="champion-detail__content-comand_about">{{ payload.description }}</div>
        </div>

        <transition name="fade">
            <ModalMembershipRequest v-if="modalVisible" :teamData="payload" @close="modalVisible = false"/>
        </transition>
    </div>
</template>

<script>
import ModalMembershipRequest from '@/components/modals/championship/ModalMembershipRequest.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ChampionshipCommandCard',
    components: {
        ModalMembershipRequest,
    },
    props: {
        payload: {
            type: Object,
            default: () => ({}),
        },
        isActiveTab: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
    },

    data: () => ({
        modalVisible: false,
    }),

    computed: {
        ...mapGetters({
            modals: 'settings/modals',
            userId: 'user/userId',
            user: 'user/myInfo',
            teams: 'championship/championshipTeams',
            currentChampionship: 'championship/currentChampionship',
            isUserParticipates: 'championship/isUserParticipates',
        }),
        canRequestMembership() {
            return !this.isLeader && !this.userInTeam && !this.isUserInvited && this.isUserParticipates;
        },
        isLeader() {
            let Leader = false;
            this.teams.forEach(item => {
                if (item.main_user === this.userId) {
                    Leader = true;
                }
            });
            return Leader;
        },
        userInTeam() {
            let isUserInTeam = false;
            this.teams.forEach(team => team.members.forEach(member => {
                if (member.id === this.userId) {
                    isUserInTeam = true;
                }
            }));
            return isUserInTeam;
        },
        isUserInvited() {
            return this.teams.some(
                teams => teams.invited_members.some(
                    member => member === this.userId
                )
            );
        },
    },
    methods: {
        ...mapActions({
            requestMembership: 'user/requestMembership',
            toggleModals: 'settings/toggleModals',
            loadCities: 'user/loadCities',
        }),
        getTeamInfo(id, index, event, name, leader) {
            event.preventDefault();
            event.stopPropagation();
            this.$emit('getInfo', {
                id,
                index,
                name,
                leader,
            });
        },
        membershipRequest() {
            this.modalVisible = true;
        },
        openUpdateCommandModal(event) {
            event.stopPropagation();
            event.preventDefault();
            this.loadCities();
            this.toggleModals({
                parent: 'championship',
                children: 'update_command',
            });
        },
    },
};
</script>

<style>
.membership_request {
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  border: 0;
  background-color: inherit;
  padding: 0;
  margin: 0;
  font-weight: 500;
  position: relative;
  top: 50%;
  left: 55%;
  font-size: 14px;
  line-height: 16px;
  color: black;
  transform: translateY(-50%);
}

.update-command {
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  border: 0;
  background-color: inherit;
  padding: 0;
  margin: 0;
  font-weight: 500;
  position: relative;
  top: 5%;
  left: 75%;
  font-size: 20px;
  line-height: 16px;
  color: black;
  transform: translateY(-50%);
}
</style>
