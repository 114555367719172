<template>
    <div class="modal-inner">
        <button class="modal-inner__close" @click="toggleModal">
            <img alt="" src="@/assets/images/close-icon.svg"/>
        </button>
        <div class="container">
            <div class="modal-inner__title">Информация о пользователе</div>
            <div v-if="!isModalLoaded" class="profile__wrapper-item--loading">
                <div class="profile__wrapper-item--loading-container">
                    <SkeletonCard
                        borderRadius="10px"
                        primary="#e3e3e3"
                        structure="7001324032"
                    />
                </div>
                <div class="profile__wrapper-item--loading-container grid">
                    <SkeletonCard
                        v-for="item in 2"
                        :key="item"
                        primary="#e3e3e3"
                        structure="700132"
                    />
                </div>
            </div>
            <div v-else class="profile__content-item">
                <div class="profile__wrapper">
                    <div class="profile__wrapper-avatar">
                        <div class="profile__wrapper-avatar_img modal">
                            <img :src="userInfo.profile_filed.img"/>
                        </div>
                    </div>
                    <div class="profile__wrapper-date">
                        <div class="profile__wrapper-date_left">
                            <div class="profile__wrapper-date_name subtitle">
                                {{ userInfo.profile_filed.second_name }}
                            </div>

                            <div class="profile__wrapper-date_username">
                                {{ userInfo.username }}
                            </div>
                            <div class="profile__wrapper-date_account">
                                <div class="profile__wrapper-date_row">
                                    <div class="profile__wrapper-date_text">
                                        Электронная почта:
                                    </div>
                                    <div class="profile__wrapper-date_value">
                                        {{ userInfo.email }}
                                    </div>
                                </div>
                                <div class="profile__wrapper-date_row">
                                    <div class="profile__wrapper-date_text">Дата рождения:</div>
                                    <div class="profile__wrapper-date_value">
                                        {{ preparedBornDate(userInfo.profile_filed.born) }}
                                    </div>
                                </div>
                                <div class="profile__wrapper-date_row">
                                    <div class="profile__wrapper-date_text">Пол:</div>
                                    <div class="profile__wrapper-date_value">
                                        {{
                                            userInfo.profile_filed.gender === "male"
                                                ? "Мужской"
                                                : userInfo.profile_filed.gender === "female"
                                                    ? "Женский"
                                                    : ""
                                        }}
                                    </div>
                                </div>
                                <div class="profile__wrapper-date_row">
                                    <div class="profile__wrapper-date_text">Город:</div>
                                    <div class="profile__wrapper-date_value">
                                        {{ preparedCity }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="profile__rating">
                    <div class="profile__rating-title subtitle">Личный рейтинг</div>
                    <div v-if="preparedAchievements.length" class="profile__rating-wrapper modal">
                        <ProfileRatingCard
                            v-for="(item, itemIndex) in preparedAchievements"
                            :key="itemIndex"
                            :payload="item"
                        />
                    </div>
                    <div v-else class="profile__rating-wrapper">пусто</div>
                </div>

                <div class="profile__skills">
                    <div class="profile__skills-title subtitle">Hard Skills</div>
                    <div v-if="preparedHardSkills.length" class="profile__skills-wrapper">
                        <div class="profile__skills-block">
                            <ProfileSkillsCard
                                v-for="(item, itemIndex) in preparedHardSkills"
                                :key="itemIndex"
                                :payload="item"
                            />
                        </div>
                    </div>
                    <div v-else class="profile__skills-wrapper">пусто</div>
                </div>

                <div class="profile__skills">
                    <div class="profile__skills-title subtitle">Soft Skills</div>
                    <div v-if="preparedGroupSoftSkills.length" class="profile__skills-wrapper">
                        <div class="profile__skills-block">
                            <ProfileSkillsCard
                                v-for="(item, itemIndex) in preparedGroupSoftSkills"
                                :key="itemIndex"
                                :payload="item"
                            />
                        </div>
                    </div>
                    <div v-else class="profile__skills-wrapper">пусто</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { convertedDate } from '@/global/functions';
import { mapGetters, mapMutations, mapActions } from 'vuex';

import ProfileRatingCard from '@/components/profile/ProfileRatingCard.vue';
import ProfileSkillsCard from '@/components/profile/ProfileSkillsCard.vue';

export default {
    name: 'ModalUserInfo',
    components: {
        ProfileRatingCard,
        ProfileSkillsCard,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            userInfo: 'user/userInfo',
            userAchievements: 'user/userAchievements',
            isModalLoaded: 'settings/isModalLoaded',
        }),

        preparedCity() {
            return this.userInfo.profile_filed?.city?.id
                ? this.userInfo.profile_filed?.city?.name
                : this.userInfo.profile_filed?.city;
        },

        preparedHardSkills() {
            return this.userInfo.Hard_skill_field.map(item => ({
                ...item,
                type: 'hardSkill',
            }));
        },

        preparedGroupSoftSkills() {
            return this.userInfo.Group_Soft_skill_field.map(item => ({
                ...item,
                type: 'softSkill',
            }));
        },

        preparedAchievements() {
            return Object.values(this.userAchievements);
        },
    },
    methods: {
        ...mapMutations({
            CONTENT_IS_LOADED: 'settings/CONTENT_IS_LOADED',
        }),

        ...mapActions({
            toggleModals: 'settings/toggleModals',
        }),

        toggleModal() {
            this.toggleModals({
                parent: 'championship',
                children: 'userinfo',
            });

            this.CONTENT_IS_LOADED(false);
        },

        preparedBornDate(date) {
            return convertedDate(date);
        },
    },
};
</script>
