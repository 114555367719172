<template>
    <div class="profile__content-item">
        <div v-if="isLoading" class="profile__videos">
            <SkeletonCard
                v-for="item in 6"
                :key="item"
                primary="#e3e3e3"
                structure="700132"/>
        </div>
        <div v-else class="profile__videos">
            <ProfileVideoCard
                v-for="(item, itemIndex) in preparedVideos"
                :key="itemIndex"
                :isEdit="video.isEdit"
                :payload="item"
            />
            <div class="profile__videos-item add-video" @click="toggleModal('video')">
                <div class="profile__videos-item_addtext">Загрузить видео</div>
            </div>
        </div>
    </div>
</template>

<script>
import { REGEX_CODE_YT } from '@/global/constants';
import { mapGetters, mapActions } from 'vuex';
import ProfileVideoCard from '@/components/profile/ProfileVideoCard';

export default {
    name: 'ProfileVideoView',
    components: {
        ProfileVideoCard,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/myInfo',
            video: 'user/video',
        }),

        preparedVideos() {
            const filteredVideos = this.video.list.map(item => {
                const url = item.url.match(REGEX_CODE_YT) ?? [];
                return {
                    ...item,
                    url: url[1] || null,
                };
            });
            return filteredVideos ?? [];
        },
    },
    mounted() {
        this.loadVideos();
    },
    methods: {
        ...mapActions({
            getUserVideos: 'user/getUserVideos',
            toggleModals: 'settings/toggleModals',
        }),

        async loadVideos() {
            this.isLoading = true;

            const res = await this.getUserVideos({ id_user: this.user.id });

            if (res) {
                this.isLoading = false;
            }
        },

        toggleModal(children) {
            this.toggleModals({
                parent: 'profile',
                children,
            });
        },
    },
};
</script>

<style>

</style>
