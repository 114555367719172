import { send } from '@/global/functions';

const state = {
    gamesList: [],
};

const getters = {
    gamesList: state => state.gamesList,
};

const mutations = {
    SET_GAMES_LIST(state, res) {
        state.gamesList = res;
    },
};

const actions = {
    async getGamesList({ commit }, req) {
        try {
            const res = await send({
                uri: 'game/all',
                method: 'GET',
                wait: true,
            });

            commit('SET_GAMES_LIST', res);

            return res;
        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
