export default function auth({
    next,
    store,
    to,
}) {
    if (!localStorage.getItem('token-access')) {
        return next({
            path: '/login',
        });
    }

    if (store.getters['user/myInfo']?.profile_filed?.role) {
        // Now that user data is guaranteed to be loaded, proceed with role check
        const userRole = store.getters['user/myInfo'].profile_filed.role;
        const allowedForRole3 = ['/expert']; // Include all guest paths and /expert paths here

        if (userRole === 3 && !allowedForRole3.some(path => to.path.startsWith(path))) {
            return next({
                path: '/expert',
            });
        } else if (userRole !== 3 && allowedForRole3.some(path => to.path.startsWith(path))) {
            return next({
                path: '/profile',
            });
        }
    }

    return next();
}
