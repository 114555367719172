/* eslint no-useless-escape: 0 */
/* eslint no-irregular-whitespace: 0 */

const REGEX_EMAIL = /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/;
const REGEX_PASSWORD = /[A-z0-9~!@#\$%\^&\*\(\)_\+\\\[\];\{\}:\/\?\.,><-=\|]{8,64}$/;
const REGEX_FULL_YT = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
const REGEX_CODE_YT = /([a-zA-Z0-9_-]{11})+/;

const REGEX_VIDEO_URL = /src="(.*?)"/;

export { REGEX_EMAIL, REGEX_PASSWORD, REGEX_FULL_YT, REGEX_CODE_YT, REGEX_VIDEO_URL };
