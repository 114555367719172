<template>
    <section class="games">
        <div class="container">
            <div class="games__title title">Игры</div>
            <div v-if="isLoading" class="games__wrapper">
                <SkeletonCard
                    v-for="item in 8"
                    :key="item"
                    primary="#e3e3e3"
                    structure="700132"/>
            </div>
            <div v-else class="games__wrapper">
                <GameCard v-for="(item, itemIndex) in gamesList" :key="itemIndex" :payload="item"/>
            </div>
        </div>
    </section>
</template>

<script>
import GameCard from '../components/games/GameCard.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'GamesView',
    components: {
        GameCard,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            gamesList: 'games/gamesList',
        }),
    },
    created() {
        this.requestData();
    },
    methods: {
        ...mapActions({
            getGamesList: 'games/getGamesList',
        }),

        async requestData() {
            this.isLoading = true;

            const res = await this.getGamesList();

            if (res) {
                this.isLoading = false;
            }
        },
    },
};
</script>
