<script>
import ChampResultBlockComponent from '@/components/profile/ChampResultBlockComponent.vue';
import {
    mapGetters
} from 'vuex';

export default ({
    components: {
        ChampResultBlockComponent,
    },
    data() {
        return {
            isLoadingChamp: false,
        };
    },
    computed: {
        ...mapGetters({
            champResult: 'user/championshipResults',
        }),
    },
    mounted() {
        this.loadChamps();
    },
    methods: {
        async loadChamps() {
            this.isLoadingChamp = true;

            if (this.champResult !== null) {
                this.isLoadingChamp = false;
            }
        },
    },
});
</script>

<template>
    <div>
        <div v-if="isLoadingChamp" class="profile__wrapper-item--loading">
            <div class="profile__wrapper-item--loading-container grid">
                <SkeletonCard
                    v-for="item in 6"
                    :key="item"
                    primary="#e3e3e3"
                    structure="700132"/>
            </div>
        </div>
        <div v-else>
            <p class="view-title">Результаты чемпионатов</p>
            <div class="result-section">
                <ChampResultBlockComponent v-for="(champResult, i) in champResult" :key="i" :data="champResult">
                </ChampResultBlockComponent>
            </div>
        </div>
    </div>
</template>

<style scoped>
.result-section {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 45px;
}

.view-title {
  margin-top: 20px;
  font-size: 17px;
  margin-bottom: -20px;
}

@media (max-width: 1100px) {
  .view-title {
    text-align: center;
  }

  .result-section {
    justify-content: center;
  }
}
</style>
