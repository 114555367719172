<template>
    <div class="auth auth-recovery">
        <div class="container">
            <div class="auth__logo">
                <img alt="" src="@/assets/images/logo-2.png"/>
            </div>
            <div class="auth__text">
                На ваш адрес электронной почты будет отправлена инструкция для сброса и
                установки нового пароля.
            </div>
            <form action="#" class="auth__form" @submit.prevent="handleSubmit">
                <div
                    :class="{
                        'auth__form-input_error':
                            $v.email.$error || this.resetpassErrors.email.length > 0,
                    }"
                    class="auth__form-input"
                >
                    <input
                        v-model="email"
                        placeholder="Электронная почта"
                        type="email"
                        @input="resetpassErrors.email = []"
                    />
                    <div class="auth__form-error">
                        <div v-for="error in resetpassErrors.email" :key="error.index">
                            {{ error }}
                        </div>
                    </div>
                </div>
                <div v-if="success" class="auth__form-success">
                    <span>Иструкции по смене пароля отправлены на указанную почту.</span
                    ><br/>
                </div>
                <div :class="{ success: success }" class="auth__form-submit">
                    <input class="btn" type="submit" value="Отправить"/>
                </div>
            </form>
            <div class="auth__form-register">
                <router-link to="/login">Вернуться к авторизации</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ResetAccountDetailsView',
    data() {
        return {
            email: '',
            success: false,
        };
    },
    validations: {
        email: {
            required,
            email,
            minLength: minLength(4),
        },
    },
    computed: {
        ...mapGetters({
            resetpassErrors: 'settings/resetpassErrors',
        }),
    },
    methods: {
        ...mapActions({
            forgotPassword: 'auth/forgotPassword',
        }),

        async handleSubmit() {
            this.$v.$touch();

            if (!this.$v.$invalid && this.email) {
                const res = await this.forgotPassword({
                    email: this.email,
                });

                if (res === true) this.success = true;
            }
        },
    },
};
</script>
