import { send } from '@/global/functions';

export const state = {
    cropper: {
        image: '',
        visible: false,
        ratio: 1,
        file: '',
        currentCropFile: null,
        currentPreviewFile: null,
        imagesPreviews: {
            userinfo: '',
        },
        imagesBlobs: {
            userinfo: '',
        },
    },
    errors: {
        auth: {
            loginPassword: [],
        },
        register: {
            email: [],
            password: [],
            login: [],
        },
        changePassword: {
            password: [],
        },
        resetPassword: {
            password: [],
            email: [],
        },
    },
    videoSettings: {
        name: '',
        descrip: '',
        file: null,
    },
    modals: {
        profile: {
            video: false,
            password: false,
            games: false,
            data: false,
        },
        championship: {
            members: false,
            teams: false,
            userinfo: false,
            update_command: false,
            membership_request: false,
        },
        videos: {
            video: false,
        },
    },
    isModalLoaded: false,
};

export const getters = {
    cropper: state => state.cropper,
    videoSettings: state => state.videoSettings,
    authErrors: state => state.errors.auth,
    registerErrors: state => state.errors.register,
    changepassErrors: state => state.errors.changePassword,
    resetpassErrors: state => state.errors.resetPassword,
    modals: state => state.modals,
    isModalLoaded: state => state.isModalLoaded,
};

export const mutations = {
    async CHANGE_CROPPER_IMAGE(state, event) {
        const input = event.target;

        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = function(e) {
                state.cropper.image = e.target.result;
                state.cropper.ratio = 1;
            };

            reader.readAsDataURL(input.files[0]);

            state.cropper.visible = true;
        }
    },

    UPLOAD_VIDEO(state, event) {
        const file = event.target.files[0];
        if (file) {
            state.videoSettings.file = file;
        }
    },

    SAVE_PREVIEW(state) {
        state.cropper.canvas.toBlob(blob => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            state.cropper.imagesBlobs.userinfo = blob;

            reader.onload = () => {
                state.cropper.imagesPreviews.userinfo = reader.result;
            };
        }, 'image/png');
    },

    UPDATE_USER_IMAGE(state) {
        const input = document.getElementById('imageUserInfo');
        input.value = null;
        state.cropper.image = '';
        state.cropper.visible = false;
    },

    UPDATE_REGISTER_ERRORS(state, payload) {
        payload.res.forEach(error => {
            state.errors.register[payload.type].push(error);
        });
    },

    UPDATE_AUTH_ERRORS(state, payload) {
        payload.res.forEach(error => {
            state.errors.auth[payload.type].push(error);
        });
    },

    UPDATE_CHANGEPASS_ERRORS(state, payload) {
        payload.res.forEach(error => {
            state.errors.changePassword[payload.type].push(error);
        });
    },

    UPDATE_RESETPASS_ERRORS(state, payload) {
        payload.res.forEach(error => {
            state.errors.resetPassword[payload.type].push(error);
        });
    },

    CLEAR_ERRORS(state) {
        state.errors.register = {
            login: [],
            password: [],
            email: [],
        };

        state.errors.auth = {
            loginPassword: [],
        };

        state.errors.changePassword = {
            password: [],
        };

        state.errors.resetPassword = {
            password: [],
            email: [],
        };
    },

    TOGGLE_MODALS(state, res) {
        state.modals[res.parent][res.children] = !state.modals[res.parent][res.children];
    },

    CONTENT_IS_LOADED(state, res) {
        state.isModalLoaded = res;
    },
};

export const actions = {
    async CHANGE_USER_IMAGE({ commit }, req) {
        try {
            const formData = new FormData();
            formData.append('id_user', req.id);
            formData.append('file', req.image);
            console.log(formData);

            const res = await send({
                uri: 'imageUpload',
                payload: formData,
                method: 'POST',
                resType: 'full',
                rawPayload: true,
                headers: {
                    Accept: 'application/json',
                },
            });

            if (res.status === 200) {
                commit('UPDATE_USER_IMAGE');
            } else {
                alert('Ой, что-то пошло не так.');
            }
        } catch (e) {
            console.log(e);
        }
    },

    async userVideoUpload({ commit }, req) {
        try {
            const res = await send({
                uri: 'profile/video/upload',
                payload: req,
                method: 'POST',
            });

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    toggleModals({ commit }, req) {
        commit('TOGGLE_MODALS', req);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
