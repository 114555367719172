<script>
export default ({
    props: {
        score: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
});
</script>

<template>
    <div class="neural-general-block">
        <div class="neural-title">
            <slot>Аналитическое мышление</slot>
        </div>
        <div class="neural-mark">
            <div class="mark-title">
                <p>Оценка</p>
            </div>
            <div class="progress-neural">
                <p>1</p>
                <div :class="{'circle-neural-solid': score.score_2 == 1}" class="circle-neural">
                </div>
                <p>2</p>
                <div :class="{'circle-neural-solid': score.score_3 == 1}" class="circle-neural">
                </div>
                <p>3</p>
                <div :class="{'circle-neural-solid': score.score_4 == 1}" class="circle-neural">
                </div>
                <p>4</p>
                <div :class="{'circle-neural-solid': score.score_5 == 1}" class="circle-neural">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.neural-general-block {
  width: 450px;
  border-radius: 5px;
  justify-content: flex-start;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: #204B6F;
  align-items: center;
  color: #FFFFFF;
}

.neural-title {
  margin-top: 20px;
  margin-left: 30px;
  font-size: 15px;
}

.neural-mark {
  display: flex;
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 20px;
  align-items: center;
}

.neumark p {
  margin-right: 7px;
  margin-left: 7px;
}

.mark-title {
  margin-right: 10px;
  font-size: 13px;
}

.progress-neural {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-neural p {
  margin-right: 8px;
  margin-left: 5px;
  font-size: 13px;
}

.circle-neural {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #D9D9D9;
}

.circle-neural-solid {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #FF7700;
}

@media (max-width: 700px) {
  .neural-general-block {
    width: 85%;
  }
}
</style>
