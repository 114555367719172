<template>
    <div class="auth">
        <div class="container">
            <div class="auth__logo">
                <img alt="" src="@/assets/images/logo-2.png"/>
            </div>
            <form action="#" class="auth__form" @submit.prevent="handleSubmit">
                <div
                    :class="{
                        'auth__form-input_error':
                            $v.email.$error || authErrors.loginPassword.length > 0,
                    }"
                    class="auth__form-input"
                >
                    <input v-model.trim="email" placeholder="Логин" type="text"/>
                </div>
                <div
                    :class="{
                        'auth__form-input_error':
                            $v.password.$error || authErrors.loginPassword.length > 0,
                    }"
                    class="auth__form-input"
                >
                    <input v-model.trim="password" placeholder="Пароль" type="password"/>
                    <div class="auth__form-error">
                        <div v-for="error in authErrors.loginPassword" :key="error.index">
                            {{ error }}
                        </div>
                    </div>
                </div>
                <div class="auth__form-secondary">
                    <div class="auth__form-checkbox">
                        <input id="checkbox" v-model="isCheckbox" type="checkbox"/>
                        <label for="checkbox">Запомнить меня</label>
                    </div>
                    <router-link to="/reset">Забыли пароль?</router-link>
                </div>
                <div class="auth__form-submit">
                    <input class="btn" type="submit" value="Авторизоваться"/>
                </div>
            </form>
            <div class="auth__form-register">
                <span>У вас еще нет аккаута?</span>
                <router-link to="/register">Зарегистрироваться</router-link>
            </div>
            <div class="auth__form-social">
                <div class="auth__form-social--block">
                    <div @click="loginVk">
                        <img alt="" src="@/assets/images/vk-icon.svg"/>
                    </div>
                </div>
                <div id="vk_api_transport"></div>
                <div class="auth__form-social--block">
                    <div id="signinDiv"></div>
                    <img
                        alt=""
                        src="@/assets/images/google-icon.svg"
                        @click="createUser"
                    />
                </div>
                <div class="auth__form-social--block">
                    <div class="auth__form-social_link" @click="loginOk">
                        <img alt="" src="@/assets/images/class-icon.svg"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

const clienTId =
    '494542373486-q74iugv0o24r7b608ei8ll2223el6i0p.apps.googleusercontent.com';

export default {
    name: 'AuthView',
    data() {
        return {
            email: localStorage.getItem('name') || '',
            password: '',
            isCheckbox: false,
            user: {},
            info: null,
            googleSignInParams: {
                client_id:
            '494542373486-q74iugv0o24r7b608ei8ll2223el6i0p.apps.googleusercontent.com',
                fetch_basic_profile: false,
                scope: 'profile email openid',
            },
        };
    },
    mounted() {
        const googleScript = document.createElement('script');
        googleScript.src = 'https://accounts.google.com/gsi/client';
        const vkScript = document.createElement('script');
        vkScript.type = 'text/javascript';
        vkScript.async = true;
        vkScript.src = 'https://vk.com/js/api/openapi.js?169';
        document.head.appendChild(googleScript);
        document.head.appendChild(vkScript);
        document.getElementById('signinDiv').hidden = true;

        window.addEventListener('load', () => {
            window.google.accounts.id.initialize({
                client_id: clienTId,
                callback: this.handleCredentialResponse,
            });

            window.google.accounts.id.renderButton(
                document.getElementById('signinDiv'),
                {
                    theme: 'outline',
                    type: 'icon',
                    shape: 'circle',
                    size: 'large',
                }
            );
            this.user = window.google.accounts.oauth2.initTokenClient({
                client_id: clienTId,
                scope: 'profile email',
                callback: tokenResponse => {
                    if (tokenResponse && tokenResponse.access_token) {
                        this.onSignInGoogle(tokenResponse.access_token);
                    }
                },
            });
        });
    },
    computed: {
        ...mapGetters({
            authErrors: 'settings/authErrors',
        }),
    },
    methods: {
        ...mapActions({
            authorization: 'auth/authorization',
            authorizationGoogle: 'auth/authorizationGoogle',
            authorizationVk: 'auth/authorizationVk',
        }),
        createUser() {
            this.user && this.user.requestAccessToken();
        },
        async handleSubmit() {
            try {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    await this.authorization({
                        email: this.email,
                        password: this.password,
                        saveUser: this.isCheckbox,
                    });
                }
            } catch (err) {
                alert(err);
            }
        },
        async decodeJwtResponse(token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+')
                .replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(c => {
                        return '%' + ('00' + c.charCodeAt(0)
                            .toString(16)).slice(-2);
                        // eslint-disable-next-line newline-per-chained-call
                    })
                    .join('')
            );
            return JSON.parse(jsonPayload);
        },
        async loginVk() {
            const host = 'https://api.businessup.online';
            const newWindow = window.open(`${host}/auth/vk?env=prod`, '_blank');

            const intervalId = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(intervalId);
                    window.location.reload();
                }
            }, 1000);

            setTimeout(() => {
                clearInterval(intervalId);
            }, 120000);
        },
        async loginOk() {
            const host = 'https://api.businessup.online';
            const newWindow = window.open(`${host}/auth/ok?env=prod`, '_blank');

            const intervalId = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(intervalId);
                    window.location.reload();
                }
            }, 1000);

            setTimeout(() => {
                clearInterval(intervalId);
            }, 120000);
        },

        handleCredentialResponse(response) {
            document.getElementById('signinDiv').hidden = true;
        },
        async onSignInGoogle(access_token) {
            if (access_token) {
                await this.authorizationGoogle({
                    access_token,
                    code: '',
                });
            }
        },

        onSignInGoogleError(error) {
            console.log('error', error);
        },

        async onSignInVK(payload) {
            this.authorizationVk(payload);
        },

        async onSignInFacebook() {
        },

        async onSignInOK() {
        },
    },
    validations: {
        email: {
            required,
            minLength: minLength(1),
        },
        password: {
            required,
            minLength: minLength(8),
        },
    },
};
</script>

<style></style>
