<template>
    <div class="status-bar">
        <p class="desc-status">
            <slot>Наименование</slot>
        </p>
        <div class="right-status">
            <div class="status-level">
                <p :class="{ 'red': indicator < maxTotal }">{{ indicator }}/{{ maxTotal }}</p>
            </div>
            <div class="event-file">
                <img alt="" src="@/assets/images/file-event.png">
            </div>
            <div class="add-btn">
                <button @click="$emit('click')">+</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        showIndex: false,
    }),
    props: {
        member: {
            type: Object,
            default: () => ({}),
        },
        indicator: {
            type: Number,
            default: 0,
        },
        maxTotal: {
            type: Number,
            default: 10,
        },
    },
};
</script>

<style scoped>
.status-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  width: 700px;
  margin-top: 10px;
  height: 50px;
  border-radius: 15px;
  background: #0D2144B2;
  border: 2px solid #D9D9D980;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.desc-status {
  color: #fff !important;
}

.status-bar p {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #16AB09;
}

.right-status {
  gap: 20px;
  display: flex;
  align-items: center;
}

.add-btn button {
  font-size: 28px;
  font-weight: lighter;
  color: #fff;
}

.event-file img {
  width: 85%;
}

p.red {
  color: #FE0F00 !important;
}
</style>
