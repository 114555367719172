<template>
    <div class="container">
        <div v-click-outside="hideUserMenu" class="header__right">
            <button class="filter-image" @click="isOpenSubmenu = !isOpenSubmenu">
                <img
                    alt=""
                    src="@/assets/images/common/filter.svg"
                />
            </button>
            <ul v-show="isOpenSubmenu" class="filter-submenu">
                <li><input v-model="from" placeholder="С" type="number"></li>
                <li><input v-model="to" placeholder="ДО" type="number"></li>
            </ul>
        </div>
        <div v-if="loading" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div v-else class="modal-detail-champion__wrapper">
            <div class="result-cards__wrapper">
                <div
                    v-for="item in top_6_list_for_uk"
                    :key="item.id"
                    class="modal-detail-champion__wrapper-item"
                    style="padding: 0 0 20px 0">
                    <div class="champion-detail__content-participants">
                        <ChampionshipResult
                            :championshipTeamsList="championshipTeams"
                            :participatedChampionshipTeams="participatedChampionshipTeams"
                            :payload="item"
                        />
                    </div>
                </div>
            </div>
            <div class="button-wrapper">
                <button class="show-all" v-on:click="handleClick">Полный список рейтинга...</button>
            </div>
            <div v-if="showAll" class="table-container">
                <h3 style="color: black; font-size: 20px; font-weight: 500; line-height: 32px;">Результаты чемпионата: {{
                    currentChampionship.name }}</h3>
                <table class="data-table">
                    <tr class="header-row">
                        <th>Название команды</th>
                        <th>Место</th>
                        <th>Прибыль</th>
                        <th>Чистая прибыль</th>
                    </tr>
                    <tr v-for="item in getTop10Teams" :key="item.id" class="data-row">
                        <td v-if="item.teamId === myTeam?.id" style="color: #ff7f00">{{item.team_name}}</td>
                        <td v-else>{{item.team_name}}</td>
                        <td>{{item.place}}</td>
                        <td>{{item.revenue}}</td>
                        <td>{{item.netProfit}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChampionshipResult from '@/components/championship/ChampionshipResultCard.vue';

export default {
    components: {
        ChampionshipResult,
    },
    name: 'ChampionshipResultsView',
    data() {
        return {
            isOpenSubmenu: false,
            from: 1,
            to: 5,
            isLoadingMembers: false,
            awaiting: false,
            processing: false,
            final: false,
            championshipTeams: [],
            teams: [],
            loading: true,
            showAll: false,
        };
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
            user: 'user/myInfo',
            championshipMembers: 'championship/championshipMembers',
            currentChampionship: 'championship/currentChampionship',
        }),

        participatedChampionshipTeams() {
            return this.championshipTeams.filter(team => team.members.some(member => member.id === this.userId))
                .map(obj => obj.id);
        },
        preparedMembersList() {
            return this.championshipMembers;
        },

        championshipId() {
            return this.currentChampionship.id ?? null;
        },
        myTeam() {
            return this.championshipTeams.find(element => element.members.find(member => member.id === this.userId));
        },
        getTop10Teams() {
            const data = [...this.currentChampionship.top_6_list_for_uk];
            data.sort((a, b) => {
                return (a.place > b.place) ? 1 : -1;
            });
            const firstTenObjects = data.slice(0, 10);
            if (this.myTeam) {
                const existingObject = firstTenObjects.find(obj => obj.teamId === this.myTeam?.id);
                if (!existingObject) {
                    const resultsOfMyTeam = this.currentChampionship.top_6_list_for_uk.find(obj => obj.teamId === this.myTeam?.id);
                    if (resultsOfMyTeam) {
                        firstTenObjects.push(resultsOfMyTeam);
                    }
                }
            }
            return firstTenObjects;
        },

        top_6_list_for_uk() {
            if (!!this.from && !!this.to) {
                return this.currentChampionship.top_6_list_for_uk.filter(result => result.place >= this.from && result.place <= this.to);
            } else if (!this.from && !this.to) {
                return this.currentChampionship.top_6_list_for_uk;
            } else if (!this.from) {
                return this.currentChampionship.top_6_list_for_uk.filter(result => result.place <= this.to);
            } else if (!this.to) {
                return this.currentChampionship.top_6_list_for_uk.filter(result => result.place >= this.from);
            }
            return this.currentChampionship.top_6_list_for_uk;
        },
    },
    watch: {
        isLoadingMembers() {
            if (!this.isLoadingMembers) {
                this.$emit('loaded');
            }
        },
        $route() {
            this.isOpenSubmenu = false;
        },
    },
    async created() {
        const last = this.$route.path.split('/')
            .pop();

        if (last === 'members') {
            this.final = true;
        }

        localStorage.setItem('championshipTabIndex', 0);
        await this.championshipDetails();
        await this.getInfoAboutTeam();

        await this.getInviteToChampionship({
            id_user: this.userId,
            id_championship: this.championshipId,
        });
        await this.getUserHasCommand({
            id_user: this.userId,
            id_championship: this.championshipId,
        });
        this.loadData();
    },
    methods: {
        ...mapActions({
            getChampionshipDetails: 'championship/getChampionshipDetails',
            getChampionshipMembersDetails: 'championship/getChampionshipMembersDetails',
            getChampionshipTeamsDetails: 'championship/getChampionshipTeamsDetails',
            getInviteToChampionship: 'championship/getInviteToChampionship',
            getUserHasCommand: 'championship/getUserHasCommand',
        }),
        async handleClick() {
            this.showAll = this.showAll === false;
        },

        async championshipDetails() {
            this.isLoadingMembers = true;

            const second_res = await this.getChampionshipMembersDetails({
                id_championship: this.championshipId,
                wait: !!this.currentChampionship.id,
            });

            if (second_res) {
                this.isLoadingMembers = false;
            }
        },
        async getInfoAboutTeam() {
            this.championshipTeams = await this.getChampionshipTeamsDetails({
                id_championship: this.championshipId,
                wait: !!this.championshipId,
            });
        },
        hideUserMenu() {
            this.isOpenSubmenu = false;
        },
        loadData() {
            this.loading = false;
        },
    },
};
</script>

<style>
.filter-image {
  position: absolute;
  right: 0;
  height: 50px;
  width: 50px;
  border: 1px solid black;
  border-radius: 45px;
  background-color: #1d3e51;
}

.filter-submenu {
  background: #ffffff;
  border-radius: 8px;
  width: 150px;
  padding: 12px;
  position: absolute;
  top: 128%;
  right: 0;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: -30px;
  margin-right: 60px;
}

.filter-image img {
  height: 50%;
  width: 50%;
}

.filter-submenu input {
  border: 1px solid black;
  width: 50px;
  height: 30px;
  margin: 5px;
  text-align: center;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.show-all {
  border: 1px solid black;
  height: 40px;
  width: 250px;
  border-radius: 10px;
  background: #1d3e51;
  color: white;
}

.result-cards__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.table-container {
  text-align: center;
}

.data-table {
  border: solid white 1px;
  width: 100%;
  margin-bottom: 20px;
  border-collapse: separate;
  border-radius: 15px;
  overflow: hidden;
  color: white;
}

.data-table th, .data-table td {
  border-left: solid white 2px;
  border-top: solid white 2px;
  /*height: 30px;*/
}

.data-table .header-row th {
  background-color: #ff7f00;
  font-weight: bold;
  position: relative;
  border-top: none;
}

.header-row {
  border-radius: 20px 20px 0 0;
  height: 60px;
}

.data-table td {
  background-color: #1d3e51;
}

.data-table td:first-child, .data-table th:first-child {
  border-left: none;
}

.data-row {
  height: 45px;
}

/*LOADER STYLES*/

lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 30vh;
  left: 46%;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ff7f00;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff7f00 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
