import { render, staticRenderFns } from "./TestResultView.vue?vue&type=template&id=178a46fc&scoped=true&"
import script from "./TestResultView.vue?vue&type=script&lang=js&"
export * from "./TestResultView.vue?vue&type=script&lang=js&"
import style0 from "./TestResultView.vue?vue&type=style&index=0&id=178a46fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "178a46fc",
  null
  
)

export default component.exports