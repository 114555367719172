<template>
    <section class="champions">
        <div class="container">
            <div class="champions__title title">Чемпионаты</div>
            <div class="videos__search">
                <div class="videos__search-ico">
                    <img src="@/assets/images/common/search.svg">
                </div>
                <div class="videos__search-input">
                    <input v-model="search" placeholder="Поиск чемпионата по названию" type="text">
                </div>
            </div>
            <div v-if="isLoading" class="champions__wrapper">
                <SkeletonCard
                    v-for="item in 8"
                    :key="item"
                    primary="#e3e3e3"
                    structure="700132"/>
            </div>
            <div v-if="filteredChampInProcess.length > 0" class="champions__wrapper">
                <h2>Идут</h2>
                <div class="wrapper__item-block">
                    <ChampionshipCard
                        v-for="(item) in filteredChampInProcess"
                        :key="item.id"
                        :checkParticipate="true"
                        :payload="item"
                    />
                </div>
            </div>
            <br>
            <div v-if="filteredChampStart.length > 0" class="champions__wrapper">
                <h2>Подготовка к чемпионату. Найди свою команду!</h2>
                <div class="wrapper__item-block">
                    <ChampionshipCard v-for="(item) in filteredChampStart" :key="item.id" :payload="item"/>
                </div>
            </div>
            <br>
            <div v-if="filteredChampNotStart.length > 0" class="champions__wrapper">
                <h2>Планируется к старту</h2>
                <div class="wrapper__item-block">
                    <ChampionshipCard v-for="(item) in filteredChampNotStart" :key="item.id" :payload="item"/>
                </div>
            </div>
            <div v-if="filteredChampFinal.length > 0" class="champions__wrapper">
                <br>
                <h2>Завершены</h2>
                <div class="wrapper__item-block">
                    <ChampionshipCard v-for="(item) in filteredChampFinal" :key="item.id" :payload="item"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ChampionshipCard from '../components/championship/ChampionshipCard.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ChampionshipView',
    components: {
        ChampionshipCard,
    },
    data() {
        return {
            isLoading: false,
            search: '',
        };
    },
    computed: {
        ...mapGetters({
            championshipList: 'championship/championshipList',
        }),
        filteredChampInProcess() {
            return this.championshipList.filter(item => item.name.toLowerCase()
                .includes(this.search.toLowerCase()))
                .filter(item => item.status === 'in_process');
        },
        filteredChampStart() {
            return this.championshipList.filter(item => item.name.toLowerCase()
                .includes(this.search.toLowerCase()))
                .filter(item => item.status === 'start');
        },
        filteredChampNotStart() {
            return this.championshipList.filter(item => item.name.toLowerCase()
                .includes(this.search.toLowerCase()))
                .filter(item => item.status === 'not_started');
        },
        filteredChampFinal() {
            return this.championshipList.filter(item => item.name.toLowerCase()
                .includes(this.search.toLowerCase()))
                .filter(item => item.status === 'final');
        },
    },
    created() {
        this.requestData();
        const { championshipId } = this.$route.params;
        if (championshipId) {
            this.getChampionshipDetails({ id: championshipId });
        }
    },

    methods: {
        ...mapActions({
            getChampionshipList: 'championship/getChampionshipList',
            getChampionshipDetails: 'championship/getChampionshipDetails',
        }),

        async requestData() {
            this.isLoading = true;

            const res = await this.getChampionshipList();

            if (res) {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.champions__wrapper {
    display: flex;
    flex-direction: column;
}

.wrapper__item-block {
    margin-top: 30px;
    display: grid;
    grid-gap: 15px;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
</style>
