<template>
    <section class="invait">
        <div class="container">
            <div class="invait__title title">Сообщения</div>
            <div v-if="messages.length" class="invait__wrapper">
                <div
                    v-for="(item) in messages"
                    :key="item.id"
                    class="invait__wrapper-item"
                >
                    <div class="invait__wrapper-item_more-little">{{ item.text }}</div>
                    <div class="invait__wrapper-item_more-button accept" @click="acceptDelete(item.id)">
                        <button style="width: 100%">Прочитано</button>
                    </div>
                </div>
            </div>
            <div v-else class="invait__wrapper">пусто</div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'InvitingView',
    data() {
        return {
            prevHeight: 0,
        };
    },
    async created() {
        await this.requestData();
        await this.getMessagesCount({ id: this.userId });
    },
    computed: {
        ...mapGetters({
            messages: 'messages/messages',
            messageCount: 'messages/messagesCount',
            userId: 'user/userId',
        }),
    },
    methods: {
        ...mapActions({
            messagesList: 'messages/messagesList',
            readAndDelete: 'messages/readMessage',
            getMessagesCount: 'user/getMessagesCount',
        }),

        async requestData() {
            await this.messagesList({
                id: this.userId,
            });
        },
        async acceptDelete(id) {
            await this.readAndDelete(id);
            await this.requestData();
            await this.getMessagesCount({ id: this.userId });
        },
    },
};
</script>

<style scoped>
.invait__wrapper {
  margin-top: 30px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
}

.invait__wrapper-item_more-little {
  width: 90%;
  padding: 0;
  line-height: 30px;
  word-break: break-word;
}
</style>
