import { send } from '@/global/functions';

const state = {
    list: [],
    currentChampionship: {
        count_members: 0,
        count_commands: 0,
    },
    championshipMembers: [],
    championshipTeams: [],
    // мои изменения
    champTop: [],
    // мои изменеия
    currentTeam: {
        id: 0,
        members: [],
    },
    inviting: {
        user_id: null,
        nominations: [],
    },
    memberRequesting: {
        requests: [],
    },
    requesting: {
        team: null,
        about_me: '',
    },
    isUserParticipates: false,
    isUserHaveCommand: false,
    teamTabIndex: 0,
};

const getters = {
    championshipList: state => state.list,
    currentChampionship: state => state.currentChampionship,
    currentTeam: state => state.currentTeam,
    championshipMembers: state => state.championshipMembers,
    championshipTeams: state => state.championshipTeams,
    inviting: state => state.inviting,
    isUserParticipates: state => state.isUserParticipates,
    isUserHaveCommand: state => state.isUserHaveCommand,
    teamTabIndex: state => state.teamTabIndex,
    memberRequesting: state => state.memberRequesting,
};

const mutations = {
    SET_CHAMPIONSHIP_LIST(state, payload) {
        state.list = payload;
    },
    SET_CURRENT_CHAMPIONSHIP(state, payload) {
        state.currentChampionship = payload;
    },
    SET_CURRENT_TEAM(state, payload) {
        state.currentTeam = payload;
    },
    SET_CHAMPIONSHIP_MEMBERS(state, payload) {
        state.championshipMembers = payload;
    },
    SET_CHAMPIONSHIP_TEAMS(state, payload) {
        state.championshipTeams = payload;
    },
    SET_INVITED_USER(state, payload) {
        state.inviting.user_id = payload;
    },
    SET_INVITED_NOMINATIONS(state, payload) {
        state.inviting.nominations = payload;
    },
    IS_USER_HAVE_COMMAND(state, res) {
        state.isUserHaveCommand = res;
    },
    IS_USER_PARTICIPATES(state, res) {
        state.isUserParticipates = res;
    },
    SET_TEAM_MEMBERS(state, res) {
        state.currentTeam.members = res;
    },
    SET_MEMBERS_COUNT(state, res) {
        state.currentChampionship.count_members = res;
    },
    SET_MEMBERSHIP_REQUESTS(state, res) {
        state.memberRequesting.requests = res;
    },
    SET_TEAMS_COUNT(state, res) {
        state.currentChampionship.count_commands = res;
    },
    SET_TEAM_TAB_INDEX(state, res) {
        state.teamTabIndex = res;
    },
};

const actions = {
    async getChampionshipList({ commit }) {
        try {
            const res = await send({
                uri: 'championship/all',
                method: 'GET',
                wait: true,
            });

            commit('SET_CHAMPIONSHIP_LIST', res);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async getChampionshipDetails({ commit }, req) {
        try {
            const res = await send({
                uri: `championship/detail/${req.id}/`,
                method: 'GET',
            });

            commit('SET_CURRENT_CHAMPIONSHIP', res);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async getChampionshipMembersDetails({ commit }, req) {
        try {
            const res = await send({
                uri: `championship/detail/members/${req.id_championship}`,
                wait: req.wait,
            });

            const filteredArray = res.sort((a, b) => b.id - a.id);

            commit('SET_CHAMPIONSHIP_MEMBERS', filteredArray);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async getChampionshipTeamsDetails({ commit }, req) {
        try {
            const res = await send({
                uri: `championship/detail/teams/${req.id_championship}`,
                wait: req.wait,
            });

            const filteredArray = res.sort((a, b) => b.id - a.id);

            commit('SET_CHAMPIONSHIP_TEAMS', filteredArray);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async getTeamMembersDetails({ commit }, req) {
        try {
            const res = await send({
                uri: `commands/detail/${req.teamId}/`,
                method: 'GET',
                wait: !!req.wait,
            });

            const filteredArray = [];

            res.members.forEach(item => {
                if (item.id === res.main_user) {
                    filteredArray.unshift(item);
                } else {
                    filteredArray.push(item);
                }
            });

            res.members = filteredArray;

            commit('SET_CURRENT_TEAM', res);

            return res;
        } catch (e) {
            console.log(e);
        }
    },
    async updateCommand({ commit }, payload) {
        try {
            const res = await send({
                uri: `commands/detail/${payload.teamId}/`,
                method: 'PUT',
                payload,
            });
            commit('SET_CURRENT_TEAM', res);

            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async addCommandForChampionship({
        commit,
        getters,
    }, req) {
        try {
            const res = await send({
                uri: 'championship/teams/create',
                method: 'POST',
                payload: req,
            });

            const array = getters.championshipTeams;
            array.unshift(res);

            commit('SET_CHAMPIONSHIP_TEAMS', array);
            commit('SET_TEAMS_COUNT', array.length);

            return res;
        } catch (e) {
            console.error(e);
        }
    },
    async deleteCommandForChampionship({
        commit,
        state,
    }, req) {
        // console.log(req, 'reg');
        try {
            await send({
                uri: `championship/teams/delete?team_id=${req.id_team}`,
                method: 'DELETE',
                wait: !!req.wait,
            });
            const array = state.championshipTeams;
            const count = state.currentChampionship.count_commands;
            const newArr = array.filter(item => item.id_team !== req.id_team);
            commit('IS_USER_HAVE_COMMAND', Boolean(!newArr.length));
            commit('SET_CHAMPIONSHIP_TEAMS', newArr);
            commit('SET_TEAMS_COUNT', count - 1);
            return newArr;
        } catch (e) {
            console.log(e);
        }
    },

    async getNominationsList({ commit }) {
        try {
            const res = await send({
                uri: 'nominations',
                method: 'GET',
            });

            commit('SET_INVITED_NOMINATIONS', res);
        } catch (e) {
            console.error(e);
        }
    },

    async getMembershipRequestsList({ commit }) {
        try {
            const res = await send({
                uri: 'api/membership-requests/',
                method: 'GET',
            });

            commit('SET_MEMBERSHIP_REQUESTS', res);
        } catch (e) {
            console.error(e);
        }
    },

    async inviteToTeam({ commit }, req) {
        try {
            const res = await send({
                uri: 'championship/teams/invite',
                method: 'POST',
                payload: req,
            });
            if (res.status === 200) {
                commit('SET_INVITED_USER', '');
            }
            return res;
        } catch (e) {
            console.error(e);
        }
    },

    async invitingChampionship({
        commit,
        getters,
    }, {
        params,
        data,
    }) {
        try {
            const res = await send({
                uri: 'championship/members/create',
                method: 'POST',
                payload: params,
            });
            if (!res.message) {
                const newArray = [...getters.championshipMembers];

                data.team_id = [];

                newArray.push(data);

                commit('SET_CHAMPIONSHIP_MEMBERS', newArray);
                commit('SET_MEMBERS_COUNT', newArray.length);
                commit('IS_USER_PARTICIPATES', true);
            }
            if (res.message) {
                alert(res.message);
            }
            return res;
        } catch (e) {
            console.log(e);
        }
    },

    async getInviteToChampionship({ commit }, req) {
        try {
            const res = await send({
                uri: 'championship/members/exists',
                method: 'POST',
                payload: req,
            });

            if (res.status === 200) {
                commit('IS_USER_PARTICIPATES', true);
            }
        } catch (e) {
            console.error(e);
        }
    },

    async getUserHasCommand({ commit }, req) {
        try {
            const res = await send({
                uri: 'championship/teams/exists',
                method: 'POST',
                payload: req,
            });

            if (res.status === 200) {
                commit('IS_USER_HAVE_COMMAND', true);
            }
        } catch (e) {
            console.error(e);
        }
    },

    async leaveChampionship({
        commit,
        getters,
    }, req) {
        try {
            const res = await send({
                uri: 'championship/leave',
                method: 'POST',
                payload: req,
            });

            if (res.status === 200) {
                const newArray = [...getters.championshipMembers];
                newArray.forEach((item, i) => {
                    if (item.id === req.id_user) {
                        newArray.splice(i, 1);
                    }
                });

                commit('SET_CHAMPIONSHIP_MEMBERS', newArray);
                commit('SET_MEMBERS_COUNT', newArray.length);
                commit('IS_USER_PARTICIPATES', false);
            } else if (res.status === 422) {
                alert('Чтобы покинуть чемпионат, необходимо выйти из команды!');
            }
        } catch (e) {
            console.error(e);
        }
    },

    async setLeaveTeam({
        commit,
        getters,
    }, req) {
        const res = await send({
            uri: 'championship/teams/leave',
            method: 'POST',
            payload: req,
        });

        if (res.status === 200) {
            const newArray = [...getters.currentTeam.members];

            const filteredArray = newArray.filter(item => item.id !== req.id_user);

            commit('SET_TEAM_MEMBERS', filteredArray);
            commit('IS_USER_HAVE_COMMAND', false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
