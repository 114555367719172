<template>
    <div class="modal-detail-champion">
        <div class="container">
            <div class="modal-detail-champion__top">
                <div class="modal-detail-champion__status"></div>
                <p class="champ_result__status">Завершен!</p>
                <div class="modal-detail-champion__title title">
                    <!-- Название чемпионата -->
                    {{champ_title}}
                </div>
            </div>

            <div class="cilce">

            </div>

            <div class="modal-detail-champion__wrapper">
                <div v-for="item in champ_win_data" :key="item.id" class="modal-detail-champion__wrapper-item">
                    <div class="champion-detail__content-participants">
                        <ChampionshipResult
                            :championshipTeamsList="championshipTeams"
                            :participatedChampionshipTeams="participatedChampionshipTeams"
                            :payload="item"
                        />
                    </div>
                </div>
                <!--                <div class="modal-detail-champion__wrapper-item_title">-->
                <!--                    <div class="modal-detail-champion__wrapper-item_counter">   {{item.place}} место </div>-->
                <!--                    <div class="modal-detail-champion__wrapper-item_text">-->
                <!--                        {{item.team_name}}-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="modal-detail-champion__wrapper-item_statistic">-->
                <!--                    <div class="modal-detail-champion__wrapper-item_statistic-top">-->
                <!--                        <div class="modal-detail-champion__wrapper-item_block">-->
                <!--                            <div class="modal-detail-champion__wrapper-item_info">-->
                <!--                                <div class="modal-detail-champion__wrapper-item_subtext">-->
                <!--                                    Заработано денег-->
                <!--                                </div>-->
                <!--                                <div class="modal-detail-champion__wrapper-item_number">-->
                <!--                                    {{item.revenue}}-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <div class="modal-detail-champion__wrapper-item_info">-->
                <!--                                <div class="modal-detail-champion__wrapper-item_subtext">-->
                <!--                                    чистая прибыль-->
                <!--                                </div>-->
                <!--                                <div class="modal-detail-champion__wrapper-item_number">-->
                <!--                                    {{item.netProfit}}-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <div class="modal-detail-champion__wrapper-item_info">-->
                <!--                                <div class="modal-detail-champion__wrapper-item_subtext">-->
                <!--                                    собственник-->
                <!--                                </div>-->
                <!--                                <div class="modal-detail-champion__wrapper-item_number">-->
                <!--                                    {{item.score_owner }}-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <div class="modal-detail-champion__wrapper-item_info">-->
                <!--                                <div class="modal-detail-champion__wrapper-item_subtext">-->
                <!--                                    Финансовая хоз. деят.-->
                <!--                                </div>-->
                <!--                                <div class="modal-detail-champion__wrapper-item_number">-->
                <!--                                    {{item.score_busin}}-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <div class="modal-detail-champion__wrapper-item_info">-->
                <!--                                <div class="modal-detail-champion__wrapper-item_subtext">-->
                <!--                                    Финансовая дисциплина-->
                <!--                                </div>-->
                <!--                                <div class="modal-detail-champion__wrapper-item_number">-->
                <!--                                    {{item.score_finds}}-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <div class="modal-detail-champion__wrapper-item_info">-->
                <!--                                <div class="modal-detail-champion__wrapper-item_subtext">-->
                <!--                                    Инвестиционная привлекательность-->
                <!--                                </div>-->
                <!--                                <div class="modal-detail-champion__wrapper-item_number">-->
                <!--                                    {{item.score_inv}}-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <div class="modal-detail-champion__wrapper-item_info">-->
                <!--                                <div class="modal-detail-champion__wrapper-item_subtext">-->
                <!--                                    Перспектива-->
                <!--                                </div>-->
                <!--                                <div class="modal-detail-champion__wrapper-item_number">-->
                <!--                                    {{item.score_vista}}-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            &lt;!&ndash; <div class="modal-detail-champion__wrapper-item_line">-->
                <!--               <div-->
                <!--                class="modal-detail-champion__wrapper-item_subline"-->
                <!--              ></div>-->
                <!--            </div> &ndash;&gt;-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    &lt;!&ndash;&ndash;&gt;-->
                <!--                    &lt;!&ndash;&ndash;&gt;-->
            </div>
        </div>
    </div>
</template>

<script>
import ChampionshipResult from '@/components/championship/ChampionshipResultCard.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        ChampionshipResult,
    },
    props: ['champ_win_data', 'champ_title'],
    data() {
        return {
            championshipTeams: [],
        };
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
        }),
        participatedChampionshipTeams() {
            return this.championshipTeams.filter(team => team.members.some(member => member.id === this.userId))
                .map(obj => obj.id);
        },
    },
    async mounted() {
        if (this.userId) {
            await this.getInfoAboutTeam();
        }
    },
    methods: {
        ...mapActions({
            getChampionshipTeamsDetails: 'championship/getChampionshipTeamsDetails',
        }),
        async getInfoAboutTeam() {
            this.championshipTeams = await this.getChampionshipTeamsDetails({
                id_championship: this.champ_win_data[0]?.game_id,
                wait: !!this.champ_win_data.game_id,
            });
        },
    },
};
</script>
<style lang='scss' scoped>
.modal-detail-champion__top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.champ_result__status {
  color: black;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.7;
}
</style>
