<template>
    <header class="header">
        <div class="container">
            <button class="bar" @click="isSidebarOpen = true">
                <div class="bar__item"></div>
                <div class="bar__item"></div>
                <div class="bar__item"></div>
            </button>
            <div v-if="!isSidebarOpen" class="header__left">
                <router-link class="header__logo" to="/">
                    <img alt="" src="@/assets/images/logo.svg"/>
                </router-link>

                <div class="menu">
                    <ul v-if="userAuthorized">
                        <li class="menu__list">
                            <router-link to="/testing">Тестирование</router-link>
                        </li>
                        <li class="menu__list">
                            <router-link to="/games">Игры</router-link>
                        </li>
                        <li class="menu__list">
                            <router-link to="/championship">Чемпионаты</router-link>
                        </li>
                        <li class="menu__list">
                            <router-link to="/videos">Видео</router-link>
                        </li>
                        <!-- <li class="menu__list">
                <router-link to="/chat">Диалоги</router-link>
            </li> -->
                        <li class="menu__list">
                            <router-link to="/partners">Партнерам</router-link>
                        </li>
                        <li class="menu__list">
                            <router-link to="/contacts">Контакты</router-link>
                        </li>
                    </ul>
                    <ul v-else>
                        <li class="menu__list">
                            <router-link :to="{name: 'LandMain', hash: '#preview'}">Анонсы</router-link>
                        </li>
                        <li class="menu__list">
                            <router-link :to="{name: 'LandMain', hash: '#new-game' }">Новые игры</router-link>
                        </li>
                        <li class="menu__list">
                            <router-link :to="{name: 'LandMain', hash: '#news' }">Новости</router-link>
                        </li>
                        <li class="menu__list">
                            <router-link :to="{name: 'LandMain', hash: '#videos' }">Видео</router-link>
                        </li>
                        <li class="menu__list">
                            <router-link :to="{name: 'ContactsView' }">Контакты</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else>
                <Sidebar :show="isSidebarOpen" @close-sidebar="closeSidebar"/>
            </div>
            <div v-click-outside="hideUserMenu" class="header__right">
                <button class="header__avatar" @click="isOpenSubmenu = !isOpenSubmenu">
                    <div v-if="invitesCount + messagesCount + membershipRequestsCount > 0" class="header__avatar-value">
                        {{ invitesCount + messagesCount + membershipRequestsCount }}
                    </div>
                    <img
                        :src="
                            cropper.imagesPreviews.userinfo ||
                                user.profile_filed.img ||
                                defaultImg
                        "
                        alt=""/>
                </button>
                <ul v-show="isOpenSubmenu" class="header__avatar-submenu">
                    <template v-if="userAuthorized">
                        <li>
                            <router-link to="/profile">Профиль</router-link>
                        </li>
                        <li>
                            <router-link to="/messages">
                                Сообщения
                                <span v-if="messagesCount > 0">
                                    ({{messagesCount}})
                                </span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/inviting">Приглашения<span
                                v-if="invitesCount > 0">({{ invitesCount }})</span></router-link>
                        </li>
                        <li>
                            <router-link to="/membership_request">Запросы<span
                                v-if="membershipRequestsCount > 0">({{ membershipRequestsCount }})</span>
                            </router-link>
                        </li>
                        <li>
                            <button @click="logout">Выход</button>
                        </li>
                    </template>
                    <template v-else>
                        <li>
                            <router-link to="/register">Регистрация</router-link>
                        </li>
                        <li>
                            <router-link to="/login">Вход</router-link>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import Sidebar from '@/components/main/Sidebar';

export default {
    data() {
        return {
            isOpenSubmenu: false,
            isSidebarOpen: false,
            defaultImg: require('@/assets/images/profile/avatar.svg'),
        };
    },
    components: {
        Sidebar,
    },
    computed: {
        ...mapGetters({
            user: 'user/myInfo',
            userAuthorized: 'user/userAuthorized',
            invitesCount: 'user/invitesCount',
            messagesCount: 'user/messagesCount',
            membershipRequestsCount: 'user/membershipRequestsCount',
            cropper: 'settings/cropper',
        }),
    },
    watch: {
        $route() {
            this.isOpenSubmenu = false;
        },
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout',
        }),
        showSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        closeSidebar() {
            this.isSidebarOpen = false;
        },

        hideUserMenu() {
            this.isOpenSubmenu = false;
        },
    },
};
</script>
