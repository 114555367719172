<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        showModal: false,
    }),
    computed: {
        barWidth() {
            return 100 / this.data.quantity_questions * this.data.quantity_true_answers;
        },
    },
};
</script>

<template>
    <div class="test-main">
        <div class="top-section">
            <div class="test-title">
                <p>{{ data.name }}</p>
            </div>
            <div class="score-icon">
                <img alt="" src="@/assets/images/profile/star.svg">
            </div>
            <div class="num-score">
                <p>{{ data.quantity_true_answers}}/{{ parseInt(data.quantity_questions) }}</p>
            </div>
        </div>
        <div class="test-progress">
            <p>Вы прошли {{ data.quantity_true_answers }} тестов из {{ parseInt(data.quantity_questions) }}</p>
            <div class=progress-bar>
                <div class="progress-line">
                    <div :style="{width: barWidth + '%'}" class="solid"></div>
                </div>
            </div>
        </div>
        <div class="bottom-text">
            <button class="bottom-text" @click="$emit('selected', data.id)">
                <slot>Подробнее →</slot>
            </button>
        </div>
    </div>
</template>

<style scoped>
.test-main {
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.top-section {
  padding: 20px;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}

.test-title {
  width: 150px;
}

.test-title p {
  font-weight: 600;
  font-size: 17px;
  width: 100px;
}

.test-progress {
  width: 100%;
  background-color: #E9E9E9;
  padding: 10px 20px 14px 20px;
}

.progress-bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.test-progress p {
  font-size: 9px;
  margin-bottom: 2px;
}

.progress-line {
  border-radius: 5px;
  width: 100%;
  height: 10px;
  background-color: #B3B3B3;
  margin-top: 5px;
}

.solid {
  border-radius: 5px;
  width: 40%;
  height: 10px;
  background-color: #FF7F00;
  max-width: 100%;
}

.bottom-text {
  text-align: right;
  font-size: 12px;
  padding: 6px;
  color: #7C7A7A;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 1000px) {
  .test-main {
    width: 45%;
  }

  .test-title p {
    font-size: 13px;
  }
}

@media (max-width: 700px) {
  .test-main {
    width: 85%;
  }

  .test-title p {
    font-size: 17px;
  }
}
</style>
