<template>
    <div class="main">
        <ModalCropper/>

        <div class="">
            <router-view/>
        </div>

        <Notifications/>
    </div>
</template>

<script>

import ModalCropper from '../components/modals/common/ModalCropper.vue';
import Notifications from '../components/modals/common/Notifications.vue';

export default {
    name: 'DefaultLayout',
    components: {
        ModalCropper,
        Notifications,
    },
};
</script>

<style lang="scss">
.content-wrapper {
  min-height: 100vh;
  padding-bottom: 40px;
}
</style>
