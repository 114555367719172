<template>
    <div class="champion_modal__card" style="width: 340px">
        <div class="header_modal__wrapper">
            <img alt="icon" class="img_shape__icon" src="@/assets/images/shape-icon.svg">
            <p>{{payload.place}} место</p>
        </div>
        <div class="main_modal__wrapper">
            <div class="team_name__wrapper">
                <h5 class="team_name__title">{{ payload.team_name || 'some name' }}</h5>
                <p class="team_members__quantity">Участников: {{ getCurrentTeam?.members.length || ''}}</p>
            </div>
            <div v-if="isUserMember" class="your_team__wrapper">
                <p>ваша команда</p>
                <img alt="icon" class="img_lider__icon" src="@/assets/images/lider-icon.svg">
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
    name: 'ChampionshipResultsCard',
    props: {
        payload: {
            type: Object,
            default: () => ({}),
        },
        participatedChampionshipTeams: {
            type: Array,
            default: () => ([]),
        },
        championshipTeamsList: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            championshipTeams: [],
        };
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
            teams: 'championship/championshipTeams',
        }),
        isUserMember() {
            return this.participatedChampionshipTeams.includes(this.payload.teamId);
        },
        getCurrentTeam() {
            return this.championshipTeamsList.find(team => team.id === this.payload.teamId);
        },
    },
    methods: {
        ...mapMutations({
            SET_INVITED_USER: 'championship/SET_INVITED_USER',
            CONTENT_IS_LOADED: 'settings/CONTENT_IS_LOADED',
        }),

        ...mapActions({
            toggleModals: 'settings/toggleModals',
            getUserInfo: 'user/getUserInfo',
            // loadAchievements: 'user/loadAchievements',
        }),

        toggleModal(type) {
            this.toggleModals({
                parent: 'championship',
                children: type,
            });
        },
    },
};
</script>

<style>
.champion_modal__card {
  border-radius: 8px;
  background-color: #AEAEAE;
  height: 115px;
  color: black;
}

.team_name__wrapper {
  padding: 15px 12px;
}

.header_modal__wrapper {
  background-color: #1d3e51;
  width: 100%;
  color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 37px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  gap: 10px;
}

.main_modal__wrapper {
  display: flex;
  float: left;
  justify-content: space-around;
  gap: 70px;
}

.your_team__wrapper {
  display: flex;
  align-items: center;
  padding: 15px 0;
  color: #ff7f00;
  font-size: 14px;
  line-height: 24px;
  gap: 10px;
}

.img_shape__icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(338deg) brightness(102%) contrast(105%);
}

.img_lider__icon {
  height: 16px;
  filter: invert(59%) sepia(79%) saturate(3789%) hue-rotate(1deg) brightness(120%) contrast(85%);
}

.team_name__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}

.team_members__quantity {
  margin-top: 2px;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.7;
}

.champion-detail__content-participants {
  display: block;
}

.modal-detail-champion__wrapper-item {
  border-bottom: none;
}

</style>
