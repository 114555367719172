var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test__question test__question-true"},[_c('div',{staticClass:"test__question-about"},[_c('div',{staticClass:"test__question-id"},[_vm._v(_vm._s(_vm.questionIndex + 1))]),_c('div',{staticClass:"test__question-title"},[_vm._v(" "+_vm._s(_vm.question.name)+" ")])]),_c('div',{staticClass:"test__question-answer"},[(_vm.question.type_question === '' || _vm.question.type_question === null)?_c('div',{staticClass:"test__question-selective"},[_c('div',{staticClass:"test__question-flex column"},_vm._l((_vm.question.answer),function(answer){return _c('div',{key:answer.id,staticClass:"test__question-answers"},[_c('label',{staticClass:"test__question-selectText",class:{
                            active: _vm.preparedAnswer === answer.id,
                        },attrs:{"for":'answer' + answer.id}},[_vm._v(_vm._s(answer.name))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preparedAnswer),expression:"preparedAnswer"}],attrs:{"id":'answer' + answer.id,"type":"radio"},domProps:{"value":answer.id,"checked":_vm._q(_vm.preparedAnswer,answer.id)},on:{"change":[function($event){_vm.preparedAnswer=answer.id},function($event){_vm.updateAnswer(
                                _vm.settings.userId,
                                (_vm.testId = _vm.$route.params.testId),
                                _vm.question.id,
                                _vm.preparedAnswer,
                                answer.points,
                                answer.is_true
                            )}]}})])}),0)]):_vm._e(),(_vm.question.type_question === 'image')?_c('div',{staticClass:"test__question-picture"},[_c('div',{staticClass:"testing__item-picture_example"},[_c('img',{attrs:{"alt":_vm.question.descrip,"src":_vm.question.img}})]),_c('div',{staticClass:"testing__item-picture_select"},[_c('div',{staticClass:"testing__item-picture_text"},[_vm._v(" Выберите один из вариантов ответа ")]),_c('div',{staticClass:"testing__item-picture_wrapper"},_vm._l((_vm.question.answer),function(answer){return _c('div',{key:answer.id,staticClass:"testing__item-picture_variant",class:{
                            active: _vm.preparedAnswer === answer.id,
                        }},[_c('label',{staticClass:"test__question-selectImage",attrs:{"for":'answer' + answer.id}},[_c('img',{attrs:{"src":answer.img,"alt":""}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preparedAnswer),expression:"preparedAnswer"}],attrs:{"id":'answer' + answer.id,"type":"radio"},domProps:{"value":answer.id,"checked":_vm._q(_vm.preparedAnswer,answer.id)},on:{"change":[function($event){_vm.preparedAnswer=answer.id},function($event){_vm.updateAnswer(
                                    _vm.settings.userId,
                                    (_vm.testId = _vm.$route.params.testId),
                                    _vm.question.id,
                                    _vm.preparedAnswer,
                                    answer.points,
                                    answer.is_true
                                )}]}})])}),0)])]):_vm._e(),(_vm.question.type_question === 'multi')?_c('div',{staticClass:"test__question-checkbox"},[_c('div',{staticClass:"test__question-checkbox_title"},[_vm._v(" Вы можете выбрать несколько вариантов ")]),_c('div',{staticClass:"test__question-checkbox_wrapper"},_vm._l((_vm.question.answer),function(answer){return _c('div',{key:answer.id,staticClass:"test__question-checkbox_variant-multi"},[_c('div',{staticClass:"flexbox"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preparedAnswer),expression:"preparedAnswer"}],attrs:{"id":'answer' + answer.id,"type":"checkbox"},domProps:{"value":answer.id,"checked":Array.isArray(_vm.preparedAnswer)?_vm._i(_vm.preparedAnswer,answer.id)>-1:(_vm.preparedAnswer)},on:{"change":[function($event){var $$a=_vm.preparedAnswer,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=answer.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.preparedAnswer=$$a.concat([$$v]))}else{$$i>-1&&(_vm.preparedAnswer=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.preparedAnswer=$$c}},function($event){_vm.updateAnswer(
                                        _vm.settings.userId,
                                        (_vm.testId = _vm.$route.params.testId),
                                        _vm.question.id,
                                        _vm.preparedAnswer,
                                        answer.points,
                                        answer.is_true
                                    )}]}}),_c('label',{attrs:{"for":'answer' + answer.id}})]),_c('div',[_vm._v(_vm._s(answer.name))])])])}),0)]):_vm._e()]),_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }