<template>
    <div class="profile__skills-block_item">
        <div
            v-if="payload.type === 'softSkill'"
            :style="{
                height:
                    preparedProgressBar({
                        current: payload.quantity_complete_tests,
                        type: 'soft'
                    }) + 'px',
            }"
            class="profile__skills-block_top"
        >
            <div class="profile__skills-block_number">
                {{
                    preparedTitleBar({
                        current: payload.quantity_complete_tests,
                        max: payload.quantity_max_tests,
                        type: 'soft',
                    })
                }}
            </div>
        </div>

        <div
            v-if="payload.type === 'hardSkill'"
            :style="{
                height:
                    preparedProgressBar({
                        current: payload.quantity,
                        type: 'hard'
                    }) + 'px',
            }"
            class="profile__skills-block_top"
        >
            <div class="profile__skills-block_number">
                {{
                    preparedTitleBar({
                        current: payload.quantity,
                        max: payload.quantity_max,
                        type: 'hard',
                    })
                }}
            </div>
        </div>

        <div class="profile__skills-block_bottom">{{ payload.name }}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ProfileSkillsCard',
    props: {
        payload: {
            type: Object,
            default: () => {
                return {
                    type: '',
                    quantity_max_tests: 0,
                    quantity_complete_tests: 0,
                    quantity: 0,
                    quantity_max: 0,
                    name: '',
                };
            },
        },
    },
    data() {
        return {
            maxSkillHeight: 2,
        };
    },
    methods: {
        preparedProgressBar({
            current,
            type,
        }) {
            const max = Math.max(...this.preparedGroupSoftSkills.map(i => Number(i.quantity_max_tests)));
            switch (type) {
                case 'soft':
                    return this.maxSkillHeight * (100 / (max / current)) / 2;
                case 'hard':
                    return this.maxSkillHeight * Math.floor(current);
                default:
                    return 0;
            }
        },

        preparedTitleBar({
            current,
            max,
            type,
        }) {
            switch (type) {
                case 'soft':
                    return current + ' / ' + max;
                case 'hard':
                    return Math.floor(current) + '%' + ' / ' + max + '%';
                default:
                    return '';
            }
        },
    },
    computed: {
        ...mapGetters({
            user: 'user/myInfo',
        }),
        preparedHardSkills() {
            return this.user.Hard_skill_field.map(item => ({
                ...item,
                type: 'hardSkill',
            }));
        },

        preparedGroupSoftSkills() {
            return this.user.Group_Soft_skill_field.map(item => ({
                ...item,
                type: 'softSkill',
            }));
        },
    },
};
</script>
