import { send } from '@/global/functions';

const state = {
    testingList: [],
    currentTest: {
        question: [],
    },
    testHistory: [],
};

const getters = {
    testingList: state => state.testingList,
    currentTest: state => state.currentTest,
    testHistory: state => state.testHistory,
};

const mutations = {
    SET_TESTING_LIST(state, testing) {
        state.testingList = testing;
    },
    SET_CURRENT_TEST(state, test) {
        state.currentTest = test;
    },
    SET_TEST_HISTORY(state, history) {
        state.testHistory = history;
    },
    CLEAR_CURRENT_TEST(state, payload) {
        state.currentTest = payload;
    },
};

const actions = {
    clearCurrentTest({ commit }) {
        commit('CLEAR_CURRENT_TEST', {
            question: [],
        });
    },
    async getTestingList({ commit }, params) {
        try {
            const response = await send({
                uri: `tests_prepared_informations/${params.id_user}`,
                method: 'GET',
                wait: true,
            });
            commit('SET_TESTING_LIST', response);

            return response;
        } catch (e) {
            console.log(e);
        }
    },

    async getCurrentTest({ commit }, params) {
        try {
            const response = await send({
                uri: `Test_all_questions/detail/${params}`,
                method: 'GET',
            });
            commit('SET_CURRENT_TEST', response);
            return response.question;
        } catch (e) {
            console.log(e);
        }
    },

    async getTestHistory({ commit }, params) {
        try {
            const response = await send({
                uri: `tests_history/${params.userId}/${params.testId}`,
                method: 'GET',
            });
            commit('SET_TEST_HISTORY', response);
            return response;
        } catch (e) {
            console.log(e);
        }
    },

    async setAnswer(state, params) {
        try {
            await send({
                uri: `tests_history_add/${params.id_user}/${params.id_test}/${params.id_question}/${params.id_answer}/${params.points}/${params.is_true}/${params.multi_arr}`,
                method: 'GET',
                resType: 'full',
            });
        } catch (e) {
            console.log(e);
        }
    },

    async setTestResults(state, params) {
        try {
            const response = await send({
                uri: `tests_calc_add/${params.id_user}/${params.id_test}`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
