import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import user from './modules/user';
import settings from './modules/settings';
import invites from './modules/invites';
import messages from './modules/messages';
import membershipRequests from '@/store/modules/membershipRequests';
import testing from './modules/testing';
import championship from './modules/championship';
import games from './modules/games';
import videos from './modules/videos';
import contacts from './modules/contacts';
import partners from './modules/partners';
import globals from './modules/globals';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        user,
        settings,
        invites,
        messages,
        membershipRequests,
        testing,
        championship,
        games,
        videos,
        contacts,
        partners,
        globals,
    },
});
