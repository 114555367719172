<template>
    <div v-if="item.status !== 'final'" class="preview__wrapper-item">
        <div v-if="item.status === 'start'" class="preview__wrapper-start-text">
            Подготовка началась! Найди свою команду!
        </div>
        <router-link
            :style="{
                'background-image': `url(${item.img})`,
            }"
            class="preview__wrapper-item_top"
            to="/"
        ></router-link>
        <div class="preview__wrapper-item_bottom">
            <div class="preview__wrapper-title">{{ item.name }}</div>
            <p class="preview__wrapper-text">
                {{ item.description }}
            </p>
            <div class="preview__wrapper-start-date"> Дата начала: {{ formattedDate}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],
    computed: {
        formattedDate() {
            const months = [
                'янв.',
                'фев.',
                'мар.',
                'апр.',
                'мая',
                'июн.',
                'июл.',
                'авг.',
                'сен.',
                'окт.',
                'ноя.',
                'дек.',
            ];
            const dateParts = this.item.date_start.split('-');
            const year = dateParts[0];
            const month = months[parseInt(dateParts[1]) - 1];
            const day = parseInt(dateParts[2]);
            return `${day} ${month} ${year} г.`;
        },
    },
};
</script>
