<template>
    <div class="main auth-layout">
        <transition mode="out-in" name="fade">
            <router-view/>
        </transition>
        <Footer auth/>
        <Notifications/>
    </div>
</template>

<script>
import Footer from '../components/main/Footer.vue';
import Notifications from '../components/modals/common/Notifications.vue';

export default {
    name: 'AuthLayout',
    components: {
        Footer,
        Notifications,
    },
};
</script>
