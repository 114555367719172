<template>
    <div class="modal-add-comand">
        <button class="modal-add-comand__close">
            <img alt="" src="@/assets/images/close-icon.svg" @click="toggleModal"/>
        </button>
        <div class="container">
            <div class="modal-add-comand__title">Добавление команды</div>
            <form class="modal-add-comand__wrapper">
                <div class="modal-add-comand__wrapper-input">
                    <span>Название команды</span>
                    <input v-model="command.name" type="text"/>
                </div>
                <div class="modal-date__wrapper-select">
                    <span>Город</span>
                    <v-select
                        v-model="user.profile_filed.city"
                        :options="userSettings.city.options"
                        height="62"
                        label="name"
                    ></v-select>
                </div>
                <div class="modal-add-comand__wrapper-input">
                    <span>Описание команды</span>
                    <input v-model="command.description" type="text"/>
                </div>
                <div class="modal-add-comand__wrapper-submit btn" @click="updateCommand">
                    <button type="button">Обновить команду</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    name: 'ModalChampionshipUpdateCommand',
    data() {
        return {
            command: {
                name: this.team && this.team.name,
                city: this.team && this.team.city,
                description: this.team && this.team.description,
                id: this.team && this.team.id,
            },
        };
    },
    props: {
        team: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters({
            championship: 'championship/currentChampionship',
            inviting: 'championship/inviting',
            currentTeam: 'championship/currentTeam',
            user: 'user/myInfo',
            userSettings: 'user/settings',
        }),
    },
    created() {
    },
    methods: {
        ...mapActions({
            getTeamMembersDetails: 'championship/getTeamMembersDetails',
            commandUpdate: 'championship/updateCommand',
            toggleModals: 'settings/toggleModals',
            getChampionshipTeamsDetails: 'championship/getChampionshipTeamsDetails',
        }),

        ...mapMutations({
            SET_TEAM_TAB_INDEX: 'championship/SET_TEAM_TAB_INDEX',
        }),

        toggleModal() {
            this.toggleModals({
                parent: 'championship',
                children: 'update_command',
            });
        },

        async updateCommand() {
            const data = {
                name: this.command.name,
                description: this.command.description,
                id_user: this.user.id,
                id_championship: this.championship.id,
                city_name: this.command.city,
                teamId: this.command.id,
                main_user: this.team.main_user,
                invited_members: this.team.invited_member,
                members: this.team.members,
            };

            const res = await this.commandUpdate(data);
            if (res) {
                this.toggleModal();
                this.SET_TEAM_TAB_INDEX(0);
                await this.getChampionshipTeamsDetails({
                    id_championship: this.championship.id,
                    wait: !!this.championship.id,
                });
                await this.getTeamMembersDetails({
                    teamId: res.id,
                    wait: true,
                });
            }
        },
    },
};
</script>

<style scoped>
.modal-add-comand {
  z-index: 50;
}
</style>
