<template>
    <section class="partner">
        <div class="container">
            <div class="partner__title title">Партнерам и инвесторам</div>
            <div class="partner__wrapper">
                <div class="partner__wrapper-left">
                    <div class="partner__wrapper-left_text" v-html="partnersInfo.text_for_page"/>
                    <div class="partner__wrapper-left_info" v-html="partnersInfo.additional_information"/>
                </div>

                <div class="partner__wrapper-right">
                    <div class="partner__wrapper-right_item">
                        <div class="partner__wrapper-right_number">{{
                            preparedPartnersInfo(partnersInfo.partner_registraion)
                        }}
                        </div>
                        <div class="partner__wrapper-right_text">
                            предпринимателя зарегистрированы
                        </div>
                    </div>

                    <div class="partner__wrapper-right_item">
                        <div class="partner__wrapper-right_number">{{
                            '+' + preparedPartnersInfo(partnersInfo.day_registrations)
                        }}
                        </div>
                        <div class="partner__wrapper-right_text">
                            ежедневно регистрируются
                        </div>
                    </div>

                    <div class="partner__wrapper-right_item">
                        <div class="partner__wrapper-right_number">{{
                            preparedPartnersInfo(partnersInfo.increase_percentage) + '%'
                        }}
                        </div>
                        <div class="partner__wrapper-right_text">
                            на столько процентов в среднем увлечилиась эффективность
                            предпринимателей после взаимодействия с платформой*
                        </div>
                    </div>

                    <div class="partner__wrapper-right_item">
                        <div class="partner__wrapper-right_number">{{
                            preparedPartnersInfo(partnersInfo.average_monthly_audience)
                        }}
                        </div>
                        <div class="partner__wrapper-right_text">
                            средняя ежемесячная аудитория платформы
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { convertNumbers } from '@/global/functions';

export default {
    name: 'PartnersView',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            partnersInfo: 'partners/partnersInfo',
        }),
    },
    async created() {
        await this.getPartnersInfo();
    },
    methods: {
        ...mapActions({
            getPartnersInfo: 'partners/getPartnersInfo',
        }),

        preparedPartnersInfo(value) {
            return convertNumbers(value);
        },
    },

};
</script>
