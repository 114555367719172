<script>
export default {
    props: {
        quantityOfAllItems: {
            type: String,
            default: '0',
        },
        completedQuantity: {
            type: String,
            default: '0',
        },
    },
    computed: {
        barWidth() {
            return 100 / this.quantityOfAllItems * this.completedQuantity;
        },
    },
};
</script>

<template>
    <div class="general-block">
        <p>
            <slot>Софтскилл 1</slot>
        </p>
        <div class="level">
            <div :style="{width: barWidth + '%'}" class="level-bar"></div>
        </div>
    </div>
</template>

<style scoped>
.general-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 490px;
  height: 35px;
  border-radius: 5px;
  background: #FF7F00;
}

.general-block p {
  margin-left: 13px;
  color: #FFF;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}

.general-block .level {
  display: flex;
  width: 250px;
  border-radius: 5px;
  margin-right: 13px;
  height: 10px;
  background-color: #B3B3B3;
}

.level-bar {
  border-radius: 5px;
  background: #204B6F;
  width: 0px;
  height: 10px;
}

@media (max-width: 1100px) {
  .general-block {
    width: 320px
  }

  .general-block .level {
    width: 130px;
    min-width: 130px;
  }
}
</style>
