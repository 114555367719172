<template>
    <div v-show="!visible" class="loading"></div>
</template>

<script>
export default {
    name: 'BeforeLoading',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.loading {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eeeff2;
}
</style>
