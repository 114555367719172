<script>
import NeuralResultBlockComponent from '@/components/profile/NeuralResultBlockComponent.vue';
import { send } from '../../global/functions';

export default ({
    components: {
        NeuralResultBlockComponent,
    },
    data() {
        return {
            neuralNetworkData: {},
            translationDictionary: {
                analytical_thinking: 'Аналитическое мышление',
                critical_thinking: 'Критическое мышление',
                systemic_thinking: 'Системное мышление',
                social_intelligence: 'Социальный интеллект',
                confident_communication: 'Уверенное общение',
                adaptability_and_openness_to_change: 'Адаптивность и открытость к изменениям',
                learning_agility: 'Обучаемость',
                focus_on_ambitious_result: 'Сосредоточенность на амбициозных результатах',
                willingness_to_take_tasks_of_the_next_level_of_responsibility: 'Готовность брать задачи следующего уровня ответственности',
            },
        };
    },
    methods: {
        async requestData() {
            const res = await send({
                uri: 'profile/neural-network/',
                method: 'GET',
            });
            const data = {};
            if (res && res[0]) {
                for (const [key, value] of Object.entries(res[0])) {
                    data[key] = JSON.parse(value);
                }
            }
            this.neuralNetworkData = data;
        },
    },
    created() {
        this.requestData();
    },
});
</script>

<template>
    <div>
        <p class="neural-main-title">Результаты нейронки</p>
        <div class="result-section">
            <NeuralResultBlockComponent
                v-for="(slug, i) in Object.keys(translationDictionary)"
                :key="i"
                :score="neuralNetworkData[slug]"
            >
                {{ translationDictionary[slug] }}
            </NeuralResultBlockComponent>
        </div>
    </div>
</template>

<style scoped>
.result-section {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.neural-main-title {
  margin-top: 20px;
  font-size: 17px;
}

@media (max-width: 1100px) {
  .neural-main-title {
    text-align: center;
  }

  .result-section {
    justify-content: center;
  }
}
</style>
