<template>
    <div class="index-bar">
        <p class="index-title">
            {{ indicator.name || 'Показатель' }}
        </p>
        <div class="right-index">
            <div class="event-index">
                <button
                    v-for="i in indicator.total"
                    :key="i"
                    :class="{ 'selected': i === indicator.rating_scale }"
                    @click="$emit('change', {id: indicator.id, number: i})"
                >
                    <p>{{ i }}</p>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        indicator: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style>
.index-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  height: 50px;
  border-radius: 15px;
  background: #0D2144B2;
  border: 2px solid #D9D9D980;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-top: 5px;
  padding: 0 15px;
}

.index-title {
  color: #FF7F00;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.event-index button {
  color: #fff;
  margin-left: 20px;
  font-size: 20px;
}

.event-index button:hover,
.event-index button.selected {
  color: #FF7F00;
  scale: 1.2;
  transition: all .3s;
}
</style>
