<script>
import TestResultBlockComponent from '@/components/profile/TestResultBlockComponent.vue';
import {
    mapGetters
} from 'vuex';

export default ({
    components: {
        TestResultBlockComponent,
    },
    data: () => ({
        skillSelected: '',
    }),
    computed: {
        ...mapGetters({
            hardSkills: 'user/hardSkills',
            softSkills: 'user/softSkills',
        }),
        testResults() {
            const hardSkills = this.hardSkills.map(skill => {
                skill.id = 'h' + skill.id;
                return skill;
            });
            const softSkills = this.softSkills.map(skill => {
                skill.id = 's' + skill.id;
                return skill;
            });
            return [...hardSkills, ...softSkills];
        },
        currentTestResults() {
            return this.testResults.find(result => result.id === this.skillSelected);
        },
    },
    methods: {
        selectSkill(id) {
            this.skillSelected = id;
        },
    },
});
</script>

<template>
    <div>
        <div v-if="!skillSelected" class="no-modal">

            <div class="title-result-gen">
                <p class="view-title">Результаты тестов</p>
            </div>
            <div class="result-section">
                <TestResultBlockComponent
                    v-for="(testResults, i) in testResults"
                    :key="i"
                    :data="testResults"
                    @selected="selectSkill"></TestResultBlockComponent>
            </div>
        </div>
        <div v-else>
            <div class="modal-main">
                <div class="test-img">
                    <img :src="currentTestResults.img" alt="">
                </div>
                <div class="text-section">

                    <div class="test-title">
                        {{currentTestResults.name}}
                    </div>
                    <div class="test-info">
                        {{currentTestResults.recommendation}}
                    </div>
                </div>
            </div>
            <button class="back-btn" @click="skillSelected = ''">
                <p>Назад</p>
            </button>
        </div>
    </div>
</template>

<style scoped>
.result-section {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 50px;
}

.modal-main {
  display: flex;
}

.test-img {
  width: 300px;
  height: 200px;
  background-color: #DDDDE0;
  margin: 20px;
  border-radius: 5px;
}

.test-img img {
  width: 300px;
  height: 200px;
  border-radius: 5px;
}

.text-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.test-title {
  font-size: 25px;
  font-weight: bold;
}

.test-info {
  width: 700px;
  margin-top: 15px;
}

.back-btn-main {
  display: flex;
}

.back-btn {
  color: #FFFFFF;
  background-color: #204B6F;
  border: 1.75px solid #204B6F;
  padding: 12px 25px;
  border-radius: 5px;
  transition: .5s;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.back-btn:hover {
  border: 1.75px solid #204B6F;
  color: #204B6F;
  background-color: transparent;
}

.view-title {
  margin-top: 20px;
  font-size: 17px;
  margin-bottom: -20px;
}

@media (max-width: 1100px) {
  .modal-main {
    flex-direction: column;
  }

  .test-img {
    width: 90%;
    height: 90%;
  }

  .text-section {
    align-items: center;
  }

  .test-img img {
    width: 100%;
    height: 100%;
    min-height: 200px;
  }

  .test-title {
    font-size: 15px;
    width: 95%;
  }

  .test-info {
    width: 95%;
    margin-top: 8px;
    text-align: start;
  }

  .view-title {
    text-align: center;
  }

  .back-btn {
    margin-top: 10px;
  }
}

@media (max-width: 700px) {
  .result-section {
    flex-direction: column;
    align-items: center;
  }

  .modal-main {
    flex-direction: column;
  }

  .test-img {
    width: 90%;
    height: 90%;
  }

  .text-section {
    align-items: center;
  }

  .test-img img {
    width: 100%;
    height: 100%;
    min-height: 200px;
  }

  .test-title {
    font-size: 15px;
    width: 95%;
  }

  .test-info {
    width: 95%;
    margin-top: 8px;
    text-align: start;
  }

  .view-title {
    text-align: center;
  }
}
</style>
