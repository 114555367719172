<template>
    <div class="main-expert">
        <div class="button-line">
            <router-link to="/expert/profile/personal-information">
                <ExpertProfileButtonComponent>Персональная информация</ExpertProfileButtonComponent>
            </router-link>
            <router-link to="/expert/profile/events">
                <ExpertProfileButtonComponent>Мероприятия</ExpertProfileButtonComponent>
            </router-link>
            <router-link to="/expert/profile/archive">
                <ExpertProfileButtonComponent>Архив</ExpertProfileButtonComponent>
            </router-link>
        </div>
        <router-view/>
    </div>
</template>

<script>
import ExpertProfileButtonComponent from '@/views/expert/ExpertProfileButtonComponent.vue';

export default {
    components: {
        ExpertProfileButtonComponent,
    },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

.active-orange button {
  background: #FF820D;
  border-radius: 15px;
}

.main-expert {
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  background-image: url('@/assets/images/profile-bg.png');
  background-repeat: no-repeat;
}

.hero-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
  font-size: 10px;

  /deep/ .button {
    min-width: 360px;
  }
}

@media (max-width: 1100px) {
  .button-line {
    flex-direction: column;
    gap: 20px;
  }
}
</style>
