import { send } from '@/global/functions';

export const state = {
    messagesArray: [],
    messagesCount: 0,
};

export const getters = {
    messages: state => state.messagesArray,
    messagesCount: state => state.messagesCount,
};

export const mutations = {
    SET_MESSAGES(state, res) {
        state.messagesArray = res;
    },
    UPDATE_MESSAGES_COUNT(state) {
        state.messagesCount -= 1;
    },
};

export const actions = {
    async messagesList({
        commit,
        rootGetters,
    }, req) {
        try {
            const response = await send({
                uri: `message/detail/${req.id}/`,
                method: 'GET',
            });
            commit('SET_MESSAGES', response);
        } catch (e) {
            console.log(e);
        }
    },

    async readMessage({
        commit,
        getters,
        dispatch,
        rootGetters,
    }, req) {
        try {
            const res = await send({
                uri: `message/delete/${req}`,
                method: 'DELETE',
            });
            if (res) {
                const newMessagesArray = getters.messages.filter(item => item.id !== req);
                const messagesCount = rootGetters['user/myInfo'].messagesCount - 1;
                commit('user/UPDATE_MESSAGES_COUNT', messagesCount, { root: true });
                commit('SET_MESSAGES', newMessagesArray);
            }
        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
