<template>
    <div class="main">
        <section class="profile">
            <div class="container">
                <div class="profile__title title">Профиль</div>
                <div class="profile__tabs">
                    <button
                        v-for="(btn, btnIndex) in tabsContentProfile"
                        :key="btnIndex"
                        :class="{ 'profile__tabs-btn--active': activeTabItem === btn.index }"
                        class="profile__tabs-btn"
                        @click="changeTabItem(btnIndex)">
                        <router-link :to="btn.url">
                            {{ btn.name }}
                        </router-link>
                    </button>
                </div>
                <div class="profile__content">
                    <router-view/>
                </div>
            </div>

            <transition name="fade">
                <ModalEditDate v-if="modals.profile.data"/>
            </transition>
            <transition name="fade">
                <ModalEditPassword v-if="modals.profile.password"/>
            </transition>
            <transition name="fade">
                <ModalUploadVideo v-show="modals.profile.video"/>
            </transition>
            <transition name="fade">
                <ModalEditUserGames v-if="modals.profile.games"/>
            </transition>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalEditDate from '@/components/modals/profile/ModalEditDate.vue';
import ModalEditPassword from '@/components/modals/profile/ModalEditPassword.vue';
import ModalUploadVideo from '@/components/modals/profile/ModalUploadVideo.vue';
import ModalEditUserGames from '@/components/modals/profile/ModalEditUserGames.vue';

export default {
    name: 'ProfileView',
    components: {
        ModalEditDate,
        ModalEditPassword,
        ModalUploadVideo,
        ModalEditUserGames,
    },
    data() {
        return {
            tabsContentProfile: [{
                name: 'Мои данные',
                url: '/profile',
                index: 0,
            },
            {
                name: 'Результаты тестов',
                url: '/profile/results',
                index: 1,
            },

            {
                name: 'Мои видео',
                url: '/profile/video',
                index: 2,
            },
            {
                name: 'Результаты чемпионатов',
                url: '/profile/champ',
                index: 3,
            },
            {
                name: 'Результаты нейронки',
                url: '/profile/neural_network',
                index: 4,
            },
            ],
            activeTabItem: Number(localStorage.getItem('profileTabIndex')) || 0,
        };
    },
    computed: {
        ...mapGetters({
            modals: 'settings/modals',
        }),
    },
    created() {
        const activeTabIndex = this.tabsContentProfile.find(item => item.url === this.$route.path);
        if (activeTabIndex) {
            this.activeTabItem = activeTabIndex.index;
            localStorage.setItem('profileTabIndex', activeTabIndex.index);
        }
    },
    methods: {
        changeTabItem(index) {
            this.activeTabItem = index;
            localStorage.setItem('profileTabIndex', index);
        },
    },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Inter';
}

.content-wrapper {
  min-height: 100vh;
  padding-bottom: 40px;
}
</style>
