<template>
    <div class="profile__videos-item">
        <div class="profile__videos-item_top">
            <iframe
                :src="'https://www.youtube.com/embed/'+payload.url+'?controls=0&showinfo=0&rel=0'"
                :srcdoc="'<style>*{overflow:hidden;padding:0;margin:0;}html,body{height:100%;}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a class=preloadLink href=//www.youtube.com/embed/'+payload.url+'?controls=1&showinfo=0&rel=0&autoplay=1><img class=preloadImg src=https://img.youtube.com/vi/'+payload.url+'/hqdefault.jpg><span>▶</span></a>'"
                allow="autoplay"
                allowfullscreen
                frameborder="0"
            ></iframe>
            <button
                class="profile__videos-item_edit"
                @click="editVideo({id: payload.id, name: payload.name, description: payload.description, url: urlMask + payload.url})"
            />
        </div>
        <div class="profile__videos-item_bottom">
            <div class="profile__videos-item_title">
                {{ payload.name }}
            </div>
            <div class="profile__videos-item_text">
                {{ payload.description }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
    name: 'ProfileVideoCard',
    props: {
        payload: {
            type: Object,
            default: () => ({}),
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            urlMask: 'https://youtube.com/watch?v=',
        };
    },
    methods: {
        ...mapMutations({
            SET_VIDEO: 'user/SET_VIDEO',
        }),

        ...mapActions({
            toggleModals: 'settings/toggleModals',
        }),

        toggleModal() {
            this.toggleModals({
                parent: 'profile',
                children: 'video',
            });
        },

        editVideo(payload) {
            this.SET_VIDEO({
                ...payload,
                src: '',
                passed: {
                    youtube: true,
                    others: true,
                },
                isEdit: true,
            });

            if (!this.isEdit) {
                this.toggleModal();
            }
        },
    },
};
</script>

<style>

</style>
