<template>
    <div
        class="games__wrapper-item"
    >
        <div class="games__wrapper-item_img">
            <img :src="item.img" alt="">
        </div>
        <div class="new-game__wrapper-item_bottom">
            <div class="new-game__wrapper-item_title">{{ item.name }}</div>
            <div class="new-game__wrapper-item_seperator"></div>
            <p class="new-game__wrapper-item_text">{{ item.description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],
};
</script>
