<template>
    <div class="block">
        <router-link class="news__wrapper-item" to="/">
            <div v-if="item.photos && item.photos.length > 0">
                <img :src="item.photos[0].image" alt="">
            </div>
            <div class="news__wrapper-item_time">{{ dateformat(item.time)}}</div>
            <div class="news__wrapper-item_title">{{ item.title }}</div>
            <p class="news__wrapper-item_text">{{ item.text }}</p>
        </router-link>
        <a :href="item.link" class="link"><p>Ссылка на источник </p></a>
    </div>

</template>

<script>
import moment from 'moment';

export default {
    props: ['item'],
    methods: {
        dateformat(dateold) {
            return moment(dateold)
                .format('DD.MM.yyyy');
        },
    },

};
</script>

<style lang="scss" scoped>
.block {
  width: 86%;

  & * {
    width: 86%;
  }
}

.link {
  color: #ff7f00;
}
</style>
