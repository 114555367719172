<template>
    <div class="champion-detail__content">
        <div v-if="isLoadingMembers" class="champion-detail__content-participants">
            <SkeletonCard
                v-for="item in 8"
                :key="item"
                primary="#e3e3e3"
                structure="1"/>
        </div>
        <div v-else class="champion-detail__content-participants">
            <MemberCard
                v-for="(member, memberIndex) in preparedMembersList"
                :key="memberIndex"
                :isMy="member.id === userId"
                :participate="!!member.team_id.length"
                :payload="member"
                :team-leader="teamL"
                @invite="$emit('modals', 'invite')"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MemberCard from '@/components/championship/MemberCard';

export default {
    name: 'ChampionshipMembersView',
    components: {
        MemberCard,
    },
    data() {
        return {
            isLoadingMembers: false,
        };
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
            championshipMembers: 'championship/championshipMembers',
            currentTeam: 'championship/currentTeam',
            inviting: 'championship/inviting',
            teams: 'championship/championshipTeams',
            currentChampionship: 'championship/currentChampionship',
        }),

        preparedMembersList() {
            return this.championshipMembers;
        },

        championshipId() {
            return this.currentChampionship.id ?? null;
        },
        champId() {
            return Number(this.$route.params.championshipId) ?? null;
        },
        teamL() {
            return this.teams.find(item => item.main_user === this.userId);
        },
    },
    watch: {
        isLoadingMembers() {
            if (!this.isLoadingMembers) {
                this.$emit('loaded');
            }
        },
    },
    async created() {
        localStorage.setItem('championshipTabIndex', 0);
        await this.championshipDetails();
        await this.championshipDetailsf();
        await this.getChampionshipDetails({ id: this.champId });

        await this.getInviteToChampionship({
            id_user: this.userId,
            id_championship: this.championshipId,
        });
        await this.getUserHasCommand({
            id_user: this.userId,
            id_championship: this.championshipId,
        });
    },
    methods: {
        ...mapActions({
            getChampionshipDetails: 'championship/getChampionshipDetails',
            getChampionshipTeamsDetails: 'championship/getChampionshipTeamsDetails',
            getTeamMembersDetails: 'championship/getTeamMembersDetails',
            getChampionshipMembersDetails: 'championship/getChampionshipMembersDetails',
            getInviteToChampionship: 'championship/getInviteToChampionship',
            getUserHasCommand: 'championship/getUserHasCommand',
        }),

        async championshipDetails() {
            this.isLoadingMembers = true;

            const second_res = await this.getChampionshipMembersDetails({
                id_championship: this.championshipId,
                wait: !!this.currentChampionship.id,
            });

            if (second_res) {
                this.isLoadingMembers = false;
            }
        },
        async championshipDetailsf() {
            this.isLoadingTeams = true;

            await this.getChampionshipTeamsDetails({
                id_championship: this.championshipId,
                wait: !!this.currentChampionship.id,
            })
                .then(item => {
                    this.isLoadingTeams = false;
                    if (item.length) {
                        return this.getTeamMembersDetails({ teamId: this.teams[0].id });
                    }
                })
                .then(() => {
                    this.isLoadingMembers = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>

<style>

</style>
