import { send } from '@/global/functions';

const state = {
    contactsInfo: {},
};

const getters = {
    contactsInfo: state => state.contactsInfo,
};

const mutations = {
    SET_CONTACTS_INFO(state, res) {
        state.contactsInfo = res;
    },
};

const actions = {
    async sendContactsMessage({ commit }, payload) {
        try {
            await send({
                uri: 'msg_from_contact_page/create',
                method: 'POST',
                payload,
            });

            alert('Спасибо, мы получили ваш запрос и вернемся с ответом в ближайшее время');
        } catch (e) {
            console.error(e);
        }
    },

    async getContactsInfo({ commit }) {
        try {
            const res = await send({
                uri: 'contact_page/text',
                method: 'GET',
            });

            commit('SET_CONTACTS_INFO', res);
        } catch (e) {
            console.error(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
