import { send } from '@/global/functions';

export const state = {
    membershipRequestsArray: [],
    membershipRequestsCount: 0,
};

export const getters = {
    membershipRequests: state => state.membershipRequestsArray,
    membershipRequestsCount: state => state.membershipRequestsCount,
};

export const mutations = {
    SET_MEMBERSHIP_REQUESTS(state, res) {
        state.membershipRequestsArray = res;
    },
    UPDATE_MEMBERSHIP_REQUESTS_COUNT(state) {
        state.membershipRequestsCount -= 1;
    },
};

export const actions = {
    async membershipRequestsList({
        commit,
        rootGetters,
    }, req) {
        try {
            const response = await send({
                uri: `membership_requests/${req.id}/`,
                method: 'GET',
            });
            commit('SET_MEMBERSHIP_REQUESTS', response.membership_requests);
        } catch (e) {
            console.log(e);
        }
    },

    async acceptMembershipRequest({
        commit,
        getters,
        rootGetters,
    }, req) {
        try {
            const params = {
                id: req.request_id,
                status: 'ACCEPT',
            };

            const res = await send({
                uri: `membership_request/result/${req.user}/`,
                method: 'PUT',
                payload: params,
            });

            if (res.status === 200) {
                const newMembershipRequestsArray = getters.membershipRequests.filter(item => item.id !== req.request_id);
                const membershipRequestsCount = rootGetters['user/myInfo'].membershipRequestsCount - 1;
                commit('user/UPDATE_MEMBERSHIP_REQUESTS_COUNT', membershipRequestsCount, { root: true });
                commit('SET_MEMBERSHIP_REQUESTS', newMembershipRequestsArray);
            }
        } catch (e) {
            console.log(e);
        }
    },

    async declineMembershipRequest({
        commit,
        getters,
        rootGetters,
    }, req) {
        try {
            const params = {
                id: req.request_id,
                status: 'REJECT',
            };

            const res = await send({
                uri: `membership_request/result/${req.user}/`,
                method: 'PUT',
                payload: params,
            });

            if (res.status === 200) {
                const newMembershipRequestsArray = getters.membershipRequests.filter(item => item.id !== req.request_id);
                const membershipRequestsCount = rootGetters['user/myInfo'].membershipRequestsCount - 1;
                commit('user/UPDATE_MEMBERSHIP_REQUESTS_COUNT', membershipRequestsCount, { root: true });
                commit('SET_MEMBERSHIP_REQUESTS', newMembershipRequestsArray);
            }
        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
