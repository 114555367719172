import Vue from 'vue';
import { send } from '@/global/functions';

export const state = {
    invitesArray: [],
    invitesCount: 0,
    moreInfo: {},
    trigger: true,
};

export const getters = {
    invites: state => state.invitesArray,
    invitesCount: state => state.invitesCount,
    moreInfoGetter: state => state.moreInfo,
};

export const mutations = {
    SHOW_ABOUT_INVITE(state, index) {
        if (state.trigger) {
            state.moreInfo = {};
            Vue.set(state.moreInfo, index, true);
        }
    },
    HIDE_ABOUT_INVITE(state, res) {
        state.trigger = false;
        Vue.set(state.moreInfo, res.index, false);
        setTimeout(() => {
            state.trigger = true;
        });
    },
    SET_INVITES(state, res) {
        state.invitesArray = res;
    },
    UPDATE_INVITES_COUNT(state) {
        state.invitesCount -= 1;
    },
};

export const actions = {
    async invitesList({
        commit,
        rootGetters,
    }, req) {
        try {
            const response = await send({
                uri: `inviting/detail/${req.id}`,
                method: 'GET',
            });
            commit('SET_INVITES', response);
        } catch (e) {
            console.log(e);
        }
    },

    async acceptInvite({
        commit,
        getters,
        rootGetters,
    }, req) {
        try {
            const params = {
                invitation_id: req.invitation_id,
                status: 'accept',
            };

            const res = await send({
                uri: 'inviting/result',
                method: 'POST',
                payload: params,
            });

            if (res.status === 200) {
                const newInvitesArray = getters.invites.filter(item => item.id !== req.invitation_id);
                const invitesCount = 0;
                commit('user/UPDATE_INVITES_COUNT', invitesCount, { root: true });
                commit('SET_INVITES', newInvitesArray);
            }
        } catch (e) {
            console.log(e);
        }
    },

    async declineInvite({
        commit,
        getters,
        rootGetters,
    }, req) {
        try {
            const params = {
                invitation_id: req.invitation_id,
                status: 'reject',
            };

            const res = await send({
                uri: 'inviting/result',
                method: 'POST',
                payload: params,
            });

            if (res.status === 200) {
                const newInvitesArray = getters.invites.filter(item => item.id !== req.invitation_id);
                const invitesCount = rootGetters['user/myInfo'].invitesCount - 1;
                commit('user/UPDATE_INVITES_COUNT', invitesCount, { root: true });
                commit('SET_INVITES', newInvitesArray);
            }
        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
