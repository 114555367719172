<template>
    <div class="current-game_wrapper"></div>
</template>

<script>
export default {
    name: 'GameDetailsView',
};
</script>

<style>

</style>
