<template>
    <div class="confirm-wrapper">
        <div class="modal-inner confirm">
            <div class="modal-inner__title confirm">
                {{ payload.title }}
            </div>
            <div class="flexbox modal-inner__wrapper-buttons">
                <div>
                    <button
                        class="modal-upload-video__wrapper-submit btn"
                        type="button"
                        @click="accept"
                    >
                        {{ payload.buttons.accept }}
                    </button>
                </div>
                <div>
                    <button
                        class="modal-upload-video__wrapper-submit btn danger"
                        type="button"
                        @click="reject"
                    >
                        {{ payload.buttons.reject }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalConfirm',
    props: {
        payload: {
            type: Object,
            default: () => ({
                visible: false,
                title: '',
                buttons: {
                    accept: 'Sample Text',
                    reject: 'Отмена',
                },
            }),
        },
    },
    methods: {
        accept() {
            this.$emit('accept');
        },

        reject() {
            this.$emit('reject');
        },
    },
};
</script>

<style>

</style>
