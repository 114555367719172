<template>
    <div class="profile__rating-wrapper_item">
        <div class="profile__rating-wrapper_title">{{ payload.name }}</div>
        <div class="profile__rating-wrapper_text profile__rating-wrapper_level">
            Вы достигли {{ payload.level }} уровня!
        </div>
        <div class="profile__rating-wrapper_text profile__rating-wrapper_place">
            {{ payload.place_current || 1 }} место
            <span>из {{ payload.place_total || 1 }} игроков</span>
        </div>
        <div class="profile__rating-wrapper-progress">
            <div class="profile__rating-wrapper-progress_top">
                <div class="profile__rating-wrapper-progress_points">
                    {{ Math.floor(payload.score).toFixed() }} очков
                </div>
                <div class="profile__rating-wrapper-progress_procent">
                    <!-- {{
              Math.floor(
                  100 / (payload.points_total / payload.points_current)
              ).toFixed()
          }}% -->

                </div>
            </div>
            <div class="profile__rating-wrapper-progress_bottom">
                <div
                    :style="
                        'width: ' +
                            Math.floor(payload.progress).toFixed() +
                            '%'
                    "
                    class="profile__rating-wrapper-progress_line"
                ></div>
            </div>
        </div>
    <!--        <router-link-->
    <!--            class="profile__rating-wrapper_next"-->
    <!--            :to="'/games/' + payload.link_to_play"-->
    <!--        >Перейти к игре</router-link-->
    <!--        >-->
    </div>
</template>

<script>
export default {
    name: 'ProfileRatingCard',
    props: {
        payload: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
