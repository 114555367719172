<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<template>
    <div class="champ-block">
        <router-link :to="{ name: 'ChampResultInner', params: { id: data.id } }" class="champ-block-link">
            <slot>{{ data.name_game }}</slot>
        </router-link>
    </div>
</template>

<style scoped>
.champ-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 155px;
  border-radius: 5px;
  background-color: #204B6F;
}

.champ-block-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FFF;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  height: 100%;
  width: 100%;
}

@media (max-width: 1000px) {
  .champ-block {
    width: 85%;
  }
}
</style>
