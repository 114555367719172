<template>
    <div v-if="cropper.visible" class="cropper-wrapper" @click.self="cropper.visible = false">
        <div class="cropper-block">
            <cropper
                :aspect-ratio="cropper.ratio"
                :src="cropper.image"
                :stencil-props="{ aspectRatio: cropper.ratio }"
                @change="change"
            />
            <div class="save" @click="updateUserImage">Сохранить</div>
        </div>
    </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import 'vue-advanced-cropper/dist/style.css';

export default {
    name: 'ModalCropper',
    components: {
        Cropper,
    },
    computed: {
        ...mapGetters({
            cropper: 'settings/cropper',
            user: 'user/myInfo',
        }),
    },
    methods: {
        ...mapMutations({
            SAVE_PREVIEW: 'settings/SAVE_PREVIEW',
        }),

        ...mapActions({
            CHANGE_USER_IMAGE: 'settings/CHANGE_USER_IMAGE',
        }),

        change({ canvas }) {
            this.cropper.canvas = canvas;
            this.SAVE_PREVIEW();
        },

        updateUserImage() {
            this.CHANGE_USER_IMAGE({
                id: this.user.id,
                image: this.cropper.imagesBlobs.userinfo,
            });
        },
    },
};
</script>

<style scoped></style>
