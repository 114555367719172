<template>
    <div
        :class="{
            'unavailable': !preparedGame.is_available,
            'finished': !preparedGame.is_active
        }"
        class="games__wrapper-item"
    >
        <button class="content-btn" @click="openLink(payload)">
            <div class="games__wrapper-item_img">
                <img :src="preparedGame.img" alt="">
            </div>
            <div class="games__wrapper-item_info">
                <div class="games__wrapper-item_title">{{ preparedGame.name }}</div>
                <div class="games__wrapper-item_seperator"></div>
                <div class="games__wrapper-item_date">{{ preparedGame.description }}</div>
                <!--                <div class="games__wrapper-item_detailed">{{ preparedGame.inprogress ? 'Продолжить' : 'Перейти в игру' }}</div>-->
            </div>
        </button>
    </div>
</template>

<script>
export default {
    name: 'GameCard',
    props: {
        payload: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        preparedGame() {
            return this.payload;
        },
    },
    methods: {
        openLink(payload) {
            if ((payload.name === 'Business clash' || payload.name === 'Paradase City') && payload.link) {
                alert('Игра находится в разработке');
            } else {
                window.open(payload.link, '_blank');
            }
        },
    },
};
</script>

<style>
.content-btn {
  width: inherit;
  border-radius: 12px;
}
</style>
