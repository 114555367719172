import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import guest from './middleware/guest';
import auth from './middleware/auth';

// views
import AuthView from '@/views/AuthView';
import Authorized from '@/views/Authorized';
import AuthorizedOk from '@/views/AuthorizedOk';
import AccountActivationView from '@/views/AccountActivationView.vue';

import ChampionshipDetailsView from '@/views/ChampionshipDetailsView';
import ChampionshipDescriptionView from '@/components/championship/ChampionshipDescriptionView';
import ChampionshipMembersView from '@/components/championship/ChampionshipMembersView';
import ChampionshipTeamsView from '@/components/championship/ChampionshipTeamsView';
import ChampionshipResultsView from '@/components/championship/ChampionshipResultsView';
import ChampionshipView from '@/views/ChampionshipView';
import GameDetailsView from '@/views/GameDetailsView';
import InvitingView from '@/views/InvitingView';
import ProfileView from '@/views/ProfileView';
import ProfileInfoComponent from '@/components/profile/ProfileInfoComponent.vue';
// import ProfileInfoView from '@/components/profile/ProfileInfoView';
// import ProfileNeuralNetworkView from '@/components/profile/ProfileNeuralNetworkView';
// import ProfileResultsView from '@/components/profile/ProfileResultsView';
import TestResultView from '@/components/profile/TestResultView.vue';
import ChampResultComponent from '@/components/profile/ChampResultComponent.vue';
import ChampResultInnerComponent from '@/components/profile/ChampResultInnerComponent.vue';
import NeuralResultComponent from '@/components/profile/NeuralResultComponent.vue';

// import ProfileChampCard from '@/components/profile/ProfileChampCard';
import ProfileVideoView from '@/components/profile/ProfileVideoView';
import RegisterView from '@/views/RegisterView';
import ResetAccountDetailsView from '@/views/ResetAccountDetailsView';
import ResetAccountView from '@/views/ResetAccountView';
import TestingDetailsView from '@/views/TestingDetailsView';
import TestingView from '@/views/TestingView';
import GamesView from '@/views/GamesView';
import VideosView from '@/views/VideosView';
import ErrorPage from '@/views/ErrorPage';
import ContactsView from '@/views/ContactsView';
import PartnersView from '@/views/PartnersView';
import ChatView from '@/views/ChatView';
import ExpertHomeView from '@/views/expert/ExpertHomeView';
import ExpertProfileView from '@/views/expert/ExpertProfileView';
import ExpertPersonalInformation from '@/views/expert/ExpertPersonalInformation';
import ExpertEvents from '@/views/expert/ExpertEvents';
import ExpertArchiveView from '@/views/expert/ExpertArchiveView';

import LandMain from '@/views/LandMain';
import MessagesView from '@/views/MessagesView.vue';
import MembershipRequestsView from '@/views/MembershipRequestsView.vue';

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'LandMain',
    component: LandMain,
    meta: {
        layout: 'landing',
        // middleware: [guest],
        title: 'Главная страница',
    },
},
{
    path: '/login',
    name: 'login',
    component: AuthView,
    meta: {
        layout: 'auth-layout',
        middleware: [guest],
        title: 'Вход',
    },
},
{
    path: '/authorized-vk',
    name: 'authorized',
    component: Authorized,
    meta: {
        layout: 'auth-layout',
        middleware: [guest],
        title: 'Вошел',
    },
},
{
    path: '/authorized-ok',
    name: 'authorizedOk',
    component: AuthorizedOk,
    meta: {
        layout: 'auth-layout',
        middleware: [guest],
        title: 'Вошел успешно',
    },
},
{
    path: '/register',
    name: 'RegisterView',
    component: RegisterView,
    meta: {
        layout: 'auth-layout',
        title: 'Зарегистрироваться',
    },
},
{
    path: '/activate/:uuid/',
    name: 'AccountActivationView',
    component: AccountActivationView,
},
{
    path: '/reset',
    name: 'ResetAccountView',
    component: ResetAccountView,
    meta: {
        layout: 'auth-layout',
        title: 'Восстановить аккаунт',
    },
},
{
    path: '/profile',
    component: ProfileView,
    meta: {
        middleware: [auth],
        title: 'Профиль',
    },
    children: [{
        path: '',
        name: 'ProfileInfoView',
        component: ProfileInfoComponent,
        meta: {
            middleware: [auth],
            title: 'Мои данные',
        },
    },
    {
        path: 'results',
        name: 'testResults',
        component: TestResultView,
        meta: {
            middleware: [auth],
            title: 'Результаты тестов',
        },
    },
    {
        path: 'video',
        name: 'ProfileVideoView',
        component: ProfileVideoView,
        meta: {
            middleware: [auth],
            title: 'Мои видео',
        },
    },
    {
        path: 'champ',
        name: 'ChampResult',
        component: ChampResultComponent,
        meta: {
            middleware: [auth],
            title: 'Результаты чемпионатов',
        },
    },
    {
        path: 'champ/:id',
        name: 'ChampResultInner',
        component: ChampResultInnerComponent,
        meta: {
            middleware: [auth],
            title: 'Результаты чемпионата',
        },
    },
    {
        name: 'NeuralResultComponent',
        path: 'neural_network',
        component: NeuralResultComponent,
        meta: {
            middleware: [auth],
            title: 'Результаты нейронки',
        },
    },
        // {
        //     path: 'сhampInner',
        //     name: 'ChampResultInnerComponent',
        //     component: ChampResultInnerComponent,
        //     meta: {
        //         middleware: [auth],
        //         title: 'Результаты чемпионата',
        //     },
        // },
    ],
},
{
    path: '/inviting',
    name: 'InvitingView',
    component: InvitingView,
    meta: {
        middleware: [auth],
        title: 'Приглашение',
    },
},
{
    path: '/messages',
    name: 'MessagesView',
    component: MessagesView,
    meta: {
        middleware: [auth],
        title: 'Сообщения',
    },
},
{
    path: '/membership_request',
    name: 'MembershipRequestsView',
    component: MembershipRequestsView,
    meta: {
        middleware: [auth],
        title: 'Запрос на членство',
    },
},
{
    path: '/reset/:uuid/:token',
    name: 'ResetAccountDetailsView',
    component: ResetAccountDetailsView,
    meta: {
        layout: 'auth-layout',
        title: 'Восстановление аккаунта',
    },
},
{
    path: '/testing',
    name: 'TestingView',
    component: TestingView,
    meta: {
        middleware: [auth],
        title: 'Тестирование',
    },
},
{
    path: '/testing/:testId',
    name: 'TestingDetailsView',
    component: TestingDetailsView,
    meta: {
        middleware: [auth],
        title: 'Детали тестирования',
    },
},
{
    path: '/championship',
    name: 'ChampionshipView',
    component: ChampionshipView,
    meta: {
        middleware: [auth],
        title: 'Чемпионат',
    },
},
{
    path: '/championship/:championshipId',
    component: ChampionshipDetailsView,
    meta: {
        middleware: [auth],
        title: 'Детали чемпионата',
    },
    children: [{
        path: 'description',
        name: 'ChampionshipDescriptionView',
        component: ChampionshipDescriptionView,
        meta: {
            middleware: [auth],
            title: 'Описание чемпионата',
        },
    },
    {
        path: 'members',
        name: 'ChampionshipMembersView',
        component: ChampionshipMembersView,
        meta: {
            middleware: [auth],
            title: 'Участники чемпионата',
        },
    },
    {
        path: 'teams',
        name: 'ChampionshipTeamsView',
        component: ChampionshipTeamsView,
        meta: {
            middleware: [auth],
            title: 'Команды чемпионата',
        },
    },
    {
        path: 'results',
        name: 'ChampionshipResultsView',
        component: ChampionshipResultsView,
        meta: {
            middleware: [auth],
            title: 'Результаты чемпионата',
        },
    },
    ],
},
{
    path: '/games',
    name: 'GamesView',
    component: GamesView,
    meta: {
        middleware: [auth],
        title: 'Игры',
    },
},
{
    path: '/games:gameId',
    name: 'GameDetailsView',
    component: GameDetailsView,
    meta: {
        middleware: [auth],
        title: 'Детали игр',
    },
},
{
    path: '/videos',
    name: 'VideosView',
    component: VideosView,
    meta: {
        middleware: [auth],
        title: 'Видео',
    },
},
{
    path: '/partners',
    name: 'PartnersView',
    component: PartnersView,
    meta: {
        middleware: [auth],
        title: 'Партнеры',
    },
},
{
    path: '/contacts',
    name: 'ContactsView',
    component: ContactsView,
    meta: {
        title: 'Контакты',
    },
},
{
    path: '/chat',
    name: 'ChatView',
    component: ChatView,
    meta: {
        middleware: [auth],
        title: 'Чат',
    },
},
{
    path: '*',
    name: '404',
},
{
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: {
        layout: 'error-layout',
        title: 'Ошибка',
    },
},
{
    path: '/expert',
    name: 'ExpertHomeView',
    component: ExpertHomeView,
    meta: {
        layout: 'landing',
        // middleware: [guest],
        title: 'Эксперт',
    },
},
{
    path: '/expert/profile',
    name: 'ExpertProfileView',
    component: ExpertProfileView,
    meta: {
        layout: 'landing',
        title: 'Профиль',
    },
    children: [{
        path: 'personal-information',
        name: 'ExpertPersonalInformation',
        component: ExpertPersonalInformation,
        meta: {
            layout: 'landing',
            title: 'Персональная информация',
        },
    },
    {
        path: 'events',
        name: 'ExpertEvents',
        component: ExpertEvents,
        meta: {
            layout: 'landing',
            title: 'Мероприятия',
        },
    },
    {
        path: 'archive',
        name: 'ExpertArchiveView',
        component: ExpertArchiveView,
        meta: {
            layout: 'landing',
            title: 'Архив',
        },
    },
    ],
},
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'active-orange',
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            };
        }
        return {
            x: 0,
            y: 0,
        };
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title + ' - Business up';
    } else {
        document.title = 'Business up';
    }

    if (!to.meta.middleware || to.meta.middleware.length === 0) {
        return next();
    }

    const { middleware } = to.meta;
    const context = { to, from, next, store };

    const nextMiddleware = index => {
        const subsequentMiddleware = middleware[index];
        if (!subsequentMiddleware) return next();

        return subsequentMiddleware({
            ...context,
            next: () => nextMiddleware(index + 1),
        });
    };

    return nextMiddleware(0);
});

export default router;
