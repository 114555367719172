<template>
    <header :class="{'header-mobile': isOpen}" class="header without-background">
        <div class="container">
            <router-link class="header__logo" to="/profile">
                <img alt="" src="@/assets/assetsland/img/logo.svg"/>
            </router-link>
            <nav class="header__menu">
                <ul>
                    <li class="header__list"><a href="#preview" @click="toggleHeaderMenu">Анонсы</a></li>
                    <li class="header__list"><a href="#new-game" @click="toggleHeaderMenu">Новые игры</a></li>
                    <li class="header__list"><a href="#news" @click="toggleHeaderMenu">Новости</a></li>
                    <!--                    <li class="header__list"><a @click="toggleHeaderMenu" href="#products">Сувениры</a></li>-->
                    <li class="header__list"><a href="#videos" @click="toggleHeaderMenu">Видео</a></li>
                    <li class="header__list">
                        <router-link to="contacts">Контакты</router-link>
                    </li>
                    <!--                    <li class="header__list"><a @click="toggleHeaderMenu" href="#">Донаты</a></li>-->
                </ul>
            </nav>
            <button class="bar" @click="isOpen = !isOpen">
                <div class="bar__item"></div>
                <div class="bar__item"></div>
                <div class="bar__item"></div>
            </button>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleHeaderMenu(e) {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>
