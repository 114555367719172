<script>
import EventsBlockComponent from '@/views/expert/EventsBlockComponent.vue';
import { send } from '../../global/functions';

export default {
    components: {
        EventsBlockComponent,
    },
    data: () => ({
        eventData: [],
        searchName: '',
        searchDate: '',
    }),
    methods: {
        async getArchiveEvents() {
            const res = await send({
                uri: 'archive-championships-expert',
                method: 'GET',
            });
            this.eventData = res;
        },
        clear() {
            this.searchName = '';
            this.searchDate = '';
        },
    },
    async created() {
        this.getArchiveEvents();
    },
    computed: {
        filteredEvents() {
            return this.eventData.filter(eventData => {
                return eventData.name.toLowerCase()
                    .includes(this.searchName.toLowerCase()) &&
            eventData.date_start.toLowerCase()
                .includes(this.searchDate.toLowerCase());
            });
        },
    },
};
</script>

<template>
    <div class="main-archive">
        <div class="acrhive-top-buttons">
            <div class="srch-block">
                <input v-model="searchName" placeholder="Поиск по названию" type="text">
            </div>
            <div class="srch-block">
                <input v-model="searchDate" placeholder="Поиск по дате" type="text">
            </div>
            <button class="not-filled" @click="clear()"><p>Очистить фильтры</p></button>
        </div>
        <div class="event-block-main">
            <div class="events-blocks">
                <EventsBlockComponent
                    v-for="(event, i) in filteredEvents"
                    :key="i"
                    :archive="true"
                    :count="i"
                    :eventActive="event.is_active"
                    :eventCommands="event.count_commands"
                    :eventCreateDate="event.date_create"
                    :eventDescription="event.description"
                    :eventFinishDate="event.date_finish"
                    :eventImage="event.img"
                    :eventMembers="event.count_members"
                    :eventName="event.name"
                    :eventRestriction="event.command_restriction"
                    :eventScore="event.passing_score"
                    :eventStartDate="event.date_start"
                    :eventStatus="event.status"
                ></EventsBlockComponent>
            </div>
        </div>
    </div>
</template>

<style>
.acrhive-top-buttons {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  gap: 50px;
}

.acrhive-top-buttons input {
  color: #FFFFFF;
  background: none;
  font-size: 17px;
}

.acrhive-top-buttons p {
  color: #FFFFFF;
  font-size: 17px;
}

.srch-block {
  background: #ffffff12;
  min-width: 300px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-filled {
  min-width: 180px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.events-blocks {
  display: flex;
  margin-top: 60px;
  justify-content: center;
}

@media (max-width: 1100px) {
  .acrhive-top-buttons {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

@media (max-width: 1050px) {
  .events-blocks {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }
}
</style>
