<template>
    <div class="champion-detail__content-participant">
        <div class="champion-detail__content-participant_img" @click="aboutMember(payload.id)">
            <img :src="payload.img" alt=""/>
        </div>
        <div class="champion-detail__content-participant_info">
            <div class="champion-detail__content-participant_title">{{ payload.login || 'some name' }}</div>
            <div class="champion-detail__content-participant_text">
                <span v-if="payload.second_name">{{ payload.second_name }}</span>
                <span v-if="payload.second_name && payload.city">, </span>
                <span v-if="payload.city">{{ payload.city }}</span>
            </div>
        </div>
        <button
            v-if="canBeInvited && teamLeader"
            class="champion-detail__content-participant_invite"
            @click="inviteMember(payload.id)">
            Пригласить
        </button>
        <div v-if="isLeader" class="champion-detail__content-participant_lider">
            <img alt="" src="@/assets/images/lider-icon.svg"/>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
    name: 'ChampionshipMemberCard',
    props: {
        payload: {
            type: Object,
            default: () => ({}),
        },
        teamLeader: {
            type: [Number, Object],
            default: null,
        },
        participate: {
            type: Boolean,
            default: false,
        },
        single: {
            type: Boolean,
            default: false,
        },
        isMy: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
            userVirtonomica: 'user/userVirtonomica',
            teams: 'championship/championshipTeams',
            currentTeam: 'championship/currentTeam',
            inviting: 'championship/inviting',
        }),

        isLeader() {
            return this.teamLeader === this.payload.id;
        },
        canBeInvited() {
            return !this.participate && !this.isMy && !this.isUserInvited;
        },
        checkedTeam() {
            return this.teams.find(item => item.main_user === this.userId);
        },
        isUserInvited() {
            return Boolean(this.checkedTeam?.invited_members?.find(item => item === this?.inviting.user_id || item === this.payload.id));
        },
    },
    methods: {
        ...mapMutations({
            SET_INVITED_USER: 'championship/SET_INVITED_USER',
            CONTENT_IS_LOADED: 'settings/CONTENT_IS_LOADED',
        }),

        ...mapActions({
            toggleModals: 'settings/toggleModals',
            getUserInfo: 'user/getUserInfo',
            // loadAchievements: 'user/loadAchievements',
            getChampionshipTeamsDetails: 'championship/getChampionshipTeamsDetails',
        }),

        inviteMember(id) {
            this.SET_INVITED_USER(id);
            this.toggleModal('members');
        },

        toggleModal(type) {
            this.toggleModals({
                parent: 'championship',
                children: type,
            });
        },

        async aboutMember(id) {
            this.CONTENT_IS_LOADED(false);

            this.toggleModal('userinfo');

            await this.getUserInfo({ id });
            // await this.loadAchievements({ id: this.userVirtonomica?.game_id ?? 0, isMy: false });

            this.CONTENT_IS_LOADED(true);
        },
    },
};
</script>
