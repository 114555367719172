<template>
    <div class="error__container">
        <div class="error__container-title">Ой, что-то пошло не так! :(</div>
        <div class="error__container-code">Код ошибки: <span>{{ errorCode }}</span></div>
        <div class="error__container-about">({{ aboutError }})</div>
        <div class="error__container-back" @click="toBack">Вернуться на главную</div>
    </div>
</template>

<script>
export default {
    name: 'ErrorPage',
    computed: {
        errorCode() {
            return this.$route.params.errorCode || 500;
        },

        aboutError() {
            return this.$route.params.errorAbout || 'Internal Server Error';
        },
    },
    methods: {
        toBack() {
            location.href = '/';
        },
    },
};
</script>

<style lang="scss">
.error {
  &__container {
    margin: auto;
    text-align: center;

    &-title {
      font-size: 26px;
      margin-bottom: 15px;
    }

    &-code {
      font-size: 18px;
      margin-bottom: 15px;

      span {
        font-weight: bolder;
        font-size: 20px;
      }
    }

    &-about {
      font-size: 14px;
      margin-bottom: 40px;
    }

    &-back {
      cursor: pointer;
      padding: 12px 10px;
      border-radius: 5px;
      background: #e3e3e3;
      color: gray;
      transition: 0.2s all;

      &:hover {
        color: #3e3e3e;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
