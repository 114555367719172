<script>
import EventsBlockComponent from '@/views/expert/EventsBlockComponent.vue';
import EventStatusBarComponent from '@/views/expert/EventStatusBarComponent.vue';
import EventIndexBarComponent from '@/views/expert/EventIndexBarComponent.vue';
import { send } from '../../global/functions';

export default {
    components: {
        EventsBlockComponent,
        EventStatusBarComponent,
        EventIndexBarComponent,
    },
    data: () => ({
        eventData: [],
        eventMembers: [],
        initialIndicators: [],
        indicators: [],
        champId: null,
        showExpertise: false,
        showIndicators: false,
        showModal: false,
        finishIndexing: true,
        indicatorsLoading: false,
    }),
    watch: {
        showIndicators(val) {
            if (!val) {
                this.indicators = [];
                this.initialIndicators = [];
            }
        },
    },
    methods: {
        downloadReport() {
            const element = document.createElement('a');
            console.log(this.eventMembers);
            element.setAttribute('href', process.env.VUE_APP_BASE_API_URL + this.eventMembers[0].report[0].report);
            element.setAttribute('target', '_blank');
            element.setAttribute('download', 'report.txt');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        async getActiveEvents() {
            const res = await send({
                uri: 'active-championships-expert',
                method: 'GET',
            });
            this.eventData = res;
        },
        async getTeamsOfChampionship(id) {
            const res = await send({
                uri: 'api/members-list-expert/' + id,
                method: 'GET',
            });
            this.eventMembers = res;
            this.showExpertise = true;
        },
        async getAllIndicatorsOfUser(userId) {
            try {
                this.indicatorsLoading = true;
                const res = await send({
                    uri: 'api/user/' + userId + '/' + this.champId,
                    method: 'GET',
                });
                this.initialIndicators = JSON.parse(JSON.stringify(res));
                this.indicators = res;
            } catch (e) {
                console.error(e);
            }

            this.indicatorsLoading = false;
        },
        async getAllIndicatorsOfTeam(teamId) {
            try {
                this.indicatorsLoading = true;
                const res = await send({
                    uri: 'api/commands/' + teamId + '/' + this.champId,
                    method: 'GET',
                });
                this.initialIndicators = JSON.parse(JSON.stringify(res));
                this.indicators = res;
            } catch (e) {
                console.error(e);
            }

            this.indicatorsLoading = false;
        },
        selectEvent(id) {
            this.getTeamsOfChampionship(id);
            this.champId = id;
            this.showIndicators = false;
        },
        selectIndicator(memberId, isTeam = false) {
            this.showIndicators = true;
            if (isTeam) {
                this.getAllIndicatorsOfTeam(memberId);
            } else {
                this.getAllIndicatorsOfUser(memberId);
            }
        },
        changeIndicator({
            id,
            number,
        }) {
            const itemIndex = this.indicators.findIndex(indicator => indicator.id === id);
            this.indicators[itemIndex].rating_scale = number;
        },
        clearIndicators() {
            this.indicators = this.indicators.map(indicator => {
                indicator.rating_scale = 0;
                return indicator;
            });
        },
        async submitIndicators() {
            try {
                let foundZeroRating = false;
                const indicators = this.indicators.map(({
                    id,
                    rating_scale,
                }) => {
                    if (rating_scale === 0 && !foundZeroRating) {
                        this.showModal = true;
                        foundZeroRating = true;
                    }
                    return {
                        id,
                        rating_scale,
                    };
                });

                if (foundZeroRating) {
                    return;
                }

                await send({
                    uri: 'assess_indicators/',
                    method: 'PUT',
                    payload: {
                        indicators,
                    },
                });

                this.showIndicators = false;
            } catch (e) {
                console.log(e);
            }
        },
    },
    async created() {
        this.getActiveEvents();
    },
};
</script>

<template>
    <div class="main-event">
        <!-- мероприятия -->
        <div class="event-block-main">
            <div class="events-blocks">
                <EventsBlockComponent
                    v-for="(event, i) in eventData"
                    :key="i"
                    :count="i"
                    :eventActive="event.is_active"
                    :eventCommands="event.count_commands"
                    :eventCreateDate="event.date_create"
                    :eventDescription="event.description"
                    :eventFinishDate="event.date_finish"
                    :eventImage="event.img"
                    :eventMembers="event.count_members"
                    :eventName="event.name"
                    :eventRestriction="event.command_restriction"
                    :eventScore="event.passing_score"
                    :eventStartDate="event.date_start"
                    :eventStatus="event.status"
                    @click="selectEvent(event.id)"></EventsBlockComponent>
            </div>
        </div>

        <!-- участники -->
        <div v-if="showExpertise && !showIndicators" class="member-container">
            <div class="col">
                <div class="download-events">
                    <button @click="downloadReport">Скачать файл</button>
                </div>
                <div v-for="(eventMember, i) in eventMembers" :key="'eei-' + i" class="event-bars">
                    <EventStatusBarComponent
                        v-for="(member, m) in eventMember.member"
                        :key="m"
                        :indicator="eventMember.indicator"
                        :max-total="eventMember.max_total"
                        :member="member"
                        @click="selectIndicator(member.id)">
                        {{ member.second_name }}
                    </EventStatusBarComponent>
                    <EventStatusBarComponent
                        v-for="(command, c) in eventMember.command"
                        :key="'cm-' + c"
                        :indicator="eventMember.indicator"
                        :max-total="eventMember.max_total"
                        :member="command"
                        @click="selectIndicator(command.id, true)">
                        {{ command.name }}
                    </EventStatusBarComponent>
                </div>
            </div>
            <div class="col">
                <h3>Общий комментарий</h3>
                <div v-for="(eventMember, i) in eventMembers" :key="'cei-' + i" class="comment-block">
                    {{ eventMember.comment }}
                </div>
            </div>
        </div>

        <!-- оценка -->
        <div v-if="showIndicators && indicators.length > 0" class="index-block">
            <!-- <div class="text-btns">
          <div class="index-info">
              <p>{{  }}</p>
          </div>
          <div class="main-commentary">
              <button>Заключение</button>
          </div>
      </div> -->
            <div class="index-bars">
                <EventIndexBarComponent
                    v-for="(indicator, i) in indicators"
                    :key="'in-' + i"
                    :indicator="indicator"
                    @change="changeIndicator"/>
            </div>
            <div class="bottom-btns">
                <div class="clear-index">
                    <button @click="clearIndicators">Очистить показатели</button>
                </div>
                <div class="index-end">
                    <button @click="submitIndicators">Завершить</button>
                </div>
            </div>
            <div v-if="showModal" class="index-modal">
                <div class="index-modal-window">
                    <div v-if="finishIndexing" class="close-modal" @click="showModal = false">
                        <img alt="" src="@/assets/images/close.png">
                    </div>
                    <p>Завершите оценку</p>
                </div>
            </div>
        </div>

        <div v-if="showIndicators && indicators.length <= 0" class="index-block">
            <p v-if="!indicatorsLoading" class="info">
                Пока что нет данных
            </p>
            <p v-else class="info">
                Загрузка...
            </p>
        </div>
    </div>
</template>

<style scoped>
.event-block-main {
  display: flex;
  justify-content: center;
}

.events-blocks {
  display: flex;
  width: 86%;
  gap: 25px;
  justify-content: center;
  margin-top: 40px;
}

.event-bars {
  margin-top: 20px;
}

.text-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 45px;
}

.text-btns button {
  font-size: 22.5px;
  font-weight: lighter;
}

.download-events button {
  font-size: 22.5px;
  font-weight: lighter;
  color: #FF7F00;
}

.main-commentary button {
  color: #fff;
}

.bottom-event {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.comment-block {
  width: 100%;
  min-height: 100px;
  float: left;
  padding: 15px;
  margin-top: 20px;
  border: 2px solid #D9D9D980;
  background: #0D2144B2;
  color: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 20px;
}

.index-info p {
  color: #fff;
  font-size: 22.5px;
  font-weight: bold;
}

.bottom-btns {
  display: flex;
  width: 55%;
  justify-content: space-around;
  margin-top: 30px;
}

.clear-index button {
  color: #fff;
  font-size: 20px;
}

.index-end button {
  color: #fff;
  font-size: 20px;
}

.index-bars {
  margin-left: 6%;
  margin-top: 20px;
}

.index-modal {
  display: flex;
  justify-content: center;
  width: 55%;
}

.index-modal-window {
  background-color: #FF7F00;
  width: 280px;
  height: 90px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-modal-window p {
  color: #ffffff;
  font-size: 22.5px;
  font-weight: bold;
}

.close-modal img {
  position: absolute;
  top: 12.5px;
  right: 12.5px;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.info {
  text-align: center;
  padding: 20px;
  color: #fff;
  font-size: 28px;
}

.member-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.member-container .col {
  padding: 10px;
  margin: 0 20px;
  color: #fff;
}

.member-container .col h3 {
  text-align: center;
}
</style>
