<template>
    <div v-if="searchedVideos" id="videos" class="container">
        <div class="videos__title">
            <div class="title">Видео</div>
            <!-- <div class="videos__title-addvideo" @click="toggleModal('video')">Загрузить видео</div> -->
        </div>
        <!--        <div class="videos__search">-->
        <!--            <div class="videos__search-ico">-->
        <!--                <img src="@/assets/images/common/search.svg">-->
        <!--            </div>-->
        <!--            <div class="videos__search-input">-->
        <!--                <input type="text" v-model="search" placeholder="Поиск видео по наименованию и описанию">-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="profile__content-item">
            <div v-if="isLoading" class="profile__videos">
                <SkeletonCard
                    v-for="item in 6"
                    :key="item"
                    primary="#e3e3e3"
                    structure="700132"/>
            </div>
            <div v-else-if="searchedVideos.length" class="profile__videos">
                <VideoCard
                    v-for="(item, itemIndex) in searchedVideos"
                    :key="itemIndex"
                    :isEdit="video.isEdit"
                    :payload="item"
                />
            </div>
            <div v-else class="container"><h3>No Videos Found</h3></div>
        </div>

        <transition name="fade">
            <ModalUploadVideo v-show="modals.videos.video"/>
        </transition>
    </div>
</template>

<script>
import { REGEX_CODE_YT } from '@/global/constants';
import { mapGetters, mapActions } from 'vuex';
import VideoCard from '@/components/videos/VideoCard';

import ModalUploadVideo from '@/components/modals/videos/ModalUploadVideo';

export default {
    name: 'ProfileVideoView',
    components: {
        VideoCard,
        ModalUploadVideo,
    },
    data() {
        return {
            isLoading: false,
            isAdmin: false,
            search: '',
            array: [],
        };
    },
    computed: {
        ...mapGetters({
            modals: 'settings/modals',
            video: 'videos/video',
        }),

        preparedVideos() {
            const filteredVideos = this.video.list.map(item => {
                const url = item.url.match(REGEX_CODE_YT) ?? [];
                return {
                    ...item,
                    url: url[1] || null,
                };
            });
            return filteredVideos ?? [];
        },
        searchedVideos() {
            const filteredVideos = this.preparedVideos.filter(
                item =>
                    item.name.toLowerCase()
                        .includes(this.search.toLowerCase()) ||
              item.description.toLowerCase()
                  .includes(this.search.toLowerCase())
            );
            return filteredVideos.length ? filteredVideos : [];
        },
    },
    mounted() {
        this.loadVideos();
    },
    methods: {
        ...mapActions({
            getVideos: 'videos/getVideos',
            toggleModals: 'settings/toggleModals',
        }),

        async loadVideos() {
            this.isLoading = true;

            const res = await this.getVideos();

            if (res) {
                this.isLoading = false;
            }
        },

        toggleModal(children) {
            this.toggleModals({
                parent: 'videos',
                children,
            });
        },
    },
};
</script>

<style>

</style>
