import jwt_decode from 'jwt-decode';

const state = {
    accessToken: localStorage.getItem('token-access') || null,
};

const getters = {
    accessToken: state => state.accessToken,
    convertJWT: state => {
        const token = jwt_decode(state.accessToken);
        return (state.accessToken = token);
    },
};

const mutations = {
    CHANGE_TOKEN(state, payload) {
        state.accessToken = payload;
    },
};

const actions = {
    async changeTokenAction({ commit }, req) {
        commit('CHANGE_TOKEN', req);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
