<template>
    <div class="champion-detail__content">
        <div class="champion-detail__content-comands">
            <div class="champion-detail__content-comands_left">
                <div class="videos__search">
                    <div class="videos__search-ico">
                        <img src="@/assets/images/common/search.svg">
                    </div>
                    <div class="videos__search-input">
                        <input v-model="search" placeholder="Поиск команды по названию" type="text">
                    </div>
                </div>
                <div v-if="isLoadingTeams" class="champion-detail__content-comands_wrapper">
                    <SkeletonCard
                        v-for="item in 8"
                        :key="item"
                        primary="#e3e3e3"
                        structure="132"/>
                </div>
                <div v-else class="champion-detail__content-comands_wrapper">
                    <CommandCard
                        v-for="(team, teamIndex) in preparedTeamList"
                        :key="team.id"
                        :index="teamIndex"
                        :isActiveTab="teamIndex === teamTabIndex"
                        :payload="team"
                        @getInfo="getTeamInfo"
                    />
                </div>
                <button
                    v-if="!isUserHaveCommand && isUserParticipates && !hasTeamACaption"
                    class="champion-detail__content-comands_create"
                    @click="toggleModal">
                    Добавить команду
                </button>
            </div>
            <div v-show="preparedTeamList && preparedTeamList.length" class="champion-detail__content-comands_right">
                <div
                    v-show="!isLoadingMembers && currentTeam.members && currentTeam.members.length"
                    class="champion-detail__content-comands_title subtitle">
                    Список участников "{{ currentTeam.name }}"
                </div>
                <div
                    v-show="currentTeam.members && !currentTeam.members.length && !isLoadingMembers"
                    class="champion-detail__content-participants--empty">нет участников
                </div>
                <div v-if="isLoadingMembers" class="champion-detail__content-participants--single">
                    <SkeletonCard
                        v-for="item in 8"
                        :key="item"
                        primary="#e3e3e3"
                        structure="1"/>
                </div>
                <div v-else class="champion-detail__content-participants--single">
                    <MemberCard
                        v-for="(member, memberId) in currentTeam.members"
                        :key="memberId"
                        :participate="true"
                        :payload="member"
                        :teamLeader="currentTeam.main_user"
                    />
                    <button
                        v-if="currentTeam.main_user === this.user.id"
                        class="champion-detail__content-comands_create"
                        @click="deleteTeam">
                        Удалить команду
                    </button>
                    <button
                        v-if="canUserLeaveTeam && currentTeam.main_user !== this.user.id"
                        class="champion-detail__content-comands_create"
                        @click="leaveTeam">
                        Выйти из команды
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import MemberCard from '@/components/championship/MemberCard';
import CommandCard from '@/components/championship/CommandCard.vue';

export default {
    name: 'ChampionshipTeamsView',
    components: {
        MemberCard,
        CommandCard,
    },
    data() {
        return {
            isLoadingTeams: false,
            isLoadingMembers: false,
            search: '',
        };
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
            user: 'user/myInfo',
            teams: 'championship/championshipTeams',
            members: 'championship/championshipMembers',
            currentTeam: 'championship/currentTeam',
            currentChampionship: 'championship/currentChampionship',
            isUserHaveCommand: 'championship/isUserHaveCommand',
            isUserParticipates: 'championship/isUserParticipates',
            teamTabIndex: 'championship/teamTabIndex',
        }),

        preparedTeamList() {
            return this.teams.filter(item => item.name.toLowerCase()
                .includes(this.search.toLowerCase()));
        },

        preparedMembersList() {
            return this.members;
        },

        canUserLeaveTeam() {
            return this.isUserParticipates && this.isUserHaveCommand && this.alreadyIsOwnTeam;
        },

        alreadyIsOwnTeam() {
            return this.currentTeam.members?.some(item => item.id === this.user.id);
        },

        championshipId() {
            return this.currentChampionship.id ?? null;
        },
        hasTeamACaption() {
            return this.teams.find(item => item.main_user === this.userId);
        },
    },
    watch: {
        isLoadingMembers() {
            if (!this.isLoadingMembers) {
                this.$emit('loaded');
            }
        },
    },
    async created() {
        localStorage.setItem('championshipTabIndex', 1);

        await this.championshipDetails();

        await this.getInviteToChampionship({
            id_user: this.userId,
            id_championship: this.championshipId,
        });
        await this.getUserHasCommand({
            id_user: this.userId,
            id_championship: this.championshipId,
        });
    },
    methods: {
        ...mapActions({
            getTeamMembersDetails: 'championship/getTeamMembersDetails',
            getChampionshipTeamsDetails: 'championship/getChampionshipTeamsDetails',
            toggleModals: 'settings/toggleModals',
            getInviteToChampionship: 'championship/getInviteToChampionship',
            getUserHasCommand: 'championship/getUserHasCommand',
            setLeaveTeam: 'championship/setLeaveTeam',
            loadCities: 'user/loadCities',
            deleteCommandForChampionship: 'championship/deleteCommandForChampionship',
        }),

        ...mapMutations({
            SET_TEAM_TAB_INDEX: 'championship/SET_TEAM_TAB_INDEX',
            SET_CURRENT_TEAM: 'championship/SET_CURRENT_TEAM',
        }),

        async championshipDetails() {
            this.isLoadingTeams = true;
            this.isLoadingMembers = true;

            await this.getChampionshipTeamsDetails({
                id_championship: this.championshipId,
                wait: !!this.currentChampionship.id,
            })
                .then(item => {
                    this.isLoadingTeams = false;
                    if (item.length) {
                        return this.getTeamMembersDetails({ teamId: this.teams[0].id });
                    }
                    this.SET_CURRENT_TEAM({
                        id: 0,
                        members: [],
                    });
                })
                .then(() => {
                    this.isLoadingMembers = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async getTeamInfo(payload) {
            this.SET_TEAM_TAB_INDEX(payload.index);

            this.isLoadingMembers = true;

            const res = await this.getTeamMembersDetails({
                teamId: payload.id,
                wait: true,
            });

            if (res) {
                this.isLoadingMembers = false;
            }
        },

        leaveTeam() {
            const params = {
                id_team: this.currentTeam.id,
                id_user: this.userId,
            };

            this.setLeaveTeam(params);
        },
        deleteTeam() {
            const params = {
                id_team: this.currentTeam.id,
            };
            this.deleteCommandForChampionship((params));
            this.championshipDetails();
        },

        toggleModal() {
            this.toggleModals({
                parent: 'championship',
                children: 'teams',
            });
            this.loadCities();
        },
    },
};
</script>
