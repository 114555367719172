<template>
    <div class="modal-add-comand">
        <button class="modal-add-comand__close" @click="toggleModal">
            <img alt="" src="@/assets/images/close-icon.svg"/>
        </button>
        <div class="container">
            <div class="modal-add-comand__title">Заявка в команду</div>
            <form action="#" class="modal-add-comand__wrapper" @submit.prevent="handleSubmit">
                <div class="modal-add-comand__wrapper-input">
                    <span>Название команды</span>
                    <input :disabled="!!teamData.id" :value='teamData.name'/>
                </div>
                <div class="modal-add-comand__wrapper-input">
                    <span>Обо мне*</span>
                    <textarea v-model.trim="about_me" placeholder="Письмо"></textarea>
                </div>

                <button
                    :disabled="about_me < 2"
                    class="modal-add-comand__wrapper-submit btn"
                    type="button"
                    @click="requestToTeam">Заявка в команду
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapMutations
} from 'vuex';
import {
    data
} from 'browserslist';
import {
    minLength,
    required
} from 'vuelidate/lib/validators';

export default {
    name: 'ModalMembershipRequest',
    props: {
        teamData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            teamId: null,
            about_me: '',
            description: '',
            filteredTeamName: [],
            teamAndNomId: null,
        };
    },
    computed: {
        data() {
            return data;
        },
        ...mapGetters({
            championship: 'championship/currentChampionship',
            teams: 'championship/championshipTeams',
            user: 'user/userId',
            currentChampionship: 'championship/currentChampionship',
        }),

        championshipId() {
            return this.championship.id ?? null;
        },
    },
    async created() {
        await this.teamList({
            id_championship: this.championship.id,
        });
        if (this.teams) {
            this.teams.forEach(item => {
                this.teamId = item.id;
                this.filteredTeamName = item.name;
            });
        }
    },
    methods: {
        ...mapMutations({
            SET_INVITED_USER: 'championship/SET_INVITED_USER',
        }),
        ...mapActions({
            getNominationsList: 'championship/getNominationsList',
            teamList: 'championship/getChampionshipTeamsDetails',
            requestMembership: 'user/requestMembership',
            toggleModals: 'settings/toggleModals',
            getTeamMembersDetails: 'championship/getTeamMembersDetails',
        }),

        async handleSubmit() {
            try {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    await this.requestToTeam();
                }
            } catch (err) {
                alert(err);
            }
        },

        toggleModal() {
            this.$emit('close');
        },

        async requestToTeam() {
            const data = {
                user: this.user,
                team: this.teamData.id,
                about_me: this.about_me,
            };
            this.toggleModal();
            await this.requestMembership(data);
            await this.updateTeamData();
        },
        async updateTeamData() {
            await this.teamList({
                id_championship: this.championshipId,
                wait: !!this.currentChampionship.id,
            });
        },
    },
    validations: {
        about_me: {
            required,
            minLength: minLength(1),
        },
    },
};
</script>
<style>
.modal-add-command {
  z-index: 100;
}

.modal-add-comand__title {
  color: #000000;
}

.modal-add-comand__wrapper-input {
  color: #000000;
  opacity: 0.5;
}
</style>
