<script>
import LevelBlockComponent from '@/components/profile/LevelBlockComponent.vue';
import DiagramBlockComponent from '@/components/profile/DiagramBlockComponent.vue';
import OrangeVersionLevelBlockComponent from '@/components/profile/OrangeVersionLevelBlockComponent.vue';

import {
    mapActions,
    mapGetters,
    mapMutations
} from 'vuex';

export default ({
    components: {
        DiagramBlockComponent,
        OrangeVersionLevelBlockComponent,
        LevelBlockComponent,
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
            date: 'user/convertedDate',
            userData: 'user/myInfo',
        }),
        genderInRussian() {
            const {
                gender,
            } = this.userData.profile_filed;
            if (gender === 'male') {
                return 'Мужской';
            } else if (gender === 'female') {
                return 'Женский';
            }

            return 'Неизвестный';
        },
        city() {
            return this.userData.profile_filed.city.name === undefined || !this.userData.profile_filed.city
                .name
                ? this.userData.profile_filed.city
                : this.userData.profile_filed.city.name;
        },
    },
    methods: {
        ...mapMutations({
            CHANGE_CROPPER_IMAGE: 'settings/CHANGE_CROPPER_IMAGE',
        }),

        ...mapActions({
            loadCities: 'user/loadCities',
            toggleModals: 'settings/toggleModals',
            getUserData: 'user/getUserData',
        }),

        toggleModal(children) {
            this.toggleModals({
                parent: 'profile',
                children,
            });
        },
    },
});
</script>

<template>
    <div>
        <div class="main">
            <div class="info-block">
                <div class="profile-img-block">
                    <div v-if="userData.profile_filed" class="profile-img">
                        <img :src="userData.profile_filed.img" alt="">
                    </div>
                </div>
                <div class="info-list-block">
                    <div class="main-user">
                        <div class="username">
                            <p>{{ userData.profile_filed.second_name }}</p>
                        </div>
                        <div class="user-active">
                            <div class="online">
                                <p>В сети</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="userData.profile_filed" class="info-list">
                        <div class="email inline"><img alt="" src="@/assets/images/profile/email.svg">
                            <div class="bg">
                                <p>E-mail: <span>{{ userData.email }}</span></p>
                            </div>
                        </div>
                        <div class="bday inline"><img alt="" src="@/assets/images/profile/calendar.svg">
                            <div class="bg">
                                <p>Дата рождения:<span>{{ date }}</span></p>
                            </div>
                        </div>
                        <div class="gender inline"><img alt="" src="@/assets/images/profile/gender.svg">
                            <div class="bg">
                                <p>Пол: <span>{{ genderInRussian }}</span></p>
                            </div>
                        </div>
                        <div class="city inline"><img alt="" src="@/assets/images/profile/city.svg">
                            <div class="bg">
                                <p>Город: <span>{{ city }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="user-active">
            <div class="online">
                <p>В сети</p>
            </div>
        </div> -->
            </div>
            <div class="edit-section">
                <div class="edit-link">
                    <label for="imageUserInfo">Изменить фотографию</label>
                    <input
                        id="imageUserInfo"
                        accept="image/*"
                        hidden
                        type="file"
                        @change="CHANGE_CROPPER_IMAGE"/>
                </div>

                <div class="edit-link">
                    <button class="profile__wrapper-date_change" @click="toggleModal('password');">
                        Изменить пароль
                    </button>
                </div>
                <div class="edit-link">
                    <button
                        class="profile__wrapper-date_change"
                        @click="
                            toggleModal('data');
                            loadCities();
                        ">
                        Редактировать данные
                    </button>
                </div>
            </div>
        </div>
        <h1 class="blocks-title">Личный рейтинг</h1>
        <div style="margin-bottom:25px;">
            <div class="diagram-section">
                <DiagramBlockComponent
                    v-for="(achiev, i) in userData.achiev"
                    :key="i"
                    :gameLink="achiev.link_to_play"
                    :levelRating="achiev.lvl"
                    :placeRating="achiev.place_current"
                    :pointsCurrent="achiev.points_current"
                    :pointsTotal="achiev.points_total"
                    :titleRating="achiev.name"
                    :totalPlacesRating="achiev.place_total"></DiagramBlockComponent>
            </div>
        </div>
        <h1 class="blocks-title">Хардскиллы</h1>
        <div class="hardskills-section">
            <div class="hardskills">
                <LevelBlockComponent
                    v-for="(hardSkill, i) in userData.Hard_skill_field"
                    :key="i"
                    :completedQuantity="hardSkill.quantity"
                    :quantityOfAllItems="hardSkill.quantity_questions">
                    {{ hardSkill.name }}
                </LevelBlockComponent>
            </div>
        </div>
        <h1 class="blocks-title">Софтскиллы</h1>
        <div class="softskills-section">

            <div class="softskills">
                <OrangeVersionLevelBlockComponent
                    v-for="(softSkill, i) in userData.Soft_skill_field"
                    :key="i"
                    :completedQuantity="softSkill.quantity"
                    :quantityOfAllItems="softSkill.quantity_questions">
                    {{ softSkill.name }}
                </OrangeVersionLevelBlockComponent>
            </div>
        </div>
    </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Inter';
}

.main-user {
  display: flex;
  flex-direction: row;
}

.username {
  font-size: 22px;
  margin-left: 30px;
  margin-top: 10px;
}

.info-list img {
  width: 30px;
  fill: #FF7F00;
  margin-left: 26px;
}

.info-block {
  margin-top: 30px;
  display: flex;
}

.profile-img-block {
  text-align: center;
}

.profile-img {
  width: 220px;
  height: 220px;
  border-radius: 18px;
  background-color: #DDDDE0;
}

.profile-img img {
  width: 220px;
  height: 220px;
  border-radius: 18px;
}

.edit-link {
  color: #FF7F00;
  font-size: 14px;
  cursor: pointer;
}

.edit-link > * {
  cursor: pointer;
}

.info-list {
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
}

.edit-text {
  text-align: center;
}

.inline {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.bg p {
  margin-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bg {
  width: 320px;
  flex-shrink: 0;
  margin-left: 17px;
  border-radius: 5px;
  background: #F1F1F3;
}

.user-active {
  display: flex;
  margin-left: 15%;
  margin-top: 10px;
}

.online p {
  color: #F5F5F5;
  font-size: 14px;
  padding: 2px 12px;
  border-radius: 3px;
  background: #58A76E;
  margin-right: 9px;
}

.offline p {
  color: #F5F5F5;
  font-size: 14px;
  padding: 2px 12px;
  border-radius: 3px;
  background: #8CA493;
}

.edit-section {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
  margin-bottom: 34px;
  margin-top: 10px;
}

.blocks-title {
  margin-bottom: 20px;
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  margin-left: 47.5px;
}

.diagram-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.hardskills {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, auto);
  gap: 20px 90px;
  margin-inline: auto;
  margin-bottom: 20px;
}

.hardskills-section {
  display: flex;
  justify-content: center;
}

.softskills {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(10, auto);
  gap: 20px 90px;
  margin-inline: auto;
}

.softskills-section {
  display: flex;
  justify-content: center;
}

.bg {
  display: flex;
}

.bg span {
  margin-left: 20px;
}

@media (max-width: 1100px) {
  .blocks-title {
    text-align: center;
    margin-left: 0;
  }

  .hardskills {
    grid-template-columns: repeat(1, 1fr);
  }

  .softskills {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 700px) {
  .info-block {
    flex-direction: column;
  }

  .profile-img-block {
    display: flex;
    justify-content: center;
  }

  .profile-img {
    width: 90%;
    height: 90%;
    justify-content: center;
  }

  .profile-img img {
    width: 100%;
    height: 100%;
  }

  .diagram-section {
    flex-direction: column;
    align-items: center;
  }

  .edit-section {
    justify-content: space-evenly;
    text-align: center;
    display: flex;
    width: 100%;
  }
}
</style>
