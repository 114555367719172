<template>
    <section class="contact">
        <div class="container">
            <div class="contact__wrapper">
                <div class="contact__wrapper-left">
                    <div class="contact__title title">Контакты</div>

                    <div class="contact__wrapper-left_block">
                        <div class="contact__wrapper-left_item">
                            <div class="contact__wrapper-left_title">Наш адрес</div>
                            <div class="contact__wrapper-left_text">
                                {{ contactsInfo.adres }}
                            </div>
                        </div>

                        <div class="contact__wrapper-left_item">
                            <div class="contact__wrapper-left_title">Электронная почта</div>
                            <a
                                v-for="(email, i) in contactsInfo.emails"
                                :key="'e' + i"
                                class="contact__wrapper-left_text contact__wrapper-left_email"
                                href="mailto:info@businessUP.com"
                            >{{ email.description }}: {{ email.email }}</a
                            >
                        </div>

                        <div class="contact__wrapper-left_item">
                            <div class="contact__wrapper-left_title">Наш телефон</div>
                            <a
                                class="contact__wrapper-left_text contact__wrapper-left_phone"
                                href="tel:+7 (495) 123-456"
                            >{{ contactsInfo.tel }}</a
                            >
                        </div>
                    </div>

                    <form class="contact__wrapper-left_form" @submit.prevent="sendMessage">
                        <div class="contact__wrapper-left_subtitle subtitle">
                            Вопросы? Предложения? Пожелания?
                        </div>
                        <div class="contact__wrapper-left_form-block">
                            <div v-for="(email, index) in emails" :key="index" class="contact__wrapper-left_input">
                                <input v-model="email.value" placeholder="Ваша электронная почта" type="email"/>
                            </div>
                            <div v-if="errors && errors.emails" class="error-email">
                                {{ errors.emails }}
                            </div>
                            <div v-if="emails.length < emailsMaxLength" class="plus-btn" @click="addEmail">
                                +
                            </div>
                            <div class="contact__wrapper-left_input">
                                <input v-model="name" placeholder="Ваше имя" type="text"/>
                            </div>
                            <div v-if="errors && errors.name" class="error-email">
                                {{ errors.name }}
                            </div>
                            <div class="contact__wrapper-left_input">
                                <textarea v-model="text" placeholder="Сообщение"></textarea>
                            </div>
                            <div v-if="errors && errors.text" class="error-email">
                                {{ errors.text }}
                            </div>
                            <div class="contact__wrapper-left_input">
                                <input type="submit" value="Отправить"/>
                            </div>
                        </div>
                    </form>
                </div>

                <!--                <div class="contact__wrapper-right">-->
                <!--                    <div class="contact__wrapper-right_map">-->
                <!--                        <iframe-->
                <!--                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAPKPgIv0dmKwAEWc0CkbSjYHPfSFeaQL0&q=г. Москва, Вознесенский переулок, 7&zoom=15&center=55.756228611115326,37.62092431764977"-->
                <!--                            width="100%"-->
                <!--                            height="100%"-->
                <!--                            style="border: 0"-->
                <!--                            allowfullscreen=""-->
                <!--                            loading="lazy"-->
                <!--                        ></iframe>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ContactsView',
    data() {
        return {
            text: '',
            name: '',
            errors: null,
            emails: [{ value: '' }],
            emailsMaxLength: 3,
        };
    },
    computed: {
        ...mapGetters({
            contactsInfo: 'contacts/contactsInfo',
        }),
    },
    async created() {
        this.getContactsInfo();
    },
    methods: {
        ...mapActions({
            sendContactsMessage: 'contacts/sendContactsMessage',
            getContactsInfo: 'contacts/getContactsInfo',
        }),

        addEmail() {
            if (this.emails.length < this.emailsMaxLength) {
                this.emails.push({ value: '' });
            }
        },

        sendMessage() {
            const errors = {};
            const checkRequired = this.emails.some(({ value }) => !value);
            const checkInvalid = this.emails.some(({ value }) => !/^[^@]+@\w+(\.\w+)+\w$/.test(value));
            if (checkRequired) {
                errors.emails = 'Email is required';
            } else if (!this.name) {
                errors.name = 'name required';
            } else if (!this.text) {
                errors.text = 'text required';
            } else if (checkInvalid) {
                errors.emails = 'Invalid email';
            } else if (!this.name.length > 3) {
                errors.name = 'write full name';
            } else if (this.text.length < 10) {
                errors.text = 'at least two words';
            }
            if (Object.keys(errors).length > 0) {
                this.errors = errors;
                // return;
            } else {
                const emails = this.emails.map(item => item.value);
                this.errors = null;
                this.sendContactsMessage({
                    emails,
                    msg: this.text,
                    name: this.name,
                });
                this.email = '';
                this.text = '';
                this.name = '';
            }
        },
    },
};
</script>

<style scoped>
.error-email {
  color: red;
  margin-left: 10px;
  font-size: 15px;
}
</style>
