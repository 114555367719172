<template>
    <section class="invait">
        <div class="container">
            <div class="invait__title title">Приглашения</div>
            <div v-if="invites.length" class="invait__wrapper">
                <div>
                    <transition-group name="fade">
                        <div
                            v-for="(item) in leftInvites"
                            :key="item.id"
                            class="invait__wrapper-item"
                            @click="SHOW_ABOUT_INVITE(item.id)"
                        >
                            <div :class="{ active: moreInfo[item.id] }" class="invait__wrapper-item_about">
                                <div class="invait__wrapper-item_title">{{ item.name ? item.name : 'Sample Team' }}</div>
                                <div class="invait__wrapper-item_counter">Участников: {{ item.members_count }}</div>
                                <div class="invait__wrapper-item_text">
                                    {{ item.letter }}
                                </div>
                            </div>
                            <transition name="slide">
                                <div v-show="moreInfo[item.id]" class="invait__wrapper-item_more">
                                    <div class="invait__wrapper-item_more-invite">Приглашение в команду</div>
                                    <div class="invait__wrapper-item_more-title">{{ item.name ? item.name : 'Sample Team' }}</div>
                                    <div class="invait__wrapper-item_more-little">Номинация</div>
                                    <div class="invait__wrapper-item_more-about">{{ item.nomination_name }}</div>
                                    <div class="invait__wrapper-item_more-little">Сопроводительное письмо</div>
                                    <div class="invait__wrapper-item_more-text">{{ item.letter }}</div>
                                    <div
                                        class="invait__wrapper-item_more-button accept"
                                        @click="acceptAndDeleteAnother({invitation_id: item.id})">
                                        <button>Принять приглашение</button>
                                    </div>
                                    <div class="invait__wrapper-item_more-button deny" @click="declineInvite({invitation_id: item.id})">
                                        <button>Отказаться</button>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </transition-group>
                </div>
                <div>
                    <transition-group name="fade">
                        <div
                            v-for="(item) in rightInvites"
                            :key="item.id"
                            class="invait__wrapper-item"
                            @click="SHOW_ABOUT_INVITE(item.id)"
                        >
                            <div :class="{ active: moreInfo[item.id] }" class="invait__wrapper-item_about">
                                <div class="invait__wrapper-item_title">{{ item.name ? item.name : 'Sample Team' }}</div>
                                <div class="invait__wrapper-item_counter">Участников: {{ item.members_count }}</div>
                                <div class="invait__wrapper-item_text">
                                    {{ item.letter }}
                                </div>
                            </div>
                            <transition name="slide">
                                <div v-show="moreInfo[item.id]" class="invait__wrapper-item_more">
                                    <div class="invait__wrapper-item_more-invite">Приглашение в команду</div>
                                    <div class="invait__wrapper-item_more-title">{{ item.name ? item.name : 'Sample Team' }}</div>
                                    <div class="invait__wrapper-item_more-little">Номинация</div>
                                    <div class="invait__wrapper-item_more-about">{{ item.nomination_name }}</div>
                                    <div class="invait__wrapper-item_more-little">Сопроводительное письмо</div>
                                    <div class="invait__wrapper-item_more-text">{{ item.letter }}</div>
                                    <div
                                        class="invait__wrapper-item_more-button accept"
                                        @click="acceptAndDeleteAnother({invitation_id: item.id})">
                                        <button>Принять приглашение</button>
                                    </div>
                                    <div class="invait__wrapper-item_more-button deny" @click="declineInvite({invitation_id: item.id})">
                                        <button>Отказаться</button>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </transition-group>
                </div>
            </div>
            <div v-else class="invait__wrapper">пусто</div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'InvitingView',
    data() {
        return {
            prevHeight: 0,
        };
    },
    async created() {
        await this.requestData();
        await this.getInvitesCount({ id: this.userId });
    },
    computed: {
        ...mapGetters({
            invites: 'invites/invites',
            moreInfo: 'invites/moreInfoGetter',
            userId: 'user/userId',
        }),

        leftInvites() {
            const newArray = [];

            this.invites.forEach((item, i) => {
                if (i === 0) {
                    newArray.unshift(item);
                } else if (i % 2 === 0) {
                    newArray.push(item);
                }
            });
            return newArray;
        },

        rightInvites() {
            const newArray = [];

            this.invites.forEach((item, i) => {
                if (i % 2 !== 0) {
                    newArray.push(item);
                }
            });
            return newArray;
        },
    },
    methods: {
        ...mapMutations({
            SHOW_ABOUT_INVITE: 'invites/SHOW_ABOUT_INVITE',
        }),

        ...mapActions({
            invitesList: 'invites/invitesList',
            acceptInvite: 'invites/acceptInvite',
            declineInvite: 'invites/declineInvite',
            getInvitesCount: 'user/getInvitesCount',
        }),

        async requestData() {
            await this.invitesList({
                id: this.userId,
            });
        },
        async acceptAndDeleteAnother(id) {
            await this.acceptInvite(id);
            await this.requestData();
        },
    },
};
</script>

<style>

</style>
