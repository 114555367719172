<template>
    <div class="auth auth-register">
        <div class="container">
            <div class="auth__logo">
                <img alt="" src="@/assets/images/logo-2.png"/>
            </div>
            <form action="#" class="auth__form" @submit.prevent="handleSubmit">
                <div
                    :class="{
                        'auth__form-input_error':
                            $v.username.$error ||
                            this.registerErrors.login.length > 0 ||
                            !$v.username.minLength,
                    }"
                    class="auth__form-input"
                >
                    <input
                        v-model.trim="username"
                        placeholder="Логин"
                        type="text"
                        @input="registerErrors.login = []"
                    />
                    <div class="auth__form-error">
                        <div v-for="error in registerErrors.login" :key="error.index">
                            {{ error }}
                        </div>
                        <div v-if="!$v.username.minLength">
                            Мин. длина логина 4 символа!
                        </div>
                    </div>
                </div>
                <div
                    :class="{
                        'auth__form-input_error':
                            $v.email.$error ||
                            (!$v.email.EMAIL_CHECK && this.email.length > 0) ||
                            this.registerErrors.email.length > 0,
                    }"
                    class="auth__form-input"
                >
                    <input
                        v-model.trim="email"
                        placeholder="Электронная почта"
                        type="email"
                        @input="registerErrors.email = []"
                    />
                    <div class="auth__form-error">
                        <div v-for="error in registerErrors.email" :key="error.index">
                            {{ error }}
                        </div>
                        <div v-if="!$v.email.EMAIL_CHECK && this.email.length > 0">
                            Некорректная почта!
                        </div>
                    </div>
                </div>
                <div
                    :class="{
                        'auth__form-input_error':
                            !$v.password.minLength ||
                            $v.password.$error ||
                            (!$v.password.PASSWORD_CHECK && this.password.length > 0) ||
                            this.registerErrors.password.length > 0,
                    }"
                    class="auth__form-input"
                >
                    <input
                        v-model.trim="password"
                        placeholder="Пароль"
                        type="password"
                        @input="registerErrors.password = []"
                    />
                </div>
                <div
                    :class="{
                        'auth__form-input_error':
                            !$v.passwordRepeat.minLength ||
                            !$v.passwordRepeat.sameAsPassword ||
                            $v.passwordRepeat.$error,
                    }"
                    class="auth__form-input"
                >
                    <input
                        v-model.trim="passwordRepeat"
                        placeholder="Пароль"
                        type="password"
                        @input="registerErrors.password = []"
                    />
                    <div class="auth__form-error">
                        <div v-for="error in registerErrors.password" :key="error.index">
                            {{ error }}
                        </div>
                        <div v-if="!$v.password.PASSWORD_CHECK && this.password.length > 0">
                            Некорректный новый пароль!
                        </div>
                        <div v-if="!$v.passwordRepeat.sameAsPassword">
                            Пароли не совпадают!
                        </div>
                        <div v-if="!$v.password.minLength">
                            Мин. длина пароля 8 символов!
                        </div>
                    </div>
                </div>
                <div v-if="success" class="auth__form-success">
                    <span
                    >Поздравляем - Вы успешно зарегистрировались!<br/>
                        На вашу почту отправлено пиьсмо для активации.</span
                    >
                </div>
                <div :class="{ success: success }" class="auth__form-submit">
                    <input class="btn" type="submit" value="Зарегистрироваться"/>
                </div>
            </form>
            <div class="auth__form-register">
                <span>У вас уже есть аккаунт?</span>
                <router-link to="/login">Авторизоваться</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { REGEX_EMAIL, REGEX_PASSWORD } from '@/global/constants';

const EMAIL_CHECK = value => REGEX_EMAIL.test(value);
const PASSWORD_CHECK = value => REGEX_PASSWORD.test(value);

export default {
    name: 'RegisterView',
    data() {
        return {
            username: '',
            email: '',
            password: '',
            passwordRepeat: '',
            success: false,
        };
    },
    computed: {
        ...mapGetters({
            registerErrors: 'settings/registerErrors',
        }),
    },
    methods: {
        ...mapActions({
            register: 'auth/register',
        }),

        async handleSubmit() {
            this.$v.$touch();

            const payload = {
                username: this.username,
                email: this.email,
                password: this.password,
            };

            if (!this.$v.$invalid) {
                const res = await this.register(payload);

                if (res === true) {
                    this.success = true;
                }
            }
        },
    },
    validations: {
        username: {
            required,
            minLength: minLength(4),
        },
        email: {
            required,
            email,
            minLength: minLength(12),
            EMAIL_CHECK,
        },
        password: {
            required,
            minLength: minLength(8),
            PASSWORD_CHECK,
        },
        passwordRepeat: {
            required,
            minLength: minLength(8),
            sameAsPassword: sameAs('password'),
        },
    },
};
</script>
