<template>
    <div class="auth">
        <div class="container">
            <div class="auth__logo">
                <img alt="" src="@/assets/images/logo-2.png"/>
            </div>
            <div class="auth__form-register">
                <router-link to="/login">Вы успешно авторизованы!</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Authorized',
    data() {
        return {};
    },
    async mounted() {
        window.addEventListener('load', () => {
            const url = new URL(window.location.href);
            const data = url.searchParams.entries();
            const newData = Array.from(data);
            const myArray = [];
            for (let i = 0, len = newData.length; i < len; i++) {
                myArray.push(newData[i][1]);
            }
            myArray.forEach((item, i) => {
                if (i === 1) {
                    localStorage.setItem('token', item);
                } else {
                    localStorage.setItem('token-access', item);
                }
            });
            this.changeToken(localStorage.getItem('token-access'));
            this.updatedToken ? window.close() : this.$router.push('login');
        });
    },
    computed: {
        ...mapGetters({
            updatedToken: 'globals/accessToken',
        }),
    },
    methods: {
        ...mapActions({
            changeToken: 'globals/changeTokenAction',
        }),
    },
};
</script>

<style>

</style>
