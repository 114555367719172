<template>
    <div class="container">
        <i class="fa fa-check-circle success-icon"></i>
        <p class="message">Ваш аккаунт успешно активирован!</p>
        <a class="button" href="/login">Войти в аккаунт</a>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'AccountActivationView',
    async created() {
        const url = this.$route.path;
        const urlArray = url.split('/');
        const uuid = urlArray[urlArray.length - 1];
        const payload = {
            uuid,
        };
        await this.activateAccount(payload);
    },
    methods: {
        ...mapActions({
            activateAccount: 'user/activateAccount',
        }),
    },
};

</script>
<style scoped>
.container {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  height: 150px;
}

.success-icon {
  font-size: 48px;
  color: #4CAF50;
}

.message {
  font-size: 24px;
  margin-top: 20px;
}

.button {
  display: inline-block;
  background-color: #1d3e51;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 20px;
}

.button:hover {
  background-color: #1d3e60;
}
</style>
