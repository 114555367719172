<template>
    <div class="modal-add-comand">
        <button class="modal-add-comand__close" @click="toggleModal">
            <img alt="" src="@/assets/images/close-icon.svg"/>
        </button>
        <div class="container">
            <div class="modal-add-comand__title">Пригласить в команду</div>
            <form class="modal-add-comand__wrapper">
                <div class="modal-add-comand__wrapper-input">
                    <span>Название команды</span>
                    <input :disabled="teamId" :value='filteredTeamName'/>
                </div>
                <div class="modal-add-comand__wrapper-input">
                    <span>Сопроводительное письмо</span>
                    <textarea v-model="description" placeholder="Письмо"></textarea>
                </div>

                <div class="modal-add-comand__wrapper-submit btn" @click="inviteMember">
                    <button type="button" @click="inviteMember(payload.id)">Пригласить в команду</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { data } from 'browserslist';

export default {
    name: 'ModalInviteCommand',
    data() {
        return {
            teamId: null,
            // nominationId: null,
            description: '',
            filteredTeamName: [],
            filteredNominationsName: [],
            teamAndNomId: null,
        };
    },
    computed: {
        data() {
            return data;
        },
        ...mapGetters({
            inviting: 'championship/inviting',
            championship: 'championship/currentChampionship',
            teams: 'championship/championshipTeams',
            user: 'user/userId',
            currentChampionship: 'championship/currentChampionship',
        }),

        championshipId() {
            return this.championship.id ?? null;
        },
    },
    async created() {
        await this.getNominationsList();
        await this.teamList({ id_championship: this.championshipId });
        if (this.teams) {
            this.teams.forEach(item => {
                if (item.main_user === this.user) {
                    this.nominationId = +item.nomination;
                    this.teamId = item.id;
                    this.filteredTeamName = item.name;
                }
            });
        }
    // if (this.inviting) {
    //     this.inviting.nominations.forEach(item => {
    //         if (item.id === this.nominationId) {
    //             this.filteredNominationsName = item.name;
    //         }
    //     });
    // }
    },
    methods: {
        ...mapMutations({
            SET_INVITED_USER: 'championship/SET_INVITED_USER',
        }),
        ...mapActions({
            getNominationsList: 'championship/getNominationsList',
            teamList: 'championship/getChampionshipTeamsDetails',
            inviteToTeam: 'championship/inviteToTeam',
            toggleModals: 'settings/toggleModals',
            getTeamMembersDetails: 'championship/getTeamMembersDetails',
        }),

        toggleModal() {
            this.toggleModals({
                parent: 'championship',
                children: 'members',
            });
        },

        async inviteMember() {
            const data = {
                user_id: this.inviting.user_id,
                championship_id: this.championshipId,
                team_id: this.teamId,
                nomination_id: null,
                description: this.description,
            };

            const res = await this.inviteToTeam(data);
            if (res) {
                this.toggleModal();
                this.SET_INVITED_USER(null);
                await this.updateTeamData();
            }
        },
        async updateTeamData() {
            await this.teamList({
                id_championship: this.championshipId,
                wait: !!this.currentChampionship.id,
            })
                .then(item => {
                    this.isLoadingTeams = false;
                    if (item.length) {
                        return this.getTeamMembersDetails({ teamId: this.teams[0].id });
                    }
                })
                .then(() => {
                    this.isLoadingMembers = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>
