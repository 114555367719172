<template>
    <div
        :id="'item-'+payload.id"
        class="champions__wrapper-item"
        @mouseleave="hideDescription"
        @mouseover="showDescription(payload.id)"
    >
        <router-link class="content-btn" v-bind:to="membersLink">
            <div class="champions__wrapper-item_img">
                <img :src="preparedChampionship.img" alt="">
            </div>
            <div class="champions__wrapper-item_info">
                <div class="champions__wrapper-item_title">
                    {{ preparedChampionship.name }}
                </div>
                <div :class="{ 'started': preparedStatus.id === 2 }" class="champions__wrapper-item_date">
                    {{ preparedStatus.title }}
                </div>
                <!-- <button v-if="preparedStatus.title === 'В процессе'" style="border: solid 1px black; padding: 5px; display: block;" v-on:click="handleClick">{{ preparedStatus.title === 'В процессе' ? 'Перейти в УК' : null }}</button>                 -->
                <div v-if="preparedStatus.type === 'not_started'" class="champions__wrapper-item_detailed">Подробнее<br>о
                    чемпионате
                </div>
                <div v-if="preparedStatus.type === 'start'" class="champions__wrapper-item_detailed">Перейти<br>к
                    командам
                </div>
                <button
                    v-if="preparedStatus.type === 'in_process' && payload.status_in_uk !== 'not_started'"
                    :disabled="!isMemberOfTeam"
                    class="champions__wrapper-item_detailed"
                    v-on:click="handleClick"
                >
                    Зайти<br>в игру
                </button>
                <div v-if="preparedStatus.type === 'final'" class="champions__wrapper-item_detailed">Посмотреть<br>
                    рейтинги
                </div>
            </div>
            <div
                v-if="preparedChampionship.top_6_list_for_uk != '' "
                v-show="visible"
                :id="'item-'+payload.id+'-desc'"
                class=""
                style="">
                <ModalDetailChampion
                    :champ_title="preparedChampionship.name"
                    :champ_win_data="preparedChampionship.top_6_list_for_uk"/>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalDetailChampion from '@/components/modals/championship/ModalDetailChampion.vue';
import { send } from '../../global/functions';

export default {
    name: 'ChampionshipCard',
    components: {
        ModalDetailChampion,
    },
    props: {
        payload: {
            type: Object,
            default: () => ({}),
        },
        checkParticipate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            championshipId: 1,
            status: {
                list: [
                    {
                        id: 1,
                        type: 'not_started',
                        title: 'Подготовка',
                    },
                    {
                        id: 2,
                        type: 'start',
                        title: 'Еще не начат',
                    },
                    {
                        id: 3,
                        type: 'in_process',
                        title: 'В процессе',
                    },
                    {
                        id: 4,
                        type: 'final',
                        title: 'Завершен!',
                    },
                ],
                current: {},
            },
            visible: false,
            membersLink: '',
            localTeams: [],
        };
    },
    computed: {
        ...mapGetters({
            convertJWT: 'globals/convertJWT',
            championshipMembers: 'championship/championshipMembers',
            teams: 'championship/championshipTeams',
            userId: 'user/userId',
        }),
        preparedChampionship() {
            return this.payload;
        },
        isMemberOfTeam() {
            let isParticipate = false;
            this.localTeams.forEach(item => item.members.forEach(item => {
                if (item.id === this.userId) isParticipate = true;
            }));
            return isParticipate;
        },
        preparedDateChampionship() {
            const date = new Date(this.payload.date_start).toLocaleString('ru', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            });
            return date;
        },

        preparedStatus() {
            const status = this.status.list.find(item => item.type === this.payload.status);

            if (status.id === 1) {
                status.title = `Дата начала: ${this.preparedDateChampionship}`;
                return status;
            }

            if (status.id === 2) {
                status.title = `Дата начала: ${this.preparedDateChampionship}`;
                return status;
            }

            if (status.id === 3) {
                status.title = `Дата начала: ${this.preparedDateChampionship}`;
                return status;
            }

            if (status.id === 4) {
                status.title = `Дата начала: ${this.preparedDateChampionship}`;
                return status;
            }

            return status;
        },
    },
    async mounted() {
        if (this.checkParticipate) {
            await this.championshipDetails();
        }

        this.membersLink = this.getFullPath();
    },
    methods: {
        ...mapActions({
            getUserData: 'user/getUserData',
            refreshToken: 'user/refreshToken',
            getChampionshipTeamsDetails: 'championship/getChampionshipTeamsDetails',
        }),
        showDescription(id) {
            const block = document.getElementById(`item-${id}`);
            const description = document.getElementById(`item-${id}-desc`);
            const rect = block.getBoundingClientRect();

            block.addEventListener('mousemove', e => {
                this.visible = true;
                if (description) {
                    description.style.left = `${e.clientX - rect.left + 10}px`;
                    description.style.top = `${e.clientY - rect.top + 10}px`;
                }
            });
        },
        getFullPath() {
            switch (this.payload.status) {
                case 'not_started':
                    return `/championship/${this.payload.id}/description`;
                case 'start':
                    return `/championship/${this.payload.id}/members`;
                case 'final':
                    return `/championship/${this.payload.id}/results`;
                case 'in_process':
                    return `/championship/${this.payload.id}/members`;
                default:
                    return '/error';
            }
        },
        hideDescription() {
            this.visible = false;
        },
        async championshipDetails() {
            this.localTeams = await this.getChampionshipTeamsDetails({
                id_championship: this.payload.id,
                wait: !!this.payload.id,
            });
        },
        async handleClick(event) {
            event.stopPropagation();
            event.preventDefault();

            await send({
                uri: `redirect/${this.payload.id}/`,
            });
        },
    },
};
</script>

<style scoped>
.champions__wrapper-item {
    margin-right: 10px;
}

.champions__wrapper-item_title {
    color: black;
}

.champions__wrapper-item_date {
    color: black;
}
</style>
