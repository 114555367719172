var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-password"},[_c('button',{staticClass:"modal-password__close",on:{"click":_vm.toggleModal}},[_c('img',{attrs:{"alt":"","src":require("@/assets/images/close-icon.svg")}})]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"modal-password__title"},[_vm._v("Изменение пароля")]),_c('form',{staticClass:"modal-password__wrapper"},[_c('div',{staticClass:"modal-password__wrapper-input",class:{
                    'auth__form-input_error':
                        _vm.$v.password.current.$error ||
                        this.changepassErrors.password.length > 0,
                }},[_c('span',[_vm._v("Старый пароль")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password.current),expression:"password.current",modifiers:{"trim":true}}],attrs:{"placeholder":"Введите старый пароль","type":"password"},domProps:{"value":(_vm.password.current)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.password, "current", $event.target.value.trim())},function($event){_vm.changepassErrors.password = []}],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"modal-password__wrapper-input",class:{
                    'auth__form-input_error':
                        !_vm.$v.password.new.minLength ||
                        _vm.$v.password.new.$error ||
                        (!_vm.$v.password.new.PASSWORD_CHECK && this.password.new.length > 0),
                }},[_c('span',[_vm._v("Новый пароль")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password.new),expression:"password.new",modifiers:{"trim":true}}],attrs:{"placeholder":"Придумайте новый пароль","type":"password"},domProps:{"value":(_vm.password.new)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.password, "new", $event.target.value.trim())},function($event){_vm.changepassErrors.password = []}],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"modal-password__wrapper-input",class:{
                    'auth__form-input_error':
                        !_vm.$v.password.confirm.minLength ||
                        !_vm.$v.password.confirm.sameAsPassword ||
                        _vm.$v.password.confirm.$error,
                }},[_c('span',[_vm._v("Подтверждение нового пароля")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password.confirm),expression:"password.confirm",modifiers:{"trim":true}}],attrs:{"placeholder":"Подтвердите новый пароль","type":"password"},domProps:{"value":(_vm.password.confirm)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.password, "confirm", $event.target.value.trim())},function($event){_vm.changepassErrors.password = []}],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"modal-password__wrapper-error"},[_vm._l((_vm.changepassErrors.password),function(error){return _c('div',{key:error.index},[_vm._v(" "+_vm._s(error)+" ")])}),(
                        !_vm.$v.password.new.PASSWORD_CHECK && this.password.new.length > 0
                    )?_c('div',[_vm._v(" Некорректный новый пароль! ")]):_vm._e(),(!_vm.$v.password.confirm.sameAsPassword)?_c('span',[_vm._v("Пароли не совпадают!")]):_vm._e(),_c('br'),(!_vm.$v.password.new.minLength)?_c('span',[_vm._v("Мин. длина пароля 8 символов!")]):_vm._e()],2),_c('div',{staticClass:"modal-password__wrapper-submit btn",on:{"click":_vm.changePassword}},[_vm._v(" Сохранить изменения ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }