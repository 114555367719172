<template>
    <div class="modal-upload-video">
        <button class="modal-upload-video__close" @click="toggleModal">
            <img alt="" src="@/assets/images/close-icon.svg"/>
        </button>
        <div class="container">
            <div class="modal-upload-video__title">{{ video.isEdit ? 'Редактировать видео': 'Загрузить видео' }}</div>
            <form class="modal-upload-video__wrapper">
                <div
                    class="modal-upload-video__wrapper-input"
                >
                    <span>Наименование ролика</span>
                    <input
                        v-model="video.name"
                        placeholder="Наименование ролика"
                        type="text"
                    />
                </div>
                <div
                    class="modal-upload-video__wrapper-input"
                >
                    <span>Аннотация</span>
                    <input
                        v-model="video.description"
                        placeholder="Аннотация"
                        type="text"
                    />
                </div>
                <div v-if="!video.isEdit" class="modal-upload-video__wrapper-submit btn inverted">
                    <label for="videoUpload" @click="addVideo">
                        <span>Добавить видео</span>
                    </label>
                </div>
                <div
                    v-show="video.visible || video.isEdit"
                    :class="{ 'auth__form-input_error': !video.passed.youtube && !video.passed.others }"
                    class="modal-upload-video__wrapper-input"
                >
                    <span>Ссылка</span>
                    <input
                        v-model="video.url"
                        placeholder="<iframe></iframe>"
                        type="text"
                    />
                </div>
                <div
                    v-if="video.url && (video.passed.youtube || video.passed.others)"
                    class="modal-upload-video__wrapper-about-file">
                    <div v-if="video.passed.youtube && video.src" class="uploaded-video">
                        <iframe
                            :src="'https://www.youtube.com/embed/'+video.code+'?controls=0&showinfo=0&rel=0'"
                            :srcdoc="'<style>*{overflow:hidden;padding:0;margin:0;}html,body{height:100%;}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a class=preloadLink href=//www.youtube.com/embed/'+video.code+'?controls=1&showinfo=0&rel=0&autoplay=1><img class=preloadImg src=https://img.youtube.com/vi/'+video.code+'/hqdefault.jpg><span>▶</span></a>'"
                            allow="autoplay"
                            allowfullscreen
                            frameborder="0"
                        ></iframe>
                    </div>
                    <div v-else>
                        <SkeletonCard
                            borderRadius="10px"
                            primary="#e3e3e3"
                            structure="7001"
                        />
                    </div>
                    <!-- <div class="uploaded-video" v-else>
              <iframe
                  :src="video.code"
                  allow="autoplay; encrypted-media; fullscreen"
                  frameborder="0"
                  allowfullscreen></iframe>
          </div> -->
                    <div v-show="video.passed.youtube && video.src" class="delete-video" @click="clearVideoData">Удалить видео
                    </div>
                </div>
                <div class="flexbox modal-upload-video__wrapper-buttons">
                    <div>
                        <button
                            :disabled="!isRequiresPassed"
                            class="modal-upload-video__wrapper-submit btn"
                            type="button"
                            @click="video.isEdit ? updateVideo() : uploadVideo()">
                            {{ video.isEdit ? 'Сохранить': 'Загрузить видео' }}
                        </button>
                    </div>
                    <div v-if="video.isEdit">
                        <button
                            class="modal-upload-video__wrapper-submit btn danger"
                            type="button"
                            @click="confirm.visible = true"
                        >
                            Удалить
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <transition name="fade">
            <Confirm
                v-if="confirm.visible"
                :payload="confirm"
                @accept="deleteVideo"
                @reject="confirm.visible = false"
            />
        </transition>
    </div>
</template>

<script>
import { REGEX_FULL_YT, REGEX_CODE_YT } from '@/global/constants';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import debounce from 'lodash/debounce';
import Confirm from '@/components/modals/common/Confirm';

export default {
    name: 'ModalUploadVideo',
    components: {
        Confirm,
    },
    data() {
        return {
            confirm: {
                visible: false,
                title: 'Вы уверены, что хотите удалить видео?',
                buttons: {
                    accept: 'Удалить видео',
                    reject: 'Отмена',
                },
                result: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            userSettings: 'user/myInfo',
            video: 'user/video',
        }),

        isRequiresPassed() {
            if (this.video.name && this.video.description && (this.video.passed.youtube || this.video.passed.others) && this.video.url) {
                return true;
            }
            return false;
        },

        debouncedCheckUrl() {
            return debounce(this.checkUrl, 800);
        },
    },
    watch: {
        'video.url'() {
            this.debouncedCheckUrl();
        },
        'video.name'() {
            if (this.video.url) {
                this.debouncedCheckUrl();
            }
        },
        'video.description'() {
            if (this.video.url) {
                this.debouncedCheckUrl();
            }
        },
    },
    methods: {
        ...mapMutations({
            SET_VIDEO: 'user/SET_VIDEO',
        }),

        ...mapActions({
            userVideoUpload: 'user/userVideoUpload',
            userVideoUpdate: 'user/userVideoUpdate',
            userDeleteVideo: 'user/userDeleteVideo',
            toggleModals: 'settings/toggleModals',
        }),

        toggleModal() {
            this.toggleModals({
                parent: 'profile',
                children: 'video',
            });
            this.SET_VIDEO({
                name: '',
                description: '',
                url: '',
                visible: false,
                passed: {
                    youtube: false,
                    others: false,
                },
                code: '',
                src: '',
                isEdit: false,
            });
        },

        addVideo() {
            this.SET_VIDEO({ visible: !this.video.visible });
        },

        clearVideoData() {
            this.SET_VIDEO({
                url: '',
                visible: false,
                passed: {
                    youtube: false,
                    others: false,
                },
                code: '',
                src: '',
            });
        },

        checkUrl() {
            const urlCodeYT = this.video.url.match(REGEX_CODE_YT);
            this.video.passed.youtube = REGEX_FULL_YT.test(this.video.url);

            if (this.video.passed.youtube && urlCodeYT?.length) {
                this.SET_VIDEO({
                    src: this.video.url,
                    code: urlCodeYT[0],
                });
            } else {
                this.SET_VIDEO({
                    passed: {
                        youtube: false,
                        others: false,
                    },
                });
            }

            // TODO валидация для других урлов
            // const link = this.video.url;
            // const url = link.match(REGEX_VIDEO_URL);

            // if (url?.length) {
            //     this.video.code = url[1] ?? '';
            //     this.video.passed.others = true;
            //     this.video.src = this.video.code;
            // }
        },

        async uploadVideo() {
            const params = {
                name: this.video.name,
                description: this.video.description,
                id_user: this.userSettings.id,
                url: this.video.src,
                is_active: true,
            };

            const res = await this.userVideoUpload(params);

            if (res) {
                this.toggleModal();

                this.SET_VIDEO({
                    name: '',
                    description: '',
                    url: '',
                    visible: false,
                    passed: {
                        youtube: false,
                        others: false,
                    },
                    code: '',
                    src: '',
                });
            }
        },

        async updateVideo() {
            const params = {
                name: this.video.name,
                description: this.video.description,
                video_id: this.video.id,
                url: this.video.src,
            };

            const res = await this.userVideoUpdate(params);

            if (res.status === 200) {
                this.toggleModal();

                this.SET_VIDEO({
                    name: '',
                    description: '',
                    url: '',
                    visible: false,
                    passed: {
                        youtube: false,
                        others: false,
                    },
                    code: '',
                    src: '',
                });
            }
        },

        async deleteVideo() {
            const res = await this.userDeleteVideo({ video_id: this.video.id });

            if (res.status === 200) {
                this.confirm.visible = false;

                this.toggleModal();
            }
        },
    },
};
</script>

<style>

</style>
