<script>
export default {
    data: () => ({
        statusesLabel: {
            start: 'Еще не начат',
            not_started: 'Подготовка',
            in_process: 'В процессе',
            final: 'Завершен',
        },
    }),
    props: {
        archive: {
            type: Boolean,
            default: false,
        },
        eventName: {
            type: String,
            default: '',
        },
        eventStatus: {
            type: String,
            default: '',
        },
        eventDescription: {
            type: String,
            default: '',
        },
        eventCreateDate: {
            type: [Number, String],
            default: 0,
        },
        eventStartDate: {
            type: [Number, String],
            default: 0,
        },
        eventFinishDate: {
            type: [Number, String],
            default: 0,
        },
        eventActive: {
            default: '',
        },
        eventMembers: {
            type: [Number, String],
            default: 0,
        },
        eventCommands: {
            type: [Number, String],
            default: 0,
        },
        eventRestriction: {
            type: [Number, String],
            default: 0,
        },
        eventImage: {
            type: [Number, String],
            default: 0,
        },
        eventScore: {
            type: [Number, String],
            default: 0,
        },
        count: {
            type: [Number, String],
            default: 1,
        },
    },
    computed: {
        blockEventBgImage() {
            const requiredData = require(
                this.archive
                    ? '@/assets/images/archive-block.png'
                    : '@/assets/images/event-block.png'
            );

            return {
                backgroundImage: `url(${requiredData})`,
            };
        },
    },
};
</script>

<template>
    <div
        :style="blockEventBgImage"
        class="block-event"
        @click="$emit('click')"
    >
        <div class="event-num">
            <p>
                <slot>{{ count + 1 }}</slot>
            </p>
        </div>
        <div class="event-name">
            <p>
                <slot>{{ eventName }}</slot>
            </p>
        </div>
        <div :class="{'colored': archive}" class="event-date">
            <p>
                <slot>{{ eventStartDate }}</slot>
            </p>
        </div>
        <div v-if="!archive" class="event-state">
            <p>
                <slot>{{ statusesLabel[eventStatus] }}</slot>
            </p>
        </div>
    </div>
</template>

<style scoped>
.block-event {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.event-num p {
  font-weight: 700;
  font-size: 60px;
}

.event-name p {
  font-size: 20px;
  font-weight: 700;
}

.event-date p {
  font-size: 23px;
  font-weight: 700;
}

.event-date.colored p {
  color: #FF7F00;
}

.event-state p {
  font-size: 22.5px;
  font-weight: 700;
  color: #FF7F00;
}
</style>
