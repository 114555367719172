<template>
    <div class="home">
        <Header/>

        <section
            id="improve"
            :style="{
                'background-image': `url(${require(`@/assets/assetsland/img/improvee.jpg`)})`,
            }"
            class="improve"
        >
            <div class="container">
                <h1 class="improve__title title_landing">
                    Business UP формирует навыки предпринимателя с помощью игровых онлайн решений
                </h1>
                <div v-if="!isLoggedIn" class="improve__buttons">
                    <button
                        class="improve__buttons-btn btn"
                        @click="onclick_registritions"
                    >
                        Зарегистрироваться
                    </button>
                    <button
                        class="improve__buttons-btn improve__buttons-login"
                        @click="onclick_login"
                    >
                        Войти
                    </button>
                </div>
                <div v-else class="improve__buttons">
                    <router-link
                        :to="{ path: '/profile' }"
                        class="improve__buttons-btn btn"
                    >
                        Личный кабинет
                    </router-link>
                </div>
            </div>
            <br/>
            <br/>

            <section id="news" class="news">
                <div v-if="newsCatalog.length > 0" class="container">
                    <h2 class="preview__subtitle subtitle_landing">Последние новости
                        <button v-if="!showAll" class="btn-show-all" @click="showAllNews">Все новости</button>
                    </h2>
                    <div class="news__wrapper">
                        <button
                            v-if="showAll && !allLoaded && newsCatalog.length > chunkSize"
                            class="btn-show-back"
                            @click="goBack">
                            <img alt="Back" src="~@/assets/images/icons8-назад-50.png"/>
                        </button>
                        <NewsItem
                            v-for="(item, idx) in displayedNews"
                            :key="idx"
                            :item="item"
                        />
                        <button
                            v-if="showAll && !allLoaded && newsCatalog.length > chunkSize"
                            class="btn-show-next"
                            @click="loadMore">
                            <img alt="Next" src="~@/assets/images/icons8-вперед-50.png"/>
                        </button>
                    </div>
                </div>
            </section>
        </section>

        <br/>
        <br/>

        <div id="preview" class="container">
            <div v-if="previewCatalog.length > 0" class="preview__top">
                <h2 class="news__title subtitle_landing"> Анонсы чемпионатов</h2>
            </div>
            <div v-swiper:swiper="swiperOptions" class="preview__wrapper">
                <div class="swiper-wrapper">
                    <PreviewItem
                        v-for="item in previewCatalog"
                        :key="item.id"
                        :item="item"
                    />
                </div>
            </div>
        </div>

        <section id="new-game" class="new-game">
            <div v-if="gameCatalog.length > 0" class="container">
                <h2 class="new-game__title subtitle_landing">
                    Новые игры на платформе
                </h2>
                <div class="new-game__wrapper">
                    <GameItem
                        v-for="(item, idx) in gameCatalog"
                        :key="idx"
                        :item="item"
                    />
                </div>
            </div>
        </section>

        <br/>
        <VideosViewLanding/>

        <section id="products" class="products">
            <div v-if="productsCatalog.length > 0" class="container">
                <h2 class="products__title subtitle_landing">Сувенирная продукция</h2>
                <div class="products__wrapper">
                    <div v-swiper:swiper2="swiperOptions2" class="products__wrapper-left">
                        <div class="swiper-wrapper">
                            <ProductsItem
                                v-for="(item, idx) in productsCatalog"
                                :key="idx"
                                :item="item"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="videos" id="videos">
  <div class="container">
    <h2 class="videos__title subtitle">Видео</h2>
    <div class="videos__input">
      <div class="videos__input-img">
        <img src="@/assets/assetsland/img/search-icon.svg" alt="" />
      </div>
      <input
        type="text"
        placeholder="Поиск видео по наименованию и описанию"
      />
    </div>
    <div class="videos__wrapper">
      <VideosItem
        v-for="(item, idx) in VideosCatalog"
        :item="item"
        :key="idx"
      />
    </div>
  </div>
</section> -->

        <Footer/>
    </div>
</template>

<script>
import Header from '../components/landing/components/Header.vue';
import Footer from '../components/landing/components/Footer.vue';
import VideosViewLanding from '../components/landing/components/Home/VideosViewLanding.vue';
import PreviewItem from '../components/landing/components/Home/PreviewItem.vue';
import NewsItem from '../components/landing/components/Home/NewsItem.vue';
import GameItem from '../components/landing/components/Home/GameItem.vue';
import ProductsItem from '../components/landing/components/Home/ProductsItem.vue';
import Swiper from 'swiper';
import { send } from '../global/functions';

export default {
    name: 'Home',
    data() {
        return {
            news: [], // Ваши данные о новостях
            chunkSize: 6, // Количество новостей в одном "куске"
            currentChunk: 1, // Текущий "кусок" новостей
            showBackButton: false,
            showNextButton: true,
            newsToShow: 3,
            showAll: false,
            newsStack: [],
            allLoaded: false,
            previewCatalog: [],
            gameCatalog: [],
            newsCatalog: [],
            productsCatalog: [],
            VideosCatalog: [],
            swiperOptions: {
                init: false,
                spaceBetween: 30,
                breakpoints: {
                    800: {
                        slidesPerView: 3,
                    },
                    600: {
                        slidesPerView: 2,
                    },
                    320: {
                        spaceBetween: 16,
                        slidesPerView: 1.2,
                    },
                },
            },
            swiperOptions2: {
                init: false,
                spaceBetween: 30,
                breakpoints: {
                    800: {
                        slidesPerView: 3,
                    },
                    600: {
                        slidesPerView: 2,
                    },
                    320: {
                        spaceBetween: 16,
                        slidesPerView: 1.2,
                    },
                },
            },
            statusSwiper: false,
        };
    },
    components: {
        Header,
        Footer,
        PreviewItem,
        NewsItem,
        GameItem,
        ProductsItem,
        VideosViewLanding,
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters['user/userId'];
        },
        displayedNews() {
            if (this.showAll) {
                return this.newsCatalog.slice(0, this.chunkSize);
            }
            return this.newsCatalog.slice(0, this.newsToShow);
        },
    },
    methods: {
        showAllNews() {
            if (!this.showAll) {
                this.newsStack.push(this.newsToShow);
                this.newsToShow = this.chunkSize;
                this.showAll = true;
            }
        },
        goBack() {
            if (this.newsStack.length > 0) {
                this.newsToShow = this.newsStack.pop();
                this.showAll = false;
            }
        },
        loadMore(direction) {
            if (direction === 'prev') {
                this.currentChunk--;
            } else {
                this.currentChunk++;
            }
            this.showBackButton = this.currentChunk > 1;
            const startIndex = (this.currentChunk - 1) * this.chunkSize;
            const endIndex = this.currentChunk * this.chunkSize;
            this.displayedNews = this.newsCatalog.slice(startIndex, endIndex);
            this.showNextButton = endIndex < this.newsCatalog.length;
        },
        changeSwiper() {
            if (document.documentElement.clientWidth < 800 && this.statusSwiper === false) {
                this.statusSwiper = true;
                if (this.swiper && this.swiper2) {
                    this.swiper.init();
                    this.swiper2.init();
                }
            } else if (document.documentElement.clientWidth > 800 && this.statusSwiper === true) {
                this.statusSwiper = false;
                if (this.swiper && this.swiper2) {
                    this.swiper.destroy();
                    this.swiper2.destroy();
                }
            }
        },
        onclick_login() {
            this.$router.push('/login');
        },
        onclick_registritions() {
            this.$router.push('/register');
        },

        addEventWindow() {
            window.addEventListener('resize', () => {
                this.changeSwiper();
            });
        },

        async loadPreviewCatalog() {
            const data = await send({ uri: 'champ/notfinall' });
            if (!data) return;

            this.previewCatalog = data.filter(item => item.is_active === true);
        },

        async loadgameCatalog() {
            const data = await send({ uri: 'game/all' });
            if (!data) return;

            this.gameCatalog = data.filter(item => item.is_active === true);
        },

        async LoadNewsCatalog() {
            const data = await send({ uri: 'api/drf/newsCatalog/all' });
            if (!data) return;

            this.newsCatalog = data.filter(item => item.is_active === true);
        },
        async LoadProductsCatalog() {
            const data = await send({ uri: 'api/drf/productsCatalog/all' });
            if (!data) return;

            this.productsCatalog = data.filter(item => item.is_active === true);
        },

        async LoadVideosCatalog() {
            this.VideosCatalog = await send({ uri: 'api/drf/VideosCatalog/all' });
        },
    },
    directives: {
        swiper: {
            inserted(el, binding) {
                // eslint-disable-next-line no-new
                new Swiper(el, binding.value);
            },
        },
    },
    async mounted() {
        this.changeSwiper();
        this.addEventWindow();
        await this.loadPreviewCatalog();
        await this.LoadNewsCatalog();
        await this.loadgameCatalog();
        await this.LoadProductsCatalog();
        await this.LoadVideosCatalog();
        // this.loadPeople();

        const hashElement = document.getElementById(this.$route.hash.replace('#', ''));
        if (hashElement) {
            hashElement.scrollIntoView();
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/assetsland/style/style.scss";

.title_landing {
  font-size: 40px;
}
</style>
