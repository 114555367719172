import { send } from '@/global/functions';

const state = {
    partners: {
        partner_registraion: '',
        day_registrations: '',
        increase_percentage: '',
        average_monthly_audience: '',
    },
};

const getters = {
    partnersInfo: state => state.partners,
};

const mutations = {
    SET_PARTNERS_INFO(state, res) {
        state.partners = res;
    },
};

const actions = {
    async getPartnersInfo({ commit }) {
        try {
            const res = await send({
                uri: 'partner/',
                method: 'GET',
            });

            commit('SET_PARTNERS_INFO', res);
        } catch (e) {
            console.error(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
