<template>
    <transition v-if="visible" name="fade">
        <div class="notifications-wrapper">
            <div class="notifications-title"></div>
            <div class="notifications-content"></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Notifications',
    data() {
        return {
            visible: false,
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.notifications-wrapper {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 100;
  padding: 10px 20px;
  background: white;
  border-radius: 10px;
  color: #3e3e3e;
  width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

  .notifications-title {
    font-weight: bolder;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }

  .notifications-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
