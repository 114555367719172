import router from '@/router';
import store from '../store';

const delay = ms => {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
};

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('token-access');
    localStorage.removeItem('tabsContentProfileIndex');
};

const send = async params => {
    const {
        domain = process.env.VUE_APP_BASE_API_URL,
        uri,
        method = 'GET',
        payload,
        rawPayload = false,
        resType = 'json',
        wait = false,
        publicRequest = false,
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    } = params;

    const token = store.getters['user/accessToken'] || localStorage.getItem('token-access');

    if (token) {
        headers.Authorization = `JWT ${token}`;
    }

    if (wait) await delay(1000);

    try {
        const body = rawPayload ? payload : JSON.stringify(payload);

        const res = await fetch(`${domain}/${uri}`, {
            method,
            headers,
            body,
        });

        if ((res.status === 401 || res.status === 400) && !publicRequest) {
            if (uri && uri !== 'auth/jwt/refresh/') {
                try {
                    await store.dispatch('user/refreshToken');
                    await store.dispatch('user/checkToken');
                    location.reload();
                } catch (e) {
                    logout();
                    return;
                }
            }
        }
        switch (res.status) {
            case 500:
                router.push({
                    path: '/error',
                    params: {
                        errorCode: res.status,
                        errorAbout: res.statusText,
                    },
                });
                break;
            default:
                return resType === 'json' ? await res.json() : res;
        }
    } catch (e) {
        console.error(e);
    }
};

const convertedDate = state => {
    const resDate = new Date(state).getTime();
    const d = new Date();
    d.setTime(resDate);
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();

    return `${day}.${month}.${year}`;
};

const convertNumbers = (value, delimiter = ' ') => {
    return value.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
};

export {
    send,
    delay,
    convertedDate,
    convertNumbers
};
