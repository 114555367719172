<template>
    <div class="main">
        <ModalCropper/>
        <Header/>

        <div class="content-wrapper">
            <router-view/>
        </div>

        <Footer/>
        <Notifications/>
    </div>
</template>

<script>
import Header from '../components/main/Header.vue';
import Footer from '../components/main/Footer.vue';
import ModalCropper from '../components/modals/common/ModalCropper.vue';
import Notifications from '../components/modals/common/Notifications.vue';

export default {
    name: 'DefaultLayout',
    components: {
        Header,
        Footer,
        ModalCropper,
        Notifications,
    },
};
</script>

<style lang="scss">
.content-wrapper {
  min-height: 100vh;
  padding-bottom: 40px;
}
</style>
