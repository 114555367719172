import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import VCalendar from 'v-calendar';
import vSelect from 'vue-select';
import vClickOutside from 'v-click-outside';
import VueWait from 'vue-wait';
import SkeletonCards from 'vue-ultimate-skeleton-cards';
import GSignInButton from 'vue-google-signin-button';
// import VueSocketIO from 'vue-socket.io';

import FilterDate from './filters/filterDate';

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VCalendar, {
    componentPrefix: 'v',
});
Vue.use(vClickOutside);
Vue.use(VueWait);
Vue.use(SkeletonCards);
Vue.use(GSignInButton);

Vue.filter('dateFormat', FilterDate);

Vue.component('v-select', vSelect);

// Vue.use(new VueSocketIO({
//     debug: true,
//     connection: 'http://45.90.32.145:8000',
//     vuex: {
//         store,
//         actionPrefix: 'SOCKET_',
//         mutationPrefix: 'SOCKET_'
//     },
// }))

new Vue({
    router,
    store,
    render: h => h(App),
    wait: new VueWait(),
}).$mount('#app');
