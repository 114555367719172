<template>
    <footer :class="{ 'footer__auth': auth }" class="footer">
        <div class="container">
            <div class="footer__left">© BusinessUP 2023</div>
            <div class="footer__right">
                <a
                    class="footer__right-item"
                    href="/download/policy.pdf"
                    target="_blank"
                >Пользовательское соглашение
                </a>
                <router-link
                    class="footer__right-item"
                    to="/partners"
                >Партнерам и инвесторам
                </router-link
                >
                <router-link class="footer__right-item" to="/contacts">Контакты</router-link>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        auth: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
