<template>
    <button class="button">
        <div class="button-txt">
            <p>
                <slot>Кнопка</slot>
            </p>
        </div>
    </button>
</template>

<script>

export default {};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

.button-txt {
  color: #FFFFFF;
  font-size: 25px;
}

.button {
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #0D2144B2;
  min-width: 200px;
  height: 80px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
