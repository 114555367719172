<template>
    <button class="button">
        <span class="button-txt">
            <slot>Кнопка</slot>
        </span>
    </button>
</template>

<script>

export default {};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

.button-txt {
  color: #FFFFFF;
  font-size: 20px;
}

.button {
  box-shadow: 0 4px 4px 0 #00000040;
  background: #0D2144B2;
  min-width: 200px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
