var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile__skills-block_item"},[(_vm.payload.type === 'softSkill')?_c('div',{staticClass:"profile__skills-block_top",style:({
            height:
                _vm.preparedProgressBar({
                    current: _vm.payload.quantity_complete_tests,
                    type: 'soft'
                }) + 'px',
        })},[_c('div',{staticClass:"profile__skills-block_number"},[_vm._v(" "+_vm._s(_vm.preparedTitleBar({ current: _vm.payload.quantity_complete_tests, max: _vm.payload.quantity_max_tests, type: 'soft', }))+" ")])]):_vm._e(),(_vm.payload.type === 'hardSkill')?_c('div',{staticClass:"profile__skills-block_top",style:({
            height:
                _vm.preparedProgressBar({
                    current: _vm.payload.quantity,
                    type: 'hard'
                }) + 'px',
        })},[_c('div',{staticClass:"profile__skills-block_number"},[_vm._v(" "+_vm._s(_vm.preparedTitleBar({ current: _vm.payload.quantity, max: _vm.payload.quantity_max, type: 'hard', }))+" ")])]):_vm._e(),_c('div',{staticClass:"profile__skills-block_bottom"},[_vm._v(_vm._s(_vm.payload.name))])])
}
var staticRenderFns = []

export { render, staticRenderFns }