<template>
    <div class="main-expert">
        <div class="hero-image"></div>
        <div class="hero-title">
            <h1>МОДУЛЬ <br><span class="orange-color">ЭКСПЕРТА</span></h1>
        </div>
        <div class="button-line">
            <router-link to="/expert/profile/personal-information">
                <ExpertButtonComponent>Профиль</ExpertButtonComponent>
            </router-link>
            <ExpertButtonComponent>Выбрать команду</ExpertButtonComponent>
            <ExpertButtonComponent>Внести наблюдения</ExpertButtonComponent>
        </div>
    </div>
</template>

<script>
import ExpertButtonComponent from '@/views/expert/ExpertButtonComponent.vue';

export default {
    components: {
        ExpertButtonComponent,
    },
};
</script>

<style lang="scss" scoped>
a:visited {
  color: #fff;
}

.main-expert {
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  background-image: url('@/assets/images/bg.png');
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.hero-title h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 120px;
  text-align: center;
  color: #FFFFFF;
}

.orange-color {
  color: #FF820D;
}

.button-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 50px;

  /deep/ .button {
    min-width: 360px;
  }
}
</style>
