<template>
    <div class="auth auth-reset">
        <div class="container">
            <div class="auth__logo">
                <img alt="" src="@/assets/images/logo-2.png"/>
            </div>
            <div class="auth__text">
                Ваш <span>старый пароль был сброшен.</span> Теперь вам необходимо
                установить новый пароль.
            </div>
            <form action="#" class="auth__form" @submit.prevent="handleSubmit">
                <div
                    :class="{
                        'auth__form-input_error':
                            !$v.password.minLength ||
                            $v.password.$error ||
                            (!$v.password.PASSWORD_CHECK && this.password.length > 0) ||
                            this.resetpassErrors.password.length > 0,
                    }"
                    class="auth__form-input"
                >
                    <input
                        v-model="password"
                        placeholder="Новый пароль"
                        type="password"
                        @input="resetpassErrors.password = []"
                    />
                </div>
                <div
                    :class="{
                        'auth__form-input_error':
                            !$v.passwordRepeat.minLength ||
                            !$v.passwordRepeat.sameAsPassword ||
                            $v.passwordRepeat.$error,
                    }"
                    class="auth__form-input"
                >
                    <input
                        v-model="passwordRepeat"
                        placeholder="Подтвердите новый пароль"
                        type="password"
                        @input="resetpassErrors.password = []"
                    />
                    <div class="auth__form-error">
                        <div v-for="error in resetpassErrors.password" :key="error.index">
                            {{ error }}
                        </div>
                        <div v-if="!$v.password.PASSWORD_CHECK && this.password.length > 0">
                            Некорректный новый пароль!
                        </div>
                        <div v-if="!$v.passwordRepeat.sameAsPassword">
                            Пароли не совпадают!
                        </div>
                        <div v-if="!$v.password.minLength">
                            Мин. длина пароля 8 символов!
                        </div>
                    </div>
                </div>
                <div v-if="success" class="auth__form-success">
                    <span>Пароль успешно изменен, авторизуйтесь!</span><br/>
                </div>
                <div :class="{ success: success }" class="auth__form-submit">
                    <input class="btn" type="submit" value="Сохранить"/>
                </div>
                <router-link
                    class="auth__form-link"
                    to="/login"
                >Отменить и оставить старый пароль
                </router-link
                >
            </form>
        </div>
    </div>
</template>

<script>
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import router from '@/router';
import { REGEX_PASSWORD } from '@/global/constants';

const PASSWORD_CHECK = value => REGEX_PASSWORD.test(value);

export default {
    name: 'ResetAccountView',
    data() {
        return {
            password: '',
            passwordRepeat: '',
            passwordSame: true,
            success: false,
        };
    },
    computed: {
        ...mapGetters({
            resetpassErrors: 'settings/resetpassErrors',
        }),
    },
    methods: {
        ...mapActions({
            confirmForgotPassword: 'auth/confirmForgotPassword',
        }),

        async handleSubmit() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                const url = this.$route.path;
                const urlArray = url.split('/');
                const uuid = urlArray[urlArray.length - 2];
                const token = urlArray[urlArray.length - 1];

                const payload = {
                    uuid,
                    token,
                    password: this.password,
                };

                const res = await this.confirmForgotPassword(payload);

                if (res === true) {
                    this.success = true;
                    setTimeout(() => router.push('/login'), 3000);
                }
            }
        },
    },
    validations: {
        password: {
            required,
            minLength: minLength(8),
            PASSWORD_CHECK,
        },
        passwordRepeat: {
            required,
            minLength: minLength(8),
            sameAsPassword: sameAs('password'),
        },
    },
};
</script>
