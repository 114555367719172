<template>
    <div class="container">

        <div class="modal-detail-champion__wrapper">
            <div>
                <h1 style="color: black; font-weight: bold; font-size: 30px; letter-spacing: -1px; line-height: 1;">{{
                    currentChampionship.name}}</h1>
            </div>

            <hr
                align="left"
                color="#ff9900"
                size="4"
                width="100%"/>

            <div>
                <h3 style="color: black; font-size: 20px; font-weight: 500; line-height: 32px;">Описание чемпионата: </h3>
                <span style="font-size: 17px; line-height: 24px;">{{ currentChampionship.description }}</span>
            </div>
            <div>
                <br/>
                <span
                    style="color: black; font-size: 20px; font-weight: 500; line-height: 32px;">Дата начала чемпионата: </span>
                <span style="color: black; font-size: 17px; font-weight: 300; line-height: 32px;">{{ Date(currentChampionship.date_start) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ChampionshipDescriptionView',
    components: {},
    data() {
        return {
            isLoadingMembers: false,
            awaiting: false,
            processing: false,
            final: false,
        };
    },
    computed: {
        ...mapGetters({
            userId: 'user/userId',
            championshipMembers: 'championship/championshipMembers',
            currentChampionship: 'championship/currentChampionship',
        }),

        preparedMembersList() {
            return this.championshipMembers;
        },

        championshipId() {
            return this.currentChampionship.id ?? null;
        },
    },
    watch: {
        isLoadingMembers() {
            if (!this.isLoadingMembers) {
                this.$emit('loaded');
            }
        },
    },
    async created() {
        console.log(this.$route);
        const last = this.$route.path.split('/')
            .pop();

        if (last === 'members') {
            this.final = true;
        }

        localStorage.setItem('championshipTabIndex', 0);
        await this.championshipDetails();

        await this.getInviteToChampionship({
            id_user: this.userId,
            id_championship: this.championshipId,
        });
        await this.getUserHasCommand({
            id_user: this.userId,
            id_championship: this.championshipId,
        });
    },
    methods: {
        ...mapActions({
            getChampionshipDetails: 'championship/getChampionshipDetails',
            getChampionshipMembersDetails: 'championship/getChampionshipMembersDetails',
            getInviteToChampionship: 'championship/getInviteToChampionship',
            getUserHasCommand: 'championship/getUserHasCommand',
        }),

        async championshipDetails() {
            this.isLoadingMembers = true;

            const second_res = await this.getChampionshipMembersDetails({
                id_championship: this.championshipId,
                wait: !!this.currentChampionship.id,
            });

            if (second_res) {
                this.isLoadingMembers = false;
            }
        },
    },
};
</script>

<style>

</style>
